import React, { useState, useEffect } from 'react';
import Datepicker from '../../components/common/datepicker';
import AuthDetail from '../../components/common/auth';
import Spinner from '../../components/spinner';
import { useNavigate } from 'react-router-dom';
import SelectOption from '../../components/common/selectOption';
import Select from '../../components/Select';
import CustomButton from '../../components/common/customButton';
import Swal from 'sweetalert2';
import Logo from '../../assets/image/idealILogo.jpeg';
const LibertyBike = () => {
    const navigate = useNavigate();
    const { base_url, user } = AuthDetail();
    const [userData, setUserData] = useState({});
    const [isLoading, setIsLoading] = useState(false);
    const [startDate, setStartDate] = useState(new Date());
    const [RTO_ZONE, setRTO_ZONE] = useState([]);
    const [TWO_WHEELER_TYPE, setTWO_WHEELER_TYPE] = useState([]);
    const [TWO_WHEELER_TYPE_KEY, setTWO_WHEELER_TYPE_KEY] = useState([]);
    const [POLICY_TYPE, setPOLICY_TYPE] = useState([]);
    const [POLICY_TYPE_KEY, setPOLICY_TYPE_KEY] = useState([]);
    const [POLICY_TYPE_bike, setPOLICY_TYPE_bike] = useState([]);
    const [POLICY_TYPE_bike_key, setPOLICY_TYPE_bike_key] = useState([]);
    const [commission, setcommission] = useState("");

    const [RTO_ZONE_data, setRTO_ZONE_data] = useState("");
    const [TWO_WHEELER_TYPE_data, setTWO_WHEELER_TYPE_data] = useState("");
    const [POLICY_TYPE_data, setPOLICY_TYPE_data] = useState("");
    const [ccData, setCCData] = useState("");


    const [RTO_ZONE_error, setRTO_ZONE_error] = useState(false);
    const [TWO_WHEELER_TYPE_error, setTWO_WHEELER_TYPE_error] = useState(false);
    const [POLICY_TYPE_error, setPOLICY_TYPE_error] = useState(false);
    const [ccError, setCcError] = useState(false);
    const onChangedate = (e) => {
        setRTO_ZONE([]);
        setTWO_WHEELER_TYPE([]);
        setTWO_WHEELER_TYPE_KEY([]);
        setPOLICY_TYPE([]);
        setPOLICY_TYPE_KEY([]);
        setPOLICY_TYPE_bike([]);
        setPOLICY_TYPE_bike_key([]);
        setcommission("");
        setRTO_ZONE_data("");
        setTWO_WHEELER_TYPE_data("");
        setPOLICY_TYPE_data("");
        setCCData("");
        setStartDate(e)
        SelectRTOState(e.getMonth() + 1, e.getFullYear());
    }
    useEffect(() => {
        setIsLoading(true);
        if (user != null) {
            setUserData(JSON.parse(user));
        } else {
            navigate('/login')
        }

        SelectRTOState(startDate.getMonth() + 1, startDate.getFullYear());

        setIsLoading(true);
    }, [])
    const SelectRTOState = async (startDate, year) => {
        try {
            const res = await base_url.post(`/twoWheeler/liberty_bike/selectRTOZone`, {
                "date": startDate,
                "year": year,
            });
            if (res.data.status === 200) {
                setRTO_ZONE(res.data.data);
                setIsLoading(false);
            } else {

                setIsLoading(false);
            }
        } catch (error) {
            setIsLoading(false);
            console.log(error);
        }
    }
    const handleChangeRTOZone = async (e) => {
        const value = e.target.value;
        setRTO_ZONE_data(value);
        setRTO_ZONE_error(false);
        setPOLICY_TYPE([]);
        setPOLICY_TYPE_KEY([]);
        setPOLICY_TYPE_bike([]);
        setPOLICY_TYPE_bike_key([]);
        setTWO_WHEELER_TYPE_data("");
        setPOLICY_TYPE_data("");
        setCCData("");
        try {
            const res = await base_url.post(`/twoWheeler/liberty_bike/Select_two_wheeler_type`, {
                "date": startDate.getMonth() + 1,
                "year": startDate.getFullYear(),
                "rtoZone": value.split('_').join(' '),
            });
            if (res.data.status === 200) {
                setTWO_WHEELER_TYPE(res.data.data);
                setTWO_WHEELER_TYPE_KEY(res.data.dataValue)
                setIsLoading(false);
            } else {

                setIsLoading(false);
            }
        } catch (error) {
            setIsLoading(false);
            console.log(error);
        }
    }
    const handleChangeTWO_WHEELER_TYPE = async (e) => {
        const value = e.target.value;
        setTWO_WHEELER_TYPE_data(value);
        setTWO_WHEELER_TYPE_error(false);
        setPOLICY_TYPE_bike([]);
        setPOLICY_TYPE_bike_key([]);
        setPOLICY_TYPE_data("");
        setCCData("");
        try {
            const res = await base_url.post(`/twoWheeler/liberty_bike/select_policy_type`, {
                "date": startDate.getMonth() + 1,
                "year": startDate.getFullYear(),
                "rtoZone": RTO_ZONE_data.split('_').join(' '),
                "tw_type": value
            });
            if (res.data.status === 200) {
                setPOLICY_TYPE(res.data.data);
                setPOLICY_TYPE_KEY(res.data.dataValue)
                setIsLoading(false);
            } else {

                setIsLoading(false);
            }
        } catch (error) {
            setIsLoading(false);
            console.log(error);
        }
    }
    const handleChangePOLICY_TYPE = async (e) => {
        const value = e.target.value;
        setPOLICY_TYPE_data(value);
        setPOLICY_TYPE_error(false);
        setCCData("");
        try {
            const res = await base_url.post(`/twoWheeler/liberty_bike/select_poilcy_bike_types`, {
                "date": startDate.getMonth() + 1,
                "year": startDate.getFullYear(),
                "rtoZone": RTO_ZONE_data.split('_').join(' '),
                "tw_type": `${value}_${TWO_WHEELER_TYPE_data}`
            });
            if (res.data.status === 200) {
                setPOLICY_TYPE_bike(res.data.dataValue)
                setPOLICY_TYPE_bike_key(res.data.data)
                setIsLoading(false);
            } else {

                setIsLoading(false);
            }
        } catch (error) {
            setIsLoading(false);
            console.log(error);
        }
    }
    const handleChangePOLICY_TYPE_CC = async (e) => {
        const value = e.target.value;
        setCCData(value);
        setCcError(false);
        try {
            const res = await base_url.post(`/twoWheeler/liberty_bike/select_poilcy_bike_types_commssion`, {
                "date": startDate.getMonth() + 1,
                "year": startDate.getFullYear(),
                "rtoZone": RTO_ZONE_data.split('_').join(' '),
                "tw_type": `${value}`
            });
            if (res.data.status === 200) {
                setcommission(res.data.data)
                setIsLoading(false);
            } else {

                setIsLoading(false);
            }
        } catch (error) {
            setIsLoading(false);
            console.log(error);
        }

    }
    const handleClick = () => {
        if (RTO_ZONE_data == "") {
            setRTO_ZONE_error(true);
        }
        if (TWO_WHEELER_TYPE_data == "") {
            setTWO_WHEELER_TYPE_error(true);
        }
        if (POLICY_TYPE_data == "") {
            setPOLICY_TYPE_error(true);
        }
        if (ccData == "") {
            setCcError(true);
        }
        if (RTO_ZONE_data != "" &&
            TWO_WHEELER_TYPE_data != "" &&
            POLICY_TYPE_data != "" &&
            ccData) {
            Swalll();
        }
    }
    const Swalll = () => {
        return (
            Swal.fire({
                title: `LIBERTY TWO  WHEELER `,
                text: 'Modal with a custom image.',
                html: `
                <table class="table text-start">
                <tbody>
                  <tr>
                    <td>RTO Zone</td>
                    <td>${RTO_ZONE_data}</td>
                  </tr> 
                <tr>
                <td>Insurance  Type </td>
                    <td style="text-transform: capitalize;white-space: pre-wrap !important;">${TWO_WHEELER_TYPE_data}</td> 
                  </tr>
                 <tr> 
                   <td>Insurance Sub Type </td>
                    <td style="text-transform: capitalize;white-space: pre-wrap !important;">${POLICY_TYPE_data.split('_').join(' ')}</td> 
                  </tr>
                  <tr> 
                  <td>Commission</td>
                   <td style="text-transform: capitalize;white-space: pre-wrap !important;">${commission}%${/[a-zA-Z]/g.test(commission) ? "" : ""}</td> 
                 </tr> 
                </tbody> 
              </table>`,
                imageUrl: `${Logo}`,
                imageWidth: 400,
                imageHeight: 200,
                imageAlt: 'Custom image',
                confirmButtonText: 'OK',
                confirmButtonColor: 'green',
            })
        )
    }
    return (
        <div className='container-fluid mt-3  rounded position-top-right-center mt-5'>
            <div className='container '>
                <div className='row'>
                    {isLoading && <Spinner />}
                    <div className="mb-3 mx-auto text-center col-12 col-lg-8 col-md-8 col-sm-12 ">
                        <div className="mb-4 ">
                            <h1 className='form-heading text-center text-uppercase'> LIBERTY TWO wheeler insurance</h1>
                        </div>
                        {
                            userData.user_type !== "user" ? <Datepicker onChangedate={onChangedate} data={startDate} /> : ""
                        }
                        <SelectOption
                            title='ROT Zone'
                            optionTitle='Select RTO Zone'
                            data={RTO_ZONE}
                            onChange={handleChangeRTOZone}
                            errors={RTO_ZONE_error}
                            value={RTO_ZONE_data}
                        />
                        <Select
                            title={'Insurance Type'}
                            optionTitle={'Select Insurance Type'}
                            data={TWO_WHEELER_TYPE}
                            dataKey={TWO_WHEELER_TYPE_KEY}
                            onChange={handleChangeTWO_WHEELER_TYPE}
                            value={TWO_WHEELER_TYPE_data}
                            errors={TWO_WHEELER_TYPE_error}
                        />
                        <Select
                            title={'Insurance Sub Type'}
                            optionTitle={'Insurance Sub Type'}
                            data={POLICY_TYPE}
                            dataKey={POLICY_TYPE_KEY}
                            onChange={handleChangePOLICY_TYPE}
                            value={POLICY_TYPE_data}
                            errors={POLICY_TYPE_error}
                        />
                        <Select
                            title={'CC'}
                            optionTitle={'Select CC'}
                            data={POLICY_TYPE_bike}
                            dataKey={POLICY_TYPE_bike_key}
                            onChange={handleChangePOLICY_TYPE_CC}
                            value={ccData}
                            errors={ccError}
                        />
                        <CustomButton
                            title={'search'}
                            handleClick={handleClick}
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default LibertyBike
