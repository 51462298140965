import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import SelectOption from '../components/common/selectOption';
import CustomButton from '../components/common/customButton';
import AuthDetail from '../components/common/auth';
import Spinner from '../components/spinner';
const TwoWheeler = () => {
    const navigate = useNavigate();
    const { base_url, user } = AuthDetail();
    const [isLoading, setIsLoading] = useState(false);
    const [userData, setUserData] = useState({});
    const [motorCompany, setMotorCompany] = useState([]);
    const [companyNem, setCompanyName] = useState("");
    const [errorCompanyNem, setErrorCompanyNem] = useState("");
    useEffect(() => {
        setIsLoading(true);
        if (user !== null) {
            setUserData(JSON.parse(user));
        } else {
            navigate('/login')
        }
        SelectTwowheelerCompany();
    }, []);
    const SelectTwowheelerCompany = async () => {
        setIsLoading(true);
        try {
            const res = await base_url.get(`/insuranceType?insuranceType=two_wheeler`);
            if (res.data.status === 200) {
                setIsLoading(false);
                setMotorCompany(res.data.data);
            }
            setIsLoading(false);
        } catch (error) {
            setIsLoading(false);
            console.log(error);
        }
    }
    const SelectCompany = (e) => {
        const value = e.target.value;
        setCompanyName(value);
        setErrorCompanyNem(false);
    }
    const handleClick = () => {
        if (companyNem !== "") {
            navigate(companyNem);
        } else {
            setErrorCompanyNem(true);
        }
    }
    return (
        <div className='container-fluid mt-3  rounded position-top-right-center mt-5'>
            <div className='container '>
                <div className='row'>
                    {isLoading && <Spinner />}
                    <div className="mb-3 mx-auto text-center col-12 col-lg-8 col-md-8 col-sm-12 ">
                        <div className="mb-4 ">
                            <h1 className='form-heading text-center text-uppercase'>two wheeler insurance companies</h1>
                        </div>
                        <SelectOption
                            title='Company '
                            optionTitle='Select Company'
                            data={motorCompany}
                            onChange={SelectCompany}
                            errors={errorCompanyNem} />
                        <CustomButton
                            title={'search'}
                            handleClick={handleClick}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default TwoWheeler;
