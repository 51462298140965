import React, { useState, useEffect } from 'react';
import Datepicker from '../../components/common/datepicker';
import { useNavigate } from 'react-router-dom';
import AuthDetail from '../../components/common/auth';
import CustomButton from '../../components/common/customButton';
import SelectOption from '../../components/common/selectOption';
import Spinner from '../../components/spinner';
import Swal from 'sweetalert2';
import Logo from '../../assets/image/idealILogo.jpeg';
import Select from '../../components/Select';
const InsuranceType = [
    "Stand Alone Third Party",
    "Comprehensive",
    "Stand Alone Own Damage"
];
const GodigitFourWheeler = () => {
    const navigate = useNavigate();
    const { base_url, user } = AuthDetail();
    const [userData, setUserData] = useState({});
    const [isLoading, setIsLoading] = useState(false);
    const [startDate, setStartDate] = useState(new Date());
    const [InsuranceTypeData, setInsuranceTypeData] = useState("");
    const [SelectClusterSATP_CLUSTER, SetSelectClusterSATP_CLUSTER] = useState([]);
    const [FUEL, setFUEL] = useState([]);


    const [dataSATP_CLUSTER, setDataSATP_CLUSTER] = useState("");
    const [RTODataValue, setRTODataValue] = useState("");
    const [RTODataListName, setRTODataListName] = useState("");
    const [FUELData, setFUELData] = useState("");
    const [commission, setCommission] = useState("");

    const [InsuranceTypeError, setInsuranceTypeError] = useState(false);
    const [RTOError, setRTOError] = useState(false);
    const [dataSATP_CLUSTER_Error, setDataSATP_CLUSTER_Error] = useState(false);
    const [FUELDataError, setFUELDataError] = useState(false);

    const [RTO, setRTO] = useState([]);
    const [RTOKey, setRTOKey] = useState([]);
    const [Zone, setZone] = useState([]);
    const [SelectCluster_CLUSTER, SetSelectCluster_CLUSTER] = useState([]);
    const [FUEL_TYPE, setFUEL_TYPE] = useState([]);
    const [policyType, setPolicyType] = useState([]);
    const [commissionTypeValue, setCommissionTypeValue] = useState([])
    const [commissionTypekeyes, setCommissionTypeKeyes] = useState([])


    const [ZoneData, setZoneDAta] = useState("");
    const [SelectCluster_CLUSTER_Data, SetSelectCluster_CLUSTER_Data] = useState("");
    const [FUEL_TYPE_Data, setFUEL_TYPE_DAta] = useState("");
    const [commissionTypeData, setCommissionTypeData] = useState("");

    const [ZoneError, setZoneError] = useState(false);
    const [SelectCluster_CLUSTER_Eroor, SetSelectCluster_CLUSTER_Error] = useState(false);
    const [FUEL_TYPE_error, setFUEL_TYPE_error] = useState(false);
    const [commissionTypeError, setCommissionTypeError] = useState(false);

    // /Comprehensive
    const [FUEL_TYPE_Comprehensive, setFUEL_TYPE_Comprehensive] = useState([]);
    const [FUEL_TYPE_KEY_Comprehensive, setFUEL_TYPE_KEY_Comprehensive] = useState([]);
    useEffect(() => {
        if (user != null) {
            setUserData(JSON.parse(user));
        } else {
            navigate('/login')
        }
    }, [])
    const onChangedate = (e) => {
        setStartDate(e)
        SetSelectClusterSATP_CLUSTER([]);
        setFUEL([]);
        setZone([]);
        setRTO([]);
        setRTOKey([]);
        SetSelectCluster_CLUSTER([]);
        setFUEL_TYPE([]);
        setCommissionTypeValue([]);
        setCommissionTypeKeyes([]);
    }
    const handleChangeInsuranceType = async (e) => {
        const value = e.target.value;
        // if (value == "Comprehensive") {
        //     try {
        //         const res = await base_url.post(`/motor/godigit/SelectClusterSaod_Fuel_type`, {
        //             "date": startDate.getMonth() + 1,
        //             "year": startDate.getFullYear(),
        //             "cluster_name": value.split('_').join(' '),
        //         });
        //         if (res.data.status === 200) {
        //             setFUEL_TYPE_Comprehensive(res.data.data);
        //             setFUEL_TYPE_KEY_Comprehensive(res.data.dataValue)
        //             setIsLoading(false);
        //         } else {
        //             setIsLoading(false);
        //         }
        //     } catch (error) {
        //         setIsLoading(false);
        //         console.log(error);
        //     }
        // }
        setInsuranceTypeData(value);
        setInsuranceTypeError(false)
        setFUEL([]);
        setZone([]);
        setZone([]);
        setRTO([]);
        SetSelectCluster_CLUSTER([]);
        setFUEL_TYPE([]);
        setCommissionTypeValue([]);
        setCommissionTypeKeyes([]);
        setDataSATP_CLUSTER("");
        setRTODataValue("");
        setFUELData("");
        setZoneDAta("");
        SetSelectCluster_CLUSTER_Data("");
        setFUEL_TYPE_DAta("");
        setCommissionTypeData("");
        setIsLoading(true);
        console.log(InsuranceTypeData);
        if (value == 'Stand_Alone_Third_Party') {
            try {
                const res = await base_url.get(`/motor/godigit/SelectRTO`);
                if (res.data.status === 200) {
                    console.log(res)
                    setRTO(res.data.data);
                    setRTOKey(res.data.dataKey)
                    setIsLoading(false);
                } else {
                    setIsLoading(false);
                }
            } catch (error) {
                setIsLoading(false);
                console.log(error);
            }
        } else {
            setIsLoading(true);
            try {
                const res = await base_url.post(`/motor/godigit/SelectClusterSaod_ZONE`, {
                    "date": startDate.getMonth() + 1,
                    "year": startDate.getFullYear(),
                });
                if (res.data.status === 200) {
                    setZone(res.data.data);
                    setIsLoading(false);
                } else {
                    setIsLoading(false);
                }
            } catch (error) {
                setIsLoading(false);
                console.log(error);
            }
        }

    }
    const handleChangeZONE = async (e) => {
        const value = e.target.value;
        const index = e.target.selectedIndex;
        const el = e.target.childNodes[index];
        const option = el.getAttribute('id');
        setRTODataListName(value);
        setRTODataValue(option);
        setRTOError(false);
        if (InsuranceTypeData == 'Stand_Alone_Third_Party') {
            try {
                const res = await base_url.post(`/motor/godigit/SelectClusterSATP_CLUSTER`, {
                    "date": startDate.getMonth() + 1,
                    "year": startDate.getFullYear(),
                    "RTO_CLUSTER": option,
                });
                if (res.data.status === 200) {
                    SetSelectClusterSATP_CLUSTER(res.data.data);
                    setIsLoading(false);
                } else {
                    setIsLoading(false);
                }
            } catch (error) {
                setIsLoading(false);
                console.log(error);
            }
        } else {
            setIsLoading(true);
            try {
                const res = await base_url.post(`/motor/godigit/SelectClusterSaod_ZONE`, {
                    "date": startDate.getMonth() + 1,
                    "year": startDate.getFullYear(),
                    "RTO_CLUSTER": value,

                });
                if (res.data.status === 200) {
                    setZone(res.data.data);
                    setIsLoading(false);
                } else {
                    setIsLoading(false);
                }
            } catch (error) {
                setIsLoading(false);
                console.log(error);
            }
        }
    }
    const handleChangeSATPCLUSTER = async (e) => {
        const value = e.target.value;
        setDataSATP_CLUSTER(value);
        setDataSATP_CLUSTER_Error(false)
        setFUELData("");
        setIsLoading(true);
        try {
            const res = await base_url.post(`/motor/godigit/SelectClusterSATP_FUEL`, {
                "date": startDate.getMonth() + 1,
                "year": startDate.getFullYear(),
                "CLUSTER": value.split('_').join(' ')
            });
            if (res.data.status === 200) {
                setFUEL(res.data.data);
                setIsLoading(false);
            } else {
                setIsLoading(false);
            }
        } catch (error) {
            setIsLoading(false);
            console.log(error);
        }
    }
    const handleChangeFuel = async (e) => {
        const value = e.target.value;
        setFUELData(value);
        setFUELDataError(false);
        setIsLoading(true);

        try {
            const res = await base_url.post(`/motor/godigit/SelectClusterSATP_Comm`, {
                "date": startDate.getMonth() + 1,
                "year": startDate.getFullYear(),
                "CLUSTER": dataSATP_CLUSTER.split('_').join(' '),
                "FUEL": value.split('_').join(' ')
            });
            if (res.data.status === 200) {
                setCommission(res.data.data);
                setIsLoading(false);
            } else {
                setIsLoading(false);
            }
        } catch (error) {
            setIsLoading(false);
            console.log(error);
        }
    }
    const handleChangeZone = async (e) => {
        const value = e.target.value;
        setZoneDAta(value);
        setZoneError(false);
        SetSelectCluster_CLUSTER([]);
        setFUEL_TYPE([]);
        setCommissionTypeValue([]);
        setCommissionTypeKeyes([]);
        SetSelectCluster_CLUSTER_Data("");
        setFUEL_TYPE_DAta("");
        setCommissionTypeData("");
        setIsLoading(true);

        try {
            const res = await base_url.post(`/motor/godigit/SelectClusterSaod_Cluster`, {
                "date": startDate.getMonth() + 1,
                "year": startDate.getFullYear(),
                "ZONE": value.split('_').join(' '),
            });
            if (res.data.status === 200) {
                SetSelectCluster_CLUSTER(res.data.data);
                setIsLoading(false);
            } else {
                setIsLoading(false);
            }
        } catch (error) {
            setIsLoading(false);
            console.log(error);
        }
    }
    const handleChangeClusterName = async (e) => {
        const value = e.target.value;
        SetSelectCluster_CLUSTER_Data(value);
        SetSelectCluster_CLUSTER_Error(false)
        setFUEL_TYPE([]);
        setFUEL_TYPE_DAta("");
        setCommissionTypeData("");
        setIsLoading(true);
        try {
            if (InsuranceTypeData == "Comprehensive") {
                try {
                    const res = await base_url.post(`/motor/godigit/SelectClusterSaod_Fuel_type_Comprehensive`, {
                        "date": startDate.getMonth() + 1,
                        "year": startDate.getFullYear(),
                        "cluster_name": value.split('_').join(' '),
                    });
                    if (res.data.status === 200) {
                        setFUEL_TYPE_Comprehensive(res.data.data);
                        setFUEL_TYPE_KEY_Comprehensive(res.data.dataValue)
                        setIsLoading(false);
                    } else {
                        setIsLoading(false);
                    }
                } catch (error) {
                    setIsLoading(false);
                    console.log(error);
                }
            } else {
                const res = await base_url.post(`/motor/godigit/SelectClusterSaod_Fuel_type`, {
                    "date": startDate.getMonth() + 1,
                    "year": startDate.getFullYear(),
                    "ZONE": ZoneData.split('_').join(' '),
                    "CLUSTER_NAME": value.split('_').join(' '),
                });
                if (res.data.status === 200) {
                    setFUEL_TYPE(res.data.data);
                    setIsLoading(false);
                } else {
                    setIsLoading(false);
                }
            }
        } catch (error) {
            setIsLoading(false);
            console.log(error);
        }
    }
    const handleChangeFuelType = async (e) => {
        const value = e.target.value;
        setFUEL_TYPE_DAta(value);
        setFUEL_TYPE_error(false);
        setCommissionTypeValue([]);
        setCommissionTypeKeyes([]);
        setCommissionTypeData("");
        setIsLoading(true);
        try {
            const res = await base_url.post(`/motor/godigit/SelectClusterSaod_Policy_type`, {
                "date": startDate.getMonth() + 1,
                "year": startDate.getFullYear(),
                "ZONE": ZoneData.split('_').join(' '),
                "CLUSTER_NAME": SelectCluster_CLUSTER_Data.split('_').join(' '),
                "FUEL_TYPE": value
            });
            if (res.data.status === 200) {
                setCommissionTypeValue(res.data.data.array_values)
                setCommissionTypeKeyes(res.data.data.array_keys)

                setIsLoading(false);
            } else {
                setIsLoading(false);
            }
        } catch (error) {
            setIsLoading(false);
            console.log(error);
        }
    }
    const handleChangeCommissionType = async (e) => {
        const value = e.target.value;
        let index = commissionTypeValue.indexOf(value.split('_').join(' '));
        setCommissionTypeData(value);
        setCommissionTypeError(false);
        setIsLoading(true);
        try {
            const res = await base_url.post(`/motor/godigit/SelectClusterSaod_Policy_Comm`, {
                "date": startDate.getMonth() + 1,
                "year": startDate.getFullYear(),
                "ZONE": ZoneData.split('_').join(' '),
                "CLUSTER_NAME": SelectCluster_CLUSTER_Data.split('_').join(' '),
                "FUEL_TYPE": FUEL_TYPE_Data,
                "Comm": commissionTypekeyes[index]
            });
            if (res.data.status === 200) {
                // setPolicyType(res.data.data);
                setCommission(res.data.data)
                setIsLoading(false);
            } else {
                setIsLoading(false);
            }
        } catch (error) {
            setIsLoading(false);
            console.log(error);
        }
    }
    const handleClick = async (e) => {
        if (InsuranceTypeData === "Comprehensive") {
            if (InsuranceTypeData == "") {
                setInsuranceTypeError(true)
            }
            if (ZoneData == "") {
                setZoneError(true);
            }
            if (SelectCluster_CLUSTER_Data === "") {
                SetSelectCluster_CLUSTER_Error(true)
            }

            if (FUEL_TYPE_Comprehensiv_Data == "") {
                setFUEL_TYPE_Comprehensiv_error(true);
            }
            if (motorCCData == "") {
                setmotorCCError(true);
            }
            Swalll(`<table class="table text-start">
                <tbody>
                  <tr>
                    <td>Insurance Type</td>
                    <td style="text-transform: capitalize;white-space: pre-wrap !important;">${InsuranceTypeData.split('_').join(' ')}</td>
                  </tr>
                  <tr>
                    <td>RTO</td>
                    <td style="text-transform: capitalize;white-space: pre-wrap !important;">${ZoneData.split('_').join(' ')}</td>
                  </tr>
                  <tr>
                    <td>Cluster Type</td>
                    <td style="text-transform: capitalize;white-space: pre-wrap !important;">${SelectCluster_CLUSTER_Data.split('_').join(' ')}</td>
                  </tr>
                  <tr>
                    <td>Vehicle Type</td>
                    <td style="text-transform: capitalize;white-space: pre-wrap !important;">${SelectCluster_CLUSTER_Data.split('_').join(' ')}</td>
                  </tr>
                  <tr>
                    <td>CC </td>
                    <td style="text-transform: capitalize;white-space: pre-wrap !important;">${motorCCData.split('_').join(' ')}</td>
                  <tr> <td>Commission </td> <td style="text-transform: capitalize;white-space: pre-wrap !important;">${commission}%</td> </tr>
                </tbody> </table>`)
        } else if (InsuranceTypeData === "Stand_Alone_Third_Party") {
            if (InsuranceTypeData == "") {
                setInsuranceTypeError(true)
            }
            if (RTODataValue == "") {
                setRTOError(true);
            }
            if (dataSATP_CLUSTER === "") {
                setDataSATP_CLUSTER_Error(true)
            }
            if (FUELData === "") {
                setFUELDataError(true)
            }
            if (InsuranceTypeData !== ""
                && dataSATP_CLUSTER !== ""
                && FUELData !== ""
            ) {
                Swalll(`<table class="table text-start">
                <tbody>
                  <tr>
                    <td>Insurance Type</td>
                    <td style="text-transform: capitalize;white-space: pre-wrap !important;">${InsuranceTypeData.split('_').join(' ')}</td>
                  </tr>
                  <tr>
                    <td>RTO</td>
                    <td style="text-transform: capitalize;white-space: pre-wrap !important;">${RTODataListName.split('_').join(' ')}</td>
                  </tr>
                  <tr>
                    <td>Cluster Type</td>
                    <td style="text-transform: capitalize;white-space: pre-wrap !important;">${dataSATP_CLUSTER.split('_').join(' ')}</td>
                  </tr>
                  <tr>
                    <td>Vehicle Type </td>
                    <td style="text-transform: capitalize;white-space: pre-wrap !important;">${FUELData.split('_').join(' ')}</td>
                  <tr> <td>Commission </td> <td style="text-transform: capitalize;white-space: pre-wrap !important;">${commission}%</td> </tr>
                </tbody> </table>`)
            }
        } else {
            if (InsuranceTypeData == "") {
                setInsuranceTypeError(true)
            }
            if (RTODataValue == "") {
                setRTOError(true);
            }
            if (ZoneData == "") {
                setZoneError(true)
            }
            if (SelectCluster_CLUSTER_Data == "") {
                SetSelectCluster_CLUSTER_Error(true)
            }
            if (FUEL_TYPE_Data == "") {
                setFUEL_TYPE_error(true)
            }
            if (commissionTypeData == "") {
                setCommissionTypeError(true)
            }
            if (InsuranceTypeData !== ""
                && ZoneData !== ""
                && SelectCluster_CLUSTER_Data !== ""
                && FUEL_TYPE_Data !== ""
                && commissionTypeData !== "") {
                Swalll(`<table class="table text-start">
                <tbody>
                  <tr>
                    <td>Insurance Type</td>
                    <td style="text-transform: capitalize;white-space: pre-wrap !important;">${InsuranceTypeData.split('_').join(' ')}</td>
                  </tr>
                  <tr>
                    <td>Zone</td>
                    <td style="text-transform: capitalize;white-space: pre-wrap !important;">${ZoneData.split('_').join(' ')}</td>
                  </tr>
                  <tr>
                    <td>Cluster Type</td>
                    <td style="text-transform: capitalize;white-space: pre-wrap !important;">${SelectCluster_CLUSTER_Data.split('_').join(' ')}</td>
                  </tr>
                  <tr>
                    <td>Vehicle Type</td>
                    <td style="text-transform: capitalize;white-space: pre-wrap !important;">${FUEL_TYPE_Data.split('_').join(' ')}</td>
                  </tr>
                  <tr>
                    <td>Packge</td>
                    <td style="text-transform: capitalize;white-space: pre-wrap !important;">${commissionTypeData.split('_').join(' ')}</td>
                  </tr>
                  <tr> <td>Commission </td> <td style="text-transform: capitalize;white-space: pre-wrap !important;">${commission}${/[a-zA-Z]/g.test(commission) ? "" : "%"}</td> </tr>
                  ${InsuranceTypeData == "Comprehensive/Stand_Alone_Own_Damage" ?
                        `<tr>
                      <td>Remarks</td>
                    <td style="text-transform: capitalize;white-space: pre-wrap !important;">Commission to be calculated on the OD Part</td> 
                  </tr> `: ""}
                  </tbody> </table>`)
            }
        }
    }
    const Swalll = (table) => {
        return (
            Swal.fire({
                title: `GODIGIT FOUR WHEELER`,
                text: 'Modal with a custom image.',
                html: table,
                imageUrl: `${Logo}`,
                imageWidth: 400,
                imageHeight: 200,
                imageAlt: 'Custom image',
                confirmButtonText: 'OK',
                confirmButtonColor: 'green',
            })
        )
    }
    const [FUEL_TYPE_Comprehensiv_Data, setFUEL_TYPE_Comprehensiv_Data] = useState("");
    const [FUEL_TYPE_Comprehensiv_error, setFUEL_TYPE_Comprehensiv_error] = useState(false);
    const [motorCC, setmotorCC] = useState([]);
    const [motorCCKey, setmotorCCKey] = useState([]);
    const [motorCCData, setmotorCCData] = useState("");
    const [motorCCError, setmotorCCError] = useState(false);
    const handleChangeFuelTypeComprehensive = async (e) => {
        const value = e.target.value;
        setFUEL_TYPE_Comprehensiv_Data(value);
        setFUEL_TYPE_Comprehensiv_error(false);
        setIsLoading(true);
        setmotorCCData("");
        try {
            if (value != 'CNG') {
                const res = await base_url.post(`/motor/godigit/motorCC`, {
                    "date": startDate.getMonth() + 1,
                    "year": startDate.getFullYear(),
                    "cluster_name": SelectCluster_CLUSTER_Data.split('_').join(' '),
                    "FUEL_TYPE": value
                });
                if (res.data.status === 200) {
                    setmotorCC(res.data.dataValue)
                    setmotorCCKey(res.data.data)
                    setIsLoading(false);
                } else {
                    setIsLoading(false);
                }
            } else {
                const res = await base_url.post(`/motor/godigit/commission`, {
                    "date": startDate.getMonth() + 1,
                    "year": startDate.getFullYear(),
                    "cluster_name": SelectCluster_CLUSTER_Data.split('_').join(' '),
                    "cc": value
                });
                if (res.data.status === 200) {
                    setCommission(res.data.data);
                    setIsLoading(false);
                } else {
                    setIsLoading(false);
                }
            }
        } catch (error) {
            setIsLoading(false);
            console.log(error);
        }

    }
    const handleChangeCCType = async (e) => {
        const value = e.target.value;
        setmotorCCData(value);
        setmotorCCError(false);
        try {
            const res = await base_url.post(`/motor/godigit/commission`, {
                "date": startDate.getMonth() + 1,
                "year": startDate.getFullYear(),
                "cluster_name": SelectCluster_CLUSTER_Data.split('_').join(' '),
                "cc": value
            });
            if (res.data.status === 200) {
                setCommission(res.data.data)
            } else {
                setIsLoading(false);
            }
        } catch (error) {
            setIsLoading(false);
            console.log(error);
        }
    }
    return (
        <div className='container-fluid mt-3  rounded position-top-right-center mt-5'>
            <div className='container '>
                <div className='row'>
                    {isLoading && <Spinner />}
                    <div className="mb-3 mx-auto text-center col-12 col-lg-8 col-md-8 col-sm-12 ">
                        <div className="mb-4 ">
                            <h1 className='form-heading text-center text-uppercase'> godigit FOUR WHEELER insurance</h1>
                        </div>
                        {userData.user_type !== "user" ? <Datepicker onChangedate={onChangedate} data={startDate} /> : ""}
                        <SelectOption
                            title='Insurance Type'
                            optionTitle='Select Insurance Type'
                            data={InsuranceType}
                            onChange={handleChangeInsuranceType}
                            errors={InsuranceTypeError}
                            value={InsuranceTypeData}
                        />
                        {InsuranceTypeData === "Stand_Alone_Third_Party" &&
                            <>
                                <Select
                                    company={'godigit'}
                                    title='RTO List'
                                    optionTitle='Select RTO List'
                                    data={RTOKey}
                                    dataKey={RTO}
                                    onChange={handleChangeZONE}
                                    errors={RTOError}
                                    value={RTODataListName}
                                />
                                <SelectOption
                                    title='Cluster Type'
                                    optionTitle='Select Cluster Type'
                                    data={SelectClusterSATP_CLUSTER}
                                    onChange={handleChangeSATPCLUSTER}
                                    errors={dataSATP_CLUSTER_Error}
                                    value={dataSATP_CLUSTER}
                                />
                                <SelectOption
                                    title='Vehicle Type'
                                    optionTitle='Select Vehicle Type'
                                    data={FUEL}
                                    onChange={handleChangeFuel}
                                    errors={FUELDataError}
                                    value={FUELData}
                                /> </>

                        }
                        {InsuranceTypeData === "Stand_Alone_Own_Damage" &&
                            <>
                                <SelectOption
                                    title='RTO Zone'
                                    optionTitle='Select RTO Zone'
                                    data={Zone}
                                    onChange={handleChangeZone}
                                    errors={ZoneError}
                                    value={ZoneData}
                                />
                                <SelectOption
                                    title='State / Cluster Name (RTO basis)'
                                    optionTitle='Select State / Cluster Name (RTO basis)'
                                    data={SelectCluster_CLUSTER}
                                    onChange={handleChangeClusterName}
                                    errors={SelectCluster_CLUSTER_Eroor}
                                    value={SelectCluster_CLUSTER_Data}
                                />
                                <SelectOption
                                    title='Fuel Type'
                                    optionTitle='Select Fuel Type'
                                    data={FUEL_TYPE}
                                    onChange={handleChangeFuelType}
                                    errors={FUEL_TYPE_error}
                                    value={FUEL_TYPE_Data}
                                />
                                <SelectOption
                                    title='Vehicle Type'
                                    optionTitle='Select Vehicle Type'
                                    data={commissionTypeValue}
                                    onChange={handleChangeCommissionType}
                                    errors={commissionTypeError}
                                    value={commissionTypeData}
                                />

                            </>}
                        {InsuranceTypeData === "Comprehensive" &&
                            <>
                                <SelectOption
                                    title='RTO Zone'
                                    optionTitle='Select RTO Zone'
                                    data={Zone}
                                    onChange={handleChangeZone}
                                    errors={ZoneError}
                                    value={ZoneData}
                                />
                                <SelectOption
                                    title='State / Cluster Name (RTO basis)'
                                    optionTitle='Select State / Cluster Name (RTO basis)'
                                    data={SelectCluster_CLUSTER}
                                    onChange={handleChangeClusterName}
                                    errors={SelectCluster_CLUSTER_Eroor}
                                    value={SelectCluster_CLUSTER_Data}
                                />
                                <Select
                                    title='Fuel Type'
                                    optionTitle='Select Fuel Type'
                                    data={FUEL_TYPE_Comprehensive}
                                    dataKey={FUEL_TYPE_KEY_Comprehensive}
                                    onChange={handleChangeFuelTypeComprehensive}
                                    errors={FUEL_TYPE_Comprehensiv_error}
                                    value={FUEL_TYPE_Comprehensiv_Data}
                                />
                                {FUEL_TYPE_Data != 'CNG' &&
                                    <Select
                                        title='Fuel Type'
                                        optionTitle='Select Fuel Type'
                                        data={motorCC}
                                        dataKey={motorCCKey}
                                        onChange={handleChangeCCType}
                                        errors={motorCCError}
                                        value={motorCCData}
                                    />}
                            </>

                        }
                        <CustomButton
                            title={'search'}
                            handleClick={handleClick}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default GodigitFourWheeler;
