import { useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
const AuthDetail = () => {
  const url = process.env.REACT_APP_API_END_POINT;
  const getToken = () => {
    const tokenString = sessionStorage.getItem("token");
    const userToken = JSON.parse(tokenString);
    return userToken;
  };

  const getUser = () => {
    const userString = sessionStorage.getItem("user");
    const user_detail = JSON.parse(userString);
    return user_detail;
  };

  const [token, setToken] = useState(getToken());
  const [user, setUser] = useState(getUser());

  const saveToken = (user, token) => {
    sessionStorage.setItem("token", JSON.stringify(token));
    sessionStorage.setItem("user", JSON.stringify(user));
    setToken(token);
    setUser(user);
    // navigate("/dashboard");
  };
  const logout = () => {
    sessionStorage.clear();
    // navigate("/login");
  };

  const base_url_img = axios.create({
    baseURL: url,
    headers: {
      "content-type": "multipart/form-data",
      Authorization: `Bearer ${token}`,
    },
  });
  const base_url = axios.create({
    baseURL: url,
    headers: {
      "Content-type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });

  const main_url = url;

  return {
    setToken: saveToken,
    token,
    user,
    getToken,
    base_url,
    base_url_img,
    logout,
    main_url,
  };
}

export default AuthDetail