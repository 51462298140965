import React, { useEffect, useState } from 'react'
import {
    Box,
    Grid,
    Typography,
    IconButton,
    TextField,
    Button,
    Paper
} from "@mui/material";
import CloseIcon from '@mui/icons-material/Close'
import AuthDetail from './AuthDetail';
import { ToastContainer, toast } from 'react-toastify';
const EditForm = ({ closeEvent, editData, url, saveEvent }) => {
    const { base_url, setToken } = AuthDetail();
    const [propsData, setPropsData] = useState({})
    useEffect(() => {
        setPropsData(editData);
    }, [])
    const handleChange = (e) => {
        const target = e.target;
        const name = target.name;
        const value = target.type == "checkbox" ? target.checked : target.value;
        setPropsData({ ...propsData, [name]: value });
    }
    const handleClickSaved = async () => {
        try {
            const res = await base_url.post(url, propsData);
            const data = res.data;
            console.log(data);
            if (data.status === 200) {
                toast.success('Success Notification !', { autoClose: 2000, });
            } else {
                toast.error('Something Went to wrong !', { autoClose: 2000, });
            }
        } catch (error) {
            console.table(error);
        }
    }
    return (
        <div>
            <ToastContainer />
            <Typography variant='h5' align='center'>
                Edit
            </Typography>
            <IconButton style={{ position: 'absolute', top: "0", right: "0" }}
                onClick={closeEvent}
            >
                <CloseIcon className='m-3' />
            </IconButton>
            <Box sx={{ m: 4 }} />
            <Grid container spacing={2} >
                {
                    Object.keys(editData).map((data, index) => {
                        return (
                            <Grid item xs={12} key={index} >
                                <TextField
                                    id="outlined-basic"
                                    label={data}
                                    variant="outlined"
                                    // size='small' 
                                    name={data}
                                    sx={{ width: '100%' }}
                                    inputProps={
                                        { readOnly: ["ID", "PRODUCT_NAME", "SUN_INSURED"].includes(data) ? true : false, }
                                    }
                                    onChange={handleChange}
                                    value={propsData[data]}
                                />

                            </Grid>
                        )
                    })
                }

                <Grid item xs={12}>
                    <Typography variant='h5' align='center'>
                        <Button variant="contained" sx={{ width: '100%', p: 2 }} onClick={(e) => saveEvent(propsData)}  color="success" >Saved</Button>
                    </Typography>
                </Grid>
            </Grid>
            <Box sx={{ m: 4 }} />
        </div>
    )
}

export default EditForm