import React from 'react'
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import Box from "@mui/material/Box";
function SelectOption({ title, optionTitle, data, onChange, errors, value }) {
  return (
    <div className="mb-4 ">
      {/* <label className="form-label float-start ">{title} <span className='text-danger'>*</span></label> */}
      {/* <select className={`form-select form-select-md border border-2 ${errors && "border border-2 border-danger "}`} aria-label=".form-select-md example" name="companies" onChange={onChange} value={value} >
        <option value='' >{optionTitle}</option>
        {
          data && data.map((data, i) => {
            return (
              <option key={i} value={data.split(' ').join('_')} className='text-capitalize'>
                {data.split('_').join(' ')}
              </option>
            )
          })
        }
      </select> */}
      <Autocomplete
        onChange={(event, value) => onChange(value)}
        disablePortal
        id="combo-box-demo"
        options={data}
        slotProps={{
          popper: {
            sx: {
              zIndex: 1111
            }
          }
        }}
        sx={{
          // border: "1px solid blue",
          "& .MuiOutlinedInput-root": {
            // border: "1px solid yellow",
            // borderRadius: "0",
            // padding: "8"
          },
          "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
            border: "1px solid green"
          }
        }}
        disableClearable
        required
        // error={Boolean(props.fieldState.error)}
        value={value}
        renderInput={(params) => <TextField {...params} label={title + "*"} variant="outlined" fullWidth error={errors} />}
      />
    </div>
  )
}

export default SelectOption