import React, { useState, useEffect } from 'react';
import AuthDetail from '../../components/common/auth';
import Datepicker from '../../components/common/datepicker';
import Spinner from '../../components/spinner';
import CustomButton from '../../components/common/customButton';
import SelectOption from '../../components/common/selectOption';
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import Logo from '../../assets/image/idealILogo.jpeg';
const date_arr = { 1: 'Jan', 2: 'Feb', 3: 'Mar', 4: 'Apr', 5: 'May', 6: 'Jun', 7: 'Jul', 8: 'Aug', 9: 'Sep', 10: 'Oct', 11: 'Nov', 12: 'Dec' };
const SU_INSURED_VAlUE_EXIT = ["Standard Fire & Special Perils Policy", "Industrial All Risk Policy", "Fire Loss of Profit", "Contractor All Risks Policy", "Erection All Risks Policy", "Marine Cum Erection Insurance"];
const Oriental_India = () => {
    const { base_url, user } = AuthDetail();
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false);
    const [userData, setUserData] = useState({});
    const [startDate, setStartDate] = useState(new Date());
    const [LINE_OF_BUSINESS, setLINE_OF_BUSINESS] = useState([]);
    const [LINE_OF_BUSINESSError, setLINE_OF_BUSINESSError] = useState(false);
    const [LINE_OF_BUSINESSData, setLINE_OF_BUSINESSData] = useState("");
    const [dataCommission, setDataCommission] = useState("");
    const [PRODUCT_NAME, setPRODUCT_NAME] = useState([]);
    const [PRODUCT_NAME_DATA, setPRODUCT_NAME_DATA] = useState("")
    const [PRODUCT_NAME_ERROR, setPRODUCT_NAME_ERROR] = useState("")
    const [SU_INSURED, setSU_INSURED] = useState([])
    const [SU_INSURED_DATA, setSU_INSURED_DATA] = useState("")
    const [SU_INSURED_ERROR, setSU_INSURED_ERROR] = useState(false);
    const [Commission, setCommission] = useState("");
    useEffect(() => {
        setIsLoading(true);
        if (user != null) {
            setUserData(JSON.parse(user));
        } else {
            navigate('/login')
        }
        selectLINE_OF_BUSINESS(`${date_arr[startDate.getMonth() + 1]}-${startDate.getFullYear()}`);

    }, [])
    const onChangedate = (e) => {
        let date = new Date(e)
        setStartDate(date);
        selectLINE_OF_BUSINESS(`${date_arr[date.getMonth() + 1]}-${date.getFullYear()}`);
        setLINE_OF_BUSINESS([]);
        setPRODUCT_NAME([]);
        setSU_INSURED([]);
        setLINE_OF_BUSINESSData("");
        setPRODUCT_NAME_DATA("");
        setSU_INSURED_DATA("");
    }
    const selectLINE_OF_BUSINESS = async (currentDate) => {
        setIsLoading(true);
        setPRODUCT_NAME([]);
        setSU_INSURED([]);
        setPRODUCT_NAME_DATA("");
        setSU_INSURED_DATA("");
        try {
            const res = await base_url.post(`/Commercial`, {
                where: { DATE: currentDate },
                fields: ["LINE_OF_BUSINESS"]
            });
            if (res.data.status === 200) {
                const data = res.data.data.map(data => ({ label: data.LINE_OF_BUSINESS, value: data.LINE_OF_BUSINESS }))
                setLINE_OF_BUSINESS(data);
                setIsLoading(false);
            } else {

                setIsLoading(false);
            }
        } catch (error) {
            console.log(error);
        }
    }
    const currentDate = `${date_arr[startDate.getMonth() + 1]}-${startDate.getFullYear()}`;
    const handleChangeLINE_OF_BUSINESS = async (e) => {
        setIsLoading(true);
        const value = e.value;
        setLINE_OF_BUSINESSData(value);
        setLINE_OF_BUSINESSError(false);
        setSU_INSURED([]);
        setPRODUCT_NAME_DATA("");
        setSU_INSURED_DATA("");
        try {
            const res = await base_url.post(`/Commercial`, {
                where: { DATE: currentDate, LINE_OF_BUSINESS: value }, fields: ["PRODUCT_NAME"]
            });
            if (res.data.status === 200) {
                const data = res?.data?.data.map(data => ({ label: data.PRODUCT_NAME, value: data.PRODUCT_NAME }))
                setPRODUCT_NAME(data);
                setIsLoading(false);
            }
        } catch (error) {
            console.log(error);
        }
    }
    const handleChangePRODUCT_NAM = async (e) => {
        const value = e.value;
        setIsLoading(true);
        setPRODUCT_NAME_DATA(value);
        setPRODUCT_NAME_ERROR(false);
        setSU_INSURED_DATA("");
        if (SU_INSURED_VAlUE_EXIT.includes(value)) {
            try {
                const res = await base_url.post(`/Commercial`, {
                    where: { DATE: currentDate, LINE_OF_BUSINESS: LINE_OF_BUSINESSData, PRODUCT_NAME: value }, fields: ["ORIENTAL_INDIA_SUM_INSURED"]
                });
                if (res.data.status === 200) {
                    const data = res?.data?.data.map(data => ({ label: data.ORIENTAL_INDIA_SUM_INSURED, value: data.ORIENTAL_INDIA_SUM_INSURED }))
                    setSU_INSURED(data);
                    setIsLoading(false);
                }
            } catch (error) {
                console.log(error);
            }
        } else {
            const res = await base_url.post(`/Commercial`, {
                where: { DATE: currentDate, LINE_OF_BUSINESS: LINE_OF_BUSINESSData, PRODUCT_NAME: value }, fields: ["ORIENTAL_INDIA_RATE"]
            });
            if (res.data.status === 200) {
                setCommission(res?.data?.data[0].ORIENTAL_INDIA_RATE);
                setIsLoading(false);
            }
        }
    }
    const handleChangeSU_INSURED = async e => {
        const value = e.value;
        setSU_INSURED_DATA(value);
        setSU_INSURED_ERROR(false);
        try {
            const res = await base_url.post(`/Commercial`, {
                where: { DATE: currentDate, LINE_OF_BUSINESS: LINE_OF_BUSINESSData, PRODUCT_NAME: PRODUCT_NAME_DATA, ORIENTAL_INDIA_SUM_INSURED: value },
                fields: ["ORIENTAL_INDIA_RATE"]
            });
            if (res.data.status === 200) {
                setCommission(res?.data?.data[0].ORIENTAL_INDIA_RATE);
            }
        } catch (error) {
            console.log(error);
        }

    }
    const handleClick = () => {
        if (LINE_OF_BUSINESSData === "") {
            setLINE_OF_BUSINESSError(true);
        }
        if (PRODUCT_NAME_DATA === "") {
            setPRODUCT_NAME_ERROR(true);
        }
        if (SU_INSURED_DATA === "") {
            setSU_INSURED_ERROR(true);
        }
        if (LINE_OF_BUSINESSData !== "" && PRODUCT_NAME_DATA !== "" && SU_INSURED_DATA !== "") {
            Swalll()
        }
        if (LINE_OF_BUSINESSData !== "" && PRODUCT_NAME_DATA !== "" && !SU_INSURED_VAlUE_EXIT.includes(PRODUCT_NAME_DATA)) {
            Swalll()
        }
    }
    const Swalll = () => {
        return (
            Swal.fire({
                title: `Commercial Oriental India Insurance`,
                text: 'Modal with a custom image.',
                html: `<table class="table text-start">
                <tbody>
                  <tr>
                    <td>Line of Business</td>
                    <td>${LINE_OF_BUSINESSData}</td>
                  </tr> 
                        <tr>
                    <td>Product Name</td>
                    <td>${PRODUCT_NAME_DATA}</td>
                  </tr> 
                  ${SU_INSURED_VAlUE_EXIT.includes(PRODUCT_NAME_DATA) == true ? `<tr><td>Sum assured</td><td>${SU_INSURED_DATA}</td></tr>` : ""}
                  <tr> 
                  <td>Commission</td>
                   <td style="text-transform: capitalize;white-space: pre-wrap !important;">${Commission}%</td> 
                 </tr> 
                </tbody> 
              </table>`,
                imageUrl: `${Logo}`,
                imageWidth: 50,
                imageHeight: 50,
                imageAlt: 'Custom image',
                confirmButtonText: 'OK',
                confirmButtonColor: 'green',
            })
        )
    }
    return (
        <div className='container-fluid mt-3  rounded position-top-right-center mt-5'>
            <div className='container '>
                <div className='row'>
                    {isLoading && <Spinner />}
                    <div className="mb-3 mx-auto text-center col-12 col-lg-8 col-md-8 col-sm-12 ">
                        <div className="mb-4 ">
                            <h1 className='form-heading text-center text-uppercase'>Commercial Oriental India  Insurance</h1>
                        </div>
                        {
                            userData.user_type !== "user" ? <Datepicker onChangedate={onChangedate} data={startDate} /> : ""
                        }

                        <SelectOption
                            title='Line of Business'
                            data={LINE_OF_BUSINESS}
                            onChange={handleChangeLINE_OF_BUSINESS}
                            errors={LINE_OF_BUSINESSError}
                            value={LINE_OF_BUSINESSData}
                        />
                        <SelectOption
                            title='Product Name'
                            data={PRODUCT_NAME}
                            onChange={handleChangePRODUCT_NAM}
                            errors={PRODUCT_NAME_ERROR}
                            value={PRODUCT_NAME_DATA}
                        />
                        {SU_INSURED_VAlUE_EXIT.includes(PRODUCT_NAME_DATA) && <SelectOption
                            title='Sum assured'
                            data={SU_INSURED}
                            onChange={handleChangeSU_INSURED}
                            errors={SU_INSURED_ERROR}
                            value={SU_INSURED_DATA}
                        />
                        }

                        <CustomButton
                            title={'search'}
                            handleClick={handleClick}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Oriental_India;
