import React from 'react'
import { Button } from '@mui/base/Button';

const CustomButton = ({ title, handleClick }) => {
    return (
        <div className="mb-5 pb-4 row mx-auto">
            <Button type='submit' className='btn  float-md-start col-lg-2 col-md-2 col-sm-12, col-12 base-Button-root'
                onClick={handleClick} >
                {title.charAt(0).toUpperCase() + title.slice(1)}
            </Button>
        </div>
    )
}

export default CustomButton