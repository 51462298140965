import React, { useState, useEffect } from 'react';
import Datepicker from '../../components/common/datepicker';
import AuthDetail from '../../components/common/auth';
import Spinner from '../../components/spinner';
import { useNavigate } from 'react-router-dom';
import SelectOption from '../../components/common/selectOption';
import CustomButton from '../../components/common/customButton';
import Swal from 'sweetalert2';
import Logo from '../../assets/image/idealILogo.jpeg'
const IIFCO_TOKIO = () => {
    const navigate = useNavigate();
    const { base_url, user } = AuthDetail();
    const [userData, setUserData] = useState({});
    const [isLoading, setIsLoading] = useState(false);
    const [startDate, setStartDate] = useState(new Date());
    const [policyType, setPolicyType] = useState([]);
    const [policyTypeData, setPolicyTypeData] = useState("");
    const [commission, setCommission] = useState("");
    const [policyTypeError, setPolicyTypeError] = useState(false);
    useEffect(() => {
        if (user != null) {
            setUserData(JSON.parse(user));
        } else {
            navigate('/login')
        }
        selectPolicyType(startDate.getMonth() + 1, startDate.getFullYear());
    }, [])
    const onChangedate = (e) => {
        setStartDate(e);
        selectPolicyType(e.getMonth() + 1, e.getFullYear());
    }
    const selectPolicyType = async (startDate, year) => {
        setIsLoading(true);
        setPolicyTypeData("");
        try {
            const res = await base_url.post(`/twoWheeler/iifco_tokio/selectPolicyType`, {
                "date": startDate,
                "year": year,
            });
            if (res.data.status === 200) {
                setPolicyType(res.data.data)
                setIsLoading(false);
            } else {
                setIsLoading(false);
            }
        } catch (error) {
            setIsLoading(false);
        }

    }
    const handleChangePolicyType = async (e) => {
        const value = e.target.value;
        setPolicyTypeData(value);
        setIsLoading(true);
        setPolicyTypeError(false);
        try {
            const res = await base_url.post(`/twoWheeler/iifco_tokio/selectCommission`, {
                "date": startDate.getMonth() + 1,
                "year": startDate.getFullYear(),
                "policyType": value.split('_').join(' ')
            });
            if (res.data.status === 200) {
                setCommission(res.data.data[0])
                setIsLoading(false);
            } else {
                setIsLoading(false);
            }
        } catch (error) {
            setIsLoading(false);
        }

    }
    const handleClick = () => {
        if (policyTypeData == "") {
            setPolicyTypeError(true);
        }
        if (policyTypeData != "") {
            Swalll();
        }
    }

    const Swalll = () => {
        console.log(commission);
        return (
            Swal.fire({
                title: `IFFCO TOKIO`,
                text: 'Modal with a custom image.',
                html: `<table class="table text-start">
                <tbody> 
                  <tr> 
                   <td>Insurance Type </td> 
                   <td style="text-transform: capitalize;white-space: pre-wrap !important;">${policyTypeData.split('_').join(' ')}</td>
                  </tr>  
                  <tr>            
                  <td>Commission</td>
                   <td style="text-transform: capitalize;white-space: pre-wrap !important;">${commission.COMMISSION}${/[a-zA-Z]/g.test(commission.COMMISSION) ? "" : "%"}</td> 
                 </tr> 
                 <tr>            
                 <td>Remarks</td>
                  <td style="text-transform: capitalize;white-space: pre-wrap !important;">${commission.REMARKS}</td> 
                </tr> 
                </tbody> 
              </table>`,
                imageUrl: `${Logo}`,
                imageWidth: 400,
                imageHeight: 200,
                imageAlt: 'Custom image',
                confirmButtonText: 'OK',
                confirmButtonColor: 'green',
            })
        )
    }
    return (
        <div className='container-fluid mt-3  rounded position-top-right-center mt-5'>
            <div className='container '>
                <div className='row'>
                    {isLoading && <Spinner />}
                    <div className="mb-3 mx-auto text-center col-12 col-lg-8 col-md-8 col-sm-12 ">
                        <div className="mb-4 ">
                            <h1 className='form-heading text-center text-uppercase'> IFFCO TOKIO TWO WHEELER Insurance</h1>
                        </div>
                        {
                            userData.user_type !== "user" ? <Datepicker onChangedate={onChangedate} data={startDate} /> : ""
                        }
                        <SelectOption
                            title='Insurance Type'
                            optionTitle='Select Insurance Type'
                            data={policyType}
                            onChange={handleChangePolicyType}
                            errors={policyTypeError}
                            value={policyTypeData}
                        />


                        <CustomButton
                            title={'search'}
                            handleClick={handleClick}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default IIFCO_TOKIO;
