import React, { useState, useEffect, useContext } from 'react'
import { NavLink } from 'react-router-dom';
import AuthDetail from '../common/auth';
import { useNavigate } from 'react-router-dom';
import { UserTypeContext } from '../../UserTypeContext';
const Navbar = () => {
    const { userType } = useContext(UserTypeContext);
    const navigate = useNavigate();
    const [userData, setUserData] = useState({});
    const [stickyClass, setStickyClass] = useState('');
    const { base_url, logout, user } = AuthDetail();
    useEffect(() => {
        // window.addEventListener('scroll', stickNavbar);
        // return () => window.removeEventListener('scroll', stickNavbar);
        if (user != null) {
            setUserData(JSON.parse(user));
        }
        // console.log(user)
    }, []);

    const stickNavbar = () => {
        if (window !== undefined) {
            let windowHeight = window.scrollY;
            // window height changed for the demo
            windowHeight > 70 ? setStickyClass('sticky-nav') : setStickyClass('');
        }
    };
    const logoutUser = () => {
        logout();
        navigate('/login')
        window.location.href = 'http://matrix.idealinsurance.in/';
        // window.location.href = 'https://basudevnayak.in/';
    }
    return (
        <>
            {/* <div className={`container-fluid shadow-sm p-1 bg-body rounded nav-container ${stickyClass}`}></div> */}
            <div className={`container-fluid shadow-sm bg-body rounded nav-container `}>
                <nav className="navbar navbar-expand-lg ">
                    <div className="container" >
                        {/* <NavLink to='/' className="navbar-brand">Home</NavLink> */}
                        <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                            <span className="navbar-toggler-icon"></span>
                        </button>
                        <div className="collapse navbar-collapse flex justify-content-between align-content-center" id="navbarNav">
                            <ul className="navbar-nav">
                                <li className="nav-item">
                                    <NavLink to='/' className="nav-link" aria-current="page">Home</NavLink>
                                </li>
                                <li className="nav-item">
                                    <NavLink to='/motor' className="nav-link" aria-current="page">Private Car</NavLink>
                                </li>
                                <li className="nav-item">
                                    <NavLink to='/two-wheeler' className="nav-link">Two Wheeler</NavLink>
                                </li>
                                <li className="nav-item">
                                    <NavLink to='/health' className="nav-link">Health</NavLink>
                                </li>
                                <li className="nav-item">
                                    <NavLink to='/Commercial' className="nav-link">Commercial</NavLink>
                                </li>

                                {userData.user_type == "super_admin" ?
                                    <li className="nav-item"><NavLink to='/matrix/dashboard' className="nav-link">Dashboard</NavLink>
                                    </li>
                                    : ""}
                            </ul>
                            <div style={{
                                width: '50px',
                                height: '50px',
                                textAlign: 'center',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                cursor: 'pointer'
                            }}
                                onClick={logoutUser}
                            >
                                <NavLink to='#' className="nav-link"> <img src="./icon/logout.png" alt="" className="img-fluid w-50 h-25" title="Logout" /></NavLink>
                            </div>
                        </div>
                    </div>
                </nav>
            </div>

        </>
    )
}

export default Navbar