import React, { useState, useEffect } from 'react';
import AuthDetail from '../../components/common/auth';
import Datepicker from '../../components/common/datepicker';
import Spinner from '../../components/spinner';
import CustomButton from '../../components/common/customButton';
import SelectOption from '../../components/common/selectOption';
import Logo from '../../assets/image/idealILogo.jpeg';
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
const date_arr = { 1: 'Jan', 2: 'Feb', 3: 'Mar', 4: 'Apr', 5: 'May', 6: 'Jun', 7: 'Jul', 8: 'Aug', 9: 'Sep', 10: 'Oct', 11: 'Nov', 12: 'Dec' };
const NEW_INDIA_Health = () => {
    const { base_url, user } = AuthDetail();
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false);
    const [userData, setUserData] = useState({});
    const [startDate, setStartDate] = useState(new Date());
    const [productArr, setProductArr] = useState([]);
    const [productDate, setProductData] = useState("");
    const [productDateError, setProductDataError] = useState("");
    const [Age, setAge] = useState([]);
    const [AgeData, setAgeData] = useState("");
    const [AgeDataError, setAgeError] = useState(false);
    const [YEAR, setYEAR] = useState([]);
    const [YEARData, setYEARData] = useState("");
    const [YEARDataError, setYEARError] = useState(false);
    const [CLAIM_RATIO, setCLAIM_RATIO] = useState([]);
    const [CLAIM_RATIO_Data, setCLAIM_RATIO_Data] = useState("");
    const [CLAIM_RATIO_Data_Error, setCLAIM_RATIO_Data_Error] = useState(false);
    const [RATE, setRATE] = useState("");
    useEffect(() => {
        setIsLoading(true);
        if (user != null) {
            setUserData(JSON.parse(user));
        } else {
            navigate('/login')
        }
        selectProduct(`${date_arr[startDate.getMonth() + 1]}-${startDate.getFullYear()}`);

    }, []);
    const onChangedate = (e) => {
        let date = new Date(e)
        setStartDate(date);
        selectProduct(`${date_arr[date.getMonth() + 1]}-${date.getFullYear()}`);
        setProductData("");
        setAgeData("");
        setYEARData("");
        setCLAIM_RATIO_Data("");
        setProductArr([]);
        setAge([]);
        setYEAR([]);
        setCLAIM_RATIO([]);
    }
    const selectProduct = async (currentDate) => {
        setIsLoading(true);
        try {
            const res = await base_url.post(`/health`, {
                where: { DATE: currentDate, INSURANCE_COMPANY: "NEW INDIA" },
                fields: ["TYPE"]
            });
            if (res.data.status === 200) {
                const data = res.data.data.map((data, index) => {
                    return { label: data.TYPE, value: data.TYPE }
                })
                setProductArr(data);
                setIsLoading(false);
            } else {

                setIsLoading(false);
            }
        } catch (error) {
            console.log(error);
        }
    }
    const currentDate = `${date_arr[startDate.getMonth() + 1]}-${startDate.getFullYear()}`;
    const onchangeProduct = async (e) => {
        const value = e.value;
        setProductData(value);
        setProductDataError(false);
        setIsLoading(true);
        setYEAR([]);
        setCLAIM_RATIO([]);
        setAgeData("");
        setYEARData("");
        setCLAIM_RATIO_Data("");
        try {
            const res = await base_url.post(`/health`, {
                where: { DATE: currentDate, INSURANCE_COMPANY: "NEW INDIA", TYPE: value },
                fields: ["AGE"]
            });
            if (res.data.status === 200) {
                const data = res.data.data.map((data, index) => {
                    return { label: data.AGE, value: data.AGE }
                })
                setAge(data);
                setIsLoading(false);
            } else {

                setIsLoading(false);
            }
        } catch (error) {
            console.log(error);
        }
    }
    const onchangeAge = async (e) => {
        const value = e.value;
        setAgeData(value);
        setAgeError(false);
        setIsLoading(true);
        setCLAIM_RATIO([]);
        setYEARData("");
        setCLAIM_RATIO_Data("");
        try {
            const res = await base_url.post(`/health`, {
                where: { DATE: currentDate, INSURANCE_COMPANY: "NEW INDIA", TYPE: productDate, AGE: value },
                fields: ["YEAR"]
            });
            if (res.data.status === 200) {
                const data = res.data.data.map((data, index) => { return { label: data.YEAR, value: data.YEAR } });
                setYEAR(data);
                setIsLoading(false);
            } else {
                setIsLoading(false);
            }
        } catch (error) {
            console.log(error);
        }
    }
    const onchangeYear = async (e) => {
        const value = e.value;
        setYEARData(value);
        setYEARError(false);
        setCLAIM_RATIO_Data("");
        try {
            const res = await base_url.post(`/health`, {
                where: { DATE: currentDate, INSURANCE_COMPANY: "NEW INDIA", TYPE: productDate, AGE: AgeData, YEAR: value },
                fields: ["CLAIM_RATIO"]
            });
            if (res.data.status === 200) {
                const data = res.data.data.map((data, index) => { return { label: data.CLAIM_RATIO, value: data.CLAIM_RATIO } });
                setCLAIM_RATIO(data);
                setIsLoading(false);
            } else {
                setIsLoading(false);
            }
        } catch (error) {
            console.log(error);
        }
    }
    const onchangeClaimRatio = async (e) => {
        const value = e.value;
        setCLAIM_RATIO_Data(value);
        setCLAIM_RATIO_Data_Error(false);
        try {
            const res = await base_url.post(`/health`, {
                where: { DATE: currentDate, INSURANCE_COMPANY: "NEW INDIA", TYPE: productDate, AGE: AgeData, YEAR: YEAR, CLAIM_RATIO: value },
                fields: ["RATE"]
            });
            if (res.data.status === 200) {
                setRATE(res.data.data[0].RATE);
                setIsLoading(false);
            } else {
                setIsLoading(false);
            }
        } catch (error) {
            console.log(error);
        }
    }
    const handleClick = () => {
        if (productDate == "") {
            setProductDataError(true)
        }
        if (AgeData == "") {
            setAgeError(true);
        }
        if (YEARData == "") {
            setYEARError(true);
        }
        if (CLAIM_RATIO_Data == "") {
            setCLAIM_RATIO_Data_Error(true);
        }
        if (productDate != "" && AgeData != "" && YEARData != "" && CLAIM_RATIO_Data) {
            Swal.fire({
                title: `NEW INDIA HEALTH INSURANCE`,
                text: 'Modal with a custom image.',
                html: `<table class="table text-start">
                <tbody>
                <tr>
                <td>PolicyType</td>
                <td>${productDate}</td>
              </tr> 
              <tr>
                <td>Age</td>
                <td>${AgeData}</td>
                 </tr> 
                 <tr>
                 <td>Year</td>
                 <td>${YEARData}</td>
                  </tr> 
                  <tr>
                  <td>Claim Ratio</td>
                  <td>${CLAIM_RATIO_Data}</td>
                   </tr> 
                  <tr> 
                  <td>Commission</td>
                   <td style="text-transform: capitalize;white-space: pre-wrap !important;">${RATE == "" ? 0 : RATE}%</td> 
                 </tr> 
                </tbody> 
              </table>`,
                imageUrl: `${Logo}`,
                imageWidth: 50,
                imageHeight: 50,
                imageAlt: 'Custom image',
                confirmButtonText: 'OK',
                confirmButtonColor: 'green',
            })
        }
    }
    return (
        <div className='container-fluid mt-3  rounded position-top-right-center mt-5'>
            <div className='container '>
                <div className='row'>
                    {isLoading && <Spinner />}
                    <div className="mb-3 mx-auto text-center col-12 col-lg-8 col-md-8 col-sm-12 ">
                        <div className="mb-4 ">
                            <h1 className='form-heading text-center text-uppercase'>NEW INDIA HEALTH  Insurance</h1>
                        </div>
                        {
                            userData.user_type !== "user" ? <Datepicker onChangedate={onChangedate} data={startDate} /> : ""
                        }
                        <SelectOption title={'Product'} data={productArr} onChange={onchangeProduct} value={productDate} errors={productDateError} />
                        <SelectOption title={'Age'} data={Age} onChange={onchangeAge} value={AgeData} errors={AgeDataError} />
                        <SelectOption title={'Year'} data={YEAR} onChange={onchangeYear} value={YEARData} errors={YEARDataError} />
                        <SelectOption title={'Claim Ratio'} data={CLAIM_RATIO} onChange={onchangeClaimRatio} value={CLAIM_RATIO_Data} errors={CLAIM_RATIO_Data_Error} />
                        <CustomButton title={'search'} handleClick={handleClick} />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default NEW_INDIA_Health
