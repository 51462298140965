import React, { useParams, useState, useContext } from 'react';
import { useNavigate } from "react-router-dom";
import {
  TextField,
  Button
} from '@mui/material';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import AuthDetail from './common/auth';
import { useFormik } from 'formik';
import { singUpSchema } from '../schemas';
import InputAdornment from '@mui/material/InputAdornment';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import IconButton from '@mui/material/IconButton';
// import { TabTitle } from './TabTitle';
import FormControl from '@mui/material/FormControl';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import { UserTypeContext } from '../UserTypeContext';
const initialValues = {
  email: "",
  password: "",
}
const Login = () => {
  //   TabTitle('LoginPage')
  const { setUserType } = useContext(UserTypeContext);
  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const navigate = useNavigate();
  const { base_url, setToken } = AuthDetail();
  const { values, errors, touched, handleBlur, handleChange, handleSubmit } = useFormik({
    initialValues: initialValues,
    validationSchema: singUpSchema,
    onSubmit: async (values, action) => {
      try {
        const res = await base_url.post(`/login`, values);
        const data = res.data;
        if (data.success === true) {
          // toast.success('Success Notification !', { autoClose: 2000, });
          var myHeaders = new Headers();
          myHeaders.append("Authorization", `Bearer ${data.data.token}`);
          var requestOptions = {
            method: 'GET',
            headers: myHeaders,
            redirect: 'follow'
          };
          const response = await base_url.get(`/user`, {
            'headers': {
              "content-type": "multipart/form-data",
              Authorization: `Bearer ${data.data.token}`,
            }
          });
          if (response.status === 200) {
            const response = await base_url.get(`/user`, {
              'headers': {
                "content-type": "multipart/form-data",
                Authorization: `Bearer ${data.data.token}`,
              }
            });
            if (response.status === 200) {
              setToken(JSON.stringify(response.data), data.data.token);
              setUserType(response.data);
              // navigate("/");
              // window.location.href = 'https://basudevnayak.in/';
              window.location.href = 'http://matrix.idealinsurance.in/';
            }
          }
        } else {
          toast.error('Something Went to wrong !', { autoClose: 2000, });

        }
      } catch (error) {
        toast.error(error);
      }
      // action.resetForm();
    },
  });
  //   const componentDidMount=()=>{
  //     window.addEventListener("beforeunload", function (event) {
  //          console.log("hellooww")
  //          event.returnValue = "Hellooww"
  //      })
  //  }

  //  constcomponentWillUnmount=()=> {
  //      window.removeEventListener("beforeunload", function (event) {
  //          console.log("hellooww")
  //          event.returnValue = "Hellooww"
  //      })
  //  }
  //  componentDidMount();
  return (
    <div className='container-fluid' style={{
      position: 'absolute',
      top: 0, left: 0, width: '100%',
      height: '100%',
      background: '#FFF',
      transform: 'translateY(-50%,50%)'
    }}>
      <div className="row mt-5">
        <div className="col-sm-12 col-md-10 col-lg-6 m-auto mt-5">
          <ToastContainer />
          <div className="card shadow rounded-2 my-5 border border-0">
            <div className="card-body  p-sm-5 text-center">
              <img src='./icon/idealILogo.jpeg' className='LoginLogo' />
              <form onSubmit={handleSubmit}>
                <div className=" mb-3 my-2">
                  <TextField
                    id="outlined-multiline-flexible"
                    label="Email-id   "
                    type={"email"}
                    variant="outlined"
                    sx={{
                      width: '100%', height: '20%',
                      "& .MuiOutlinedInput-root": {
                        "& > fieldset": { borderColor: errors.email && touched.email ? 'red' : '#236348' },
                      },
                      "& .MuiOutlinedInput-root.Mui-focused": {
                        "& > fieldset": {
                          borderColor: '#236348',
                          border: errors.email && touched.email ? '1px solid red' : '1px solid #236348'
                        }
                      }
                    }}
                    className='mt-4'
                    name="email"
                    value={values.email}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  {/* {errors.email && touched.email ? (
                      <p className="form-error text-danger float-start">{errors.email}</p>
                    ) : null} */}
                </div>
                <div className="mb-4">
                  <FormControl sx={{
                    width: '100%', height: '50px',
                    "& .MuiOutlinedInput-root": {
                      "& > fieldset": { borderColor: errors.password && touched.password ? 'red' : '#236348' },
                    },
                    "& .MuiOutlinedInput-root.Mui-focused": {
                      "& > fieldset": {
                        borderColor: '#236348',
                        border: errors.password && touched.password ? '1px solid red' : '1px solid #236348'
                      }
                    }
                  }} variant="outlined">
                    <InputLabel htmlFor="outlined-adornment-password">Password</InputLabel>
                    <OutlinedInput
                      id="outlined-adornment-password"
                      type={showPassword ? 'text' : 'password'}
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword}
                            onMouseDown={handleMouseDownPassword}
                            edge="end"
                          >
                            {showPassword ? <VisibilityOff color="success" /> : <Visibility color="success" />}
                          </IconButton>
                        </InputAdornment>
                      }
                      label="Password"
                      name="password"
                      value={values.password}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                  </FormControl>
                  {/* {errors.password && touched.password ? (
                      <p className="form-error text-danger float-start mb-0">{errors.password}</p>
                    ) : null} */}
                </div>
                <div className="d-grid mt-2 mb-4 w-100">
                  <button type='submit' className='btn col-12' > SignIn </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Login