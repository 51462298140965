import React, { useState, useEffect } from 'react';
import Datepicker from '../../components/common/datepicker';
import AuthDetail from '../../components/common/auth';
import Spinner from '../../components/spinner';
import { useNavigate } from 'react-router-dom';
import SelectOption from '../../components/common/selectOption';
import Select from '../../components/Select';
import CustomButton from '../../components/common/customButton';
import Swal from 'sweetalert2';
import Logo from '../../assets/image/idealILogo.jpeg';
const Liberty_four_wheeler = () => {
    const navigate = useNavigate();
    const { base_url, user } = AuthDetail();
    const [userData, setUserData] = useState({});
    const [isLoading, setIsLoading] = useState(false);
    const [startDate, setStartDate] = useState(new Date());
    const [location, setLocation] = useState([]);
    const [Private_car_type, setPrivate_car_type] = useState([]);
    const [Private_car_typeKey, setPrivate_car_typeKey] = useState([]);
    const [SubInsuranceType, setSubInsuranceType] = useState([]);
    const [SubInsuranceTypeKey, setSubInsuranceTypeKey] = useState([]);
    const [ncb, setNcb] = useState([]);
    const [ncbKey, setNcbKey] = useState([]);
    const [commission, setCommission] = useState("");
    const [locationData, setLocationData] = useState("");
    const [Private_car_type_data, setPrivate_car_type_data] = useState("");
    const [SubInsuranceTypeData, setSubInsuranceTypeData] = useState("");
    const [ncbData, setNcbData] = useState("");
    const [locationError, setLocationError] = useState(false);
    const [Private_car_type_data_error, setPrivate_car_type_data_error] = useState(false);
    const [SubInsuranceTypeDataErroe, setSubInsuranceTypeDataError] = useState(false);
    const [ncbDataError, setNcbDataError] = useState("");
    useEffect(() => {
        if (user != null) {
            setUserData(JSON.parse(user));
        } else {
            navigate('/login')
        }
        selectLocation(startDate.getMonth() + 1, startDate.getFullYear());
    }, []);
    const onChangedate = (e) => {
        setStartDate(e);
        setLocation([]);
        setPrivate_car_type([]);
        setPrivate_car_typeKey([]);
        setSubInsuranceType([]);
        setSubInsuranceTypeKey([]);
        setNcb([]);
        setNcbKey([]);
        setLocationData("");
        setPrivate_car_type_data("");
        setSubInsuranceTypeData("");
        setNcbData("");
        selectLocation(e.getMonth() + 1, e.getFullYear());
    }
    const selectLocation = async (startDate, year) => {
        try {
            const res = await base_url.post(`/motor/libertyFourWheeler/selectLOCATION`, {
                "date": startDate,
                "year": year,
            });
            if (res.data.status === 200) {
                setLocation(res.data.data)
                setIsLoading(false);
            } else {
                setIsLoading(false);
            }
        } catch (error) {
            setIsLoading(false);
            console.log(error);
        }
    }
    const handleChangeLocation = async (e) => {
        const value = e.target.value;
        setLocationData(value);
        setLocationError(false);
        setPrivate_car_type_data("");
        setSubInsuranceType([]);
        setSubInsuranceTypeKey([]);
        setNcb([]);
        setNcbKey([]);
        setPrivate_car_type_data("");
        setSubInsuranceTypeData("");
        setNcbData("");
        try {
            const res = await base_url.post(`/motor/libertyFourWheeler/selectPRIVATE_CAR_TYPE`, {
                "date": startDate.getMonth() + 1,
                "year": startDate.getFullYear(),
                "location": value.split('_').join(' ')
            });
            if (res.data.status === 200) {
                setPrivate_car_type(res.data.data)
                setPrivate_car_typeKey(res.data.dataValue);
                setIsLoading(false);
            } else {
                setIsLoading(false);
            }
        } catch (error) {
            setIsLoading(false);
            console.log(error);
        }
    }
    const handleChangeInsuranceType = async (e) => {
        const value = e.target.value;
        setPrivate_car_type_data(value);
        setPrivate_car_type_data_error(false)
        setNcb([]);
        setNcbKey([]);
        setSubInsuranceTypeData("");
        setNcbData("");
        try {
            const res = await base_url.post(`/motor/libertyFourWheeler/policyType`, {
                date: startDate.getMonth() + 1,
                year: startDate.getFullYear(),
                location: locationData.split('_').join(' '),
                policyType: value,
            });
            if (res.data.status === 200) {
                setSubInsuranceType(res.data.dataValue)
                setSubInsuranceTypeKey(res.data.data);
                setIsLoading(false);
            } else {
                setIsLoading(false);
            }
        } catch (error) {
            setIsLoading(false);
            console.log(error);
        }
    }
    const handleChangeInsuranceSubType = async (e) => {
        const value = e.target.value;
        setSubInsuranceTypeData(value);
        setSubInsuranceTypeDataError(false);
        setNcbData("");
        try {
            const res = await base_url.post(`/motor/libertyFourWheeler/subPolicyType`, {
                date: startDate.getMonth() + 1,
                year: startDate.getFullYear(),
                location: locationData.split('_').join(' '),
                policyType: Private_car_type_data,
                policyTypeSub: value,
            });
            if (res.data.status === 200) {
                if (Private_car_type_data == 'NEW') {
                    setCommission(res.data.data);
                } else {
                    setNcb(res.data.dataValue)
                    setNcbKey(res.data.data);
                }
                setIsLoading(false);
            } else {
                setIsLoading(false);
            }
        } catch (error) {
            setIsLoading(false);
            console.log(error);
        }
    }
    const handleChangeInsuranceNCB = async (e) => {
        const value = e.target.value;
        setNcbData(value);
        setNcbDataError(false);
        try {
            const res = await base_url.post(`/motor/libertyFourWheeler/ncb`, {
                date: startDate.getMonth() + 1,
                year: startDate.getFullYear(),
                location: locationData.split('_').join(' '),
                policyType: SubInsuranceTypeData,
                ncb: value,
            });
            if (res.data.status === 200) {
                setCommission(res.data.data);
                setIsLoading(false);
            } else {
                setIsLoading(false);
            }
        } catch (error) {
            setIsLoading(false);
            console.log(error);
        }
    }
    const handleClick = () => {
        if (locationData == "") {
            setLocationError(true)
        }
        if (Private_car_type_data == "") {
            setPrivate_car_type_data_error(true)
        }
        if (SubInsuranceTypeData == "" && Private_car_type_data != 'NEW') {
            setSubInsuranceTypeDataError(true);
        }
        if (ncbData == "" && Private_car_type_data != 'NEW') {
            setNcbDataError(true)
        }
        if (locationData != "" && Private_car_type_data != "" && SubInsuranceTypeData != "" && ncbData != "" && Private_car_type_data != 'NEW') {
            Swalll();
        } else {
            if (locationData != "" && Private_car_type_data != "" && SubInsuranceTypeData != "") {
                Swalll();
            }
        }
    }
    const Swalll = () => {
        return (
            Swal.fire({
                title: `LIBERTY FOUR WHEELER `,
                text: 'Modal with a custom image.',
                html: `
                <table class="table text-start">
                <tbody>
                  <tr>
                    <td>Location</td>
                    <td>${locationData.split('_').join(' ')}</td>
                  </tr> 
                <tr>
                <td>Insurance  Type </td>
                    <td style="text-transform: capitalize;white-space: pre-wrap !important;">${Private_car_type_data.split('_').join(' ')}</td> 
                  </tr>
                 <tr> 
                   <td>Insurance Sub Type </td>
                    <td style="text-transform: capitalize;white-space: pre-wrap !important;">${SubInsuranceTypeData.split('_').join(' ')}</td> 
                  </tr>
                  <tr> 
                  ${Private_car_type_data != 'NEW' && Private_car_type_data != 'ACT_ONLY_THIRD_PARTY' ? `<tr> 
                  <td>Is NCB </td>
                   <td style="text-transform: capitalize;white-space: pre-wrap !important;">${ncbData.split('_').join(' ')}</td> 
                 </tr>`: ""}
                  <td>Commission</td>
                   <td style="text-transform: capitalize;white-space: pre-wrap !important;">${commission}%${/[a-zA-Z]/g.test(commission) ? "" : ""}</td> 
                 </tr> 
                </tbody> 
              </table>`,
                imageUrl: `${Logo}`,
                imageWidth: 400,
                imageHeight: 200,
                imageAlt: 'Custom image',
                confirmButtonText: 'OK',
                confirmButtonColor: 'green',
            })
        )
    }
    return (
        <div className='container-fluid mt-3  rounded position-top-right-center mt-5'>
            <div className='container '>
                <div className='row'>
                    {isLoading && <Spinner />}
                    <div className="mb-3 mx-auto text-center col-12 col-lg-8 col-md-8 col-sm-12 ">
                        <div className="mb-4 ">
                            <h1 className='form-heading text-center text-uppercase'> LIBERTY four wheeler insurance</h1>
                        </div>
                        {
                            userData.user_type !== "user" ? <Datepicker onChangedate={onChangedate} data={startDate} /> : ""
                        }
                        <SelectOption
                            title='Location'
                            optionTitle='Select Location'
                            data={location}
                            onChange={handleChangeLocation}
                            errors={locationError}
                            value={locationData}
                        />
                        <Select
                            title={'Insurance Type'}
                            optionTitle={'Select Insurance Type'}
                            data={Private_car_type}
                            dataKey={Private_car_typeKey}
                            onChange={handleChangeInsuranceType}
                            value={Private_car_type_data}
                            errors={Private_car_type_data_error}
                        />
                        <Select
                            title={'Sub Insurance Type'}
                            optionTitle={'Select Sub Insurance Type'}
                            data={SubInsuranceType}
                            dataKey={SubInsuranceTypeKey}
                            onChange={handleChangeInsuranceSubType}
                            value={SubInsuranceTypeData}
                            errors={SubInsuranceTypeDataErroe}
                        />
                        {Private_car_type_data != 'NEW' && Private_car_type_data != 'ACT_ONLY_THIRD_PARTY' && <Select
                            title={'Is NCB'}
                            optionTitle={'Select NCB'}
                            data={ncb}
                            dataKey={ncbKey}
                            onChange={handleChangeInsuranceNCB}
                            value={ncbData}
                            errors={ncbDataError}
                        />}
                        <CustomButton
                            title={'search'}
                            handleClick={handleClick}
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Liberty_four_wheeler
