import React, { useState, useEffect } from 'react'
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
  Divider,
  AddCircleIcon,
  Button,
  Box,
  Stack,
  SideNav,
  Autocomplete,
  TextField,
  Grid,
  Modal,
  Card,
  Input
} from '@mui/material';
import * as XLSX from "xlsx";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import AuthDetail from '../components/AuthDetail';
import EditForm from '../components/EditForm';
import { ToastContainer, toast } from 'react-toastify';
import { useNavigate } from "react-router-dom";
const TataAig = () => {
  const navigate = useNavigate();
  const { base_url, setToken } = AuthDetail();
  const [rowsData, setRows] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [editOpen, setEditOpen] = useState(false);
  const [editData, setEditData] = useState({

  })
  const handleEditClose = () => setEditOpen(false);
  const editDataTable = (data) => {
    setEditOpen(true)
    setEditData(data);
  }
  const deleteUser = () => {
  }
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  useEffect(() => {
    getDataTata();
  }, [])
  const getDataTata = async () => {
    try {
      const res = await base_url.get(`/health/tata/selectTataData`);
      const data = res.data;
      if (data.status === 200) {
        setRows(data.data);
      }
    } catch (error) {
      console.table(error);
    }
  }
  const handleSaved = async (dataValue) => {
    try {
      const res = await base_url.post('/health/tata/selectTataDataEdit', dataValue);
      const data = res.data;
      if (data.status === 200) {
        getDataTata();
        setTimeout(() => {
          navigate("/121/dashboard/TataAig");
        }, 2000);
        toast.success('Success Notification !', { autoClose: 2000, });
      } else {
        toast.error('Something Went to wrong !', { autoClose: 2000, });
      }
    } catch (error) {
      console.table(error);
    }
  }
  const [data, setData] = useState([]);
  return (
    <Paper sx={{ width: "100%", overflow: "hidden", padding: "12px" }} className='mx-3 my-3'>
      <Modal
        open={editOpen}
        // onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          bgcolor: 'background.paper',
          p: 5,
          border: '1px solid #666',
          boxShadow: 24,
          width: { xs: "95%", md: "70%", lg: "40%" },
        }} >
          <EditForm closeEvent={handleEditClose} saveEvent={handleSaved} editData={editData} url='/health/tata/selectTataDataEdit' />
        </Box>
      </Modal>
      <Typography
        gutterBottom
        variant="h5"
        component="div"
        sx={{ padding: "20px" }}
      >
        TATA-AIG
      </Typography>
      <Divider />
      <Box height={10} />
      <TableContainer
      // component={Paper}
      >
        <Table sx={{ minWidth: 700 }} aria-label="customized table">
          <TableHead>
            <TableRow>
              <TableCell>Id</TableCell>
              <TableCell>PRODUCT_NAME</TableCell>
              <TableCell>SUN_INSURED</TableCell>
              <TableCell>NEW</TableCell>
              <TableCell>PORT</TableCell>
              <TableCell>RENEW</TableCell>
              <TableCell>Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {
              rowsData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((data, index) => {
                return (
                  <TableRow
                    hover
                    role="checkbox"
                    tabIndex={-1}
                    key={index}
                  >
                    <TableCell>{data.ID}</TableCell>
                    <TableCell>{data.PRODUCT_NAME}</TableCell>
                    <TableCell>{data.SUN_INSURED}</TableCell>
                    <TableCell>{data.NEW}</TableCell>
                    <TableCell>{data.PORT}</TableCell>
                    <TableCell>{data.RENEW}</TableCell>
                    <TableCell align="left">
                      <Stack spacing={2} direction="row">
                        <EditIcon
                          style={{
                            fontSize: "20px",
                            color: "blue",
                            cursor: "pointer",
                          }}
                          className="cursor-pointer"
                          onClick={() => editDataTable(data)}
                        />
                        {/* <DeleteIcon
                        style={{
                          fontSize: "20px",
                          color: "darkred",
                          cursor: "pointer",
                        }}
                        onClick={() => {
                          deleteUser(data.id);
                        }}
                      /> */}
                      </Stack>
                    </TableCell>
                  </TableRow>
                )
              })
            }
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[1, 5, 10, 25]}
        component="div"
        count={rowsData.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Paper>
  )
}

export default TataAig