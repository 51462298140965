import React, { useState, useEffect } from 'react';
import Datepicker from '../../components/common/datepicker';
import AuthDetail from '../../components/common/auth';
import Spinner from '../../components/spinner';
import { useNavigate } from 'react-router-dom';
import SelectOption from '../../components/common/selectOption';
import CustomButton from '../../components/common/customButton';
import Swal from 'sweetalert2';
import Logo from '../../assets/image/idealILogo.jpeg';
const BajajMotor = () => {
    const navigate = useNavigate();
    const { base_url, user } = AuthDetail();
    const [userData, setUserData] = useState({});
    const [isLoading, setIsLoading] = useState(false);
    const [startDate, setStartDate] = useState(new Date());
    const [policyType, setPolicyType] = useState([]);
    const [variant, setVariant] = useState([]);
    const [selectPolicyDescription, SetselectPolicyDescription] = useState("")
    const [stateData, setStateData] = useState("")
    const [selectPolicyDescriptionError, SetselectPolicyDescriptionError] = useState(false)
    const [stateDataError, setStateDataError] = useState(false)
    const [commission, selectCommission] = useState("");
    const onChangedate = (e) => {
        setPolicyType([]);
        setVariant([]);
        setStartDate(e);
        selectPolicyType(e.getMonth() + 1, e.getFullYear());
    }
    useEffect(() => {
        if (user != null) {
            setUserData(JSON.parse(user));
        } else {
            navigate('/login')
        }
        selectPolicyType(startDate.getMonth() + 1, startDate.getFullYear());
    }, []);
    const selectPolicyType = async (startDate, year) => {
        setIsLoading(true);
        try {
            const res = await base_url.post(`/motor/bajaj/selectPolicyType`, {
                "date": startDate,
                "year": year,
            });
            if (res.data.status === 200) {
                setPolicyType(res.data.data)
                setIsLoading(false);
            } else {
                setIsLoading(false);
            }
        } catch (error) {
            setIsLoading(false);
            console.log(error);
        }
    }
    const handleChangePolicyType = async (e) => {
        const value = e.target.value;
        SetselectPolicyDescription(value);
        SetselectPolicyDescriptionError(false)
        setVariant([]);
        setStateData("");
        setIsLoading(true);
        try {
            const res = await base_url.post(`/motor/bajaj/selectPolicyDescription`, {
                "date": startDate.getMonth() + 1,
                "year": startDate.getFullYear(),
                "policyType": value.split('_').join(' ')
            });
            if (res.data.status === 200) {
                setVariant(res.data.data)
                setIsLoading(false);
            } else {
                setIsLoading(false);
            }
        } catch (error) {
            setIsLoading(false);
            console.log(error);
        }

    }
    const handleChangePolicyTypeItem = async (e) => {
        const value = e.target.value;
        setStateData(value);
        setStateDataError(false);
        setIsLoading(true);
        try {
            const res = await base_url.post(`/motor/bajaj/selectCommission`, {
                "date": startDate.getMonth() + 1,
                "year": startDate.getFullYear(),
                "policyType": selectPolicyDescription.split('_').join(' '),
                "policy_type_item": value.split('_').join(' '),
            });
            if (res.data.status === 200) {
                selectCommission(res.data.data)
                setIsLoading(false);
            } else {
                setIsLoading(false);
            }
        } catch (error) {
            setIsLoading(false);
            console.log(error);
        }
    }
    const handleClick = () => {
        if (selectPolicyDescription == "") {
            SetselectPolicyDescriptionError(true)
        }
        if (stateData == "") {
            setStateDataError(true)
        }
        if (selectPolicyDescription != "" && stateData != "") {
            Swalll();
        }
    }

    const Swalll = () => {
        return (
            Swal.fire({
                title: `BAJAJ FOUR WHEELER`,
                text: 'Modal with a custom image.',
                html: `<table class="table text-start">
                <tbody> 
                  <tr> 
                   <td>Policy Type </td> 
                   <td style="text-transform: capitalize;white-space: pre-wrap !important;">${selectPolicyDescription.split('_').join(' ')}</td>
                  </tr> 
                  <tr> 
                  <td>${selectPolicyDescription == "Stand_Alone_Third_Party" ? "State" : "Vehicle Description"}</td>
                  <td style="text-transform: capitalize;white-space: pre-wrap !important;">${stateData.split('_').join(' ')}</td> 
                </tr>                
                  <td>Commission</td>
                   <td style="text-transform: capitalize;white-space: pre-wrap !important;">${commission}${/[a-zA-Z]/g.test(commission) ? "" : "%"}</td> 
                 </tr> 
                 ${selectPolicyDescription == "Comprehensive" ?
                        `<tr>
                    <td>Remarks</td>
                  <td style="text-transform: capitalize;white-space: pre-wrap !important;">Commission to be calculated on the OD Part</td> 
                </tr> `: ""}
                </tbody> 
              </table>`,
                imageUrl: `${Logo}`,
                imageWidth: 400,
                imageHeight: 200,
                imageAlt: 'Custom image',
                confirmButtonText: 'OK',
                confirmButtonColor: 'green',
            })
        )
    }
    return (
        <div className='container-fluid mt-3  rounded position-top-right-center mt-5'>
            <div className='container '>
                <div className='row'>
                    {isLoading && <Spinner />}
                    <div className="mb-3 mx-auto text-center col-12 col-lg-8 col-md-8 col-sm-12 ">
                        <div className="mb-4 ">
                            <h1 className='form-heading text-center text-uppercase'> bajaj four wheeler insurance</h1>
                        </div>
                        {
                            userData.user_type !== "user" ? <Datepicker onChangedate={onChangedate} data={startDate} /> : ""
                        }
                        <SelectOption
                            title='Policy Type'
                            optionTitle='Select Policy Type'
                            data={policyType}
                            onChange={handleChangePolicyType}
                            errors={selectPolicyDescriptionError}
                            value={selectPolicyDescription}
                        />
                        <SelectOption
                            title={selectPolicyDescription == "Stand_Alone_Third_Party" ? "State" : "Vehicle Description"}
                            optionTitle={selectPolicyDescription == "Stand_Alone_Third_Party" ? "Select State" : "Select Vehicle Description"}
                            data={variant}
                            onChange={handleChangePolicyTypeItem}
                            errors={stateDataError}
                            value={stateData}
                        />

                        <CustomButton
                            title={'search'}
                            handleClick={handleClick}
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default BajajMotor