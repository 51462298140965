import React, { useEffect, useState } from 'react';
import SelectOption from '../components/common/selectOption';
import CustomButton from '../components/common/customButton';
import Datepicker from '../components/common/datepicker';
import AuthDetail from '../components/common/auth';
import Spinner from '../components/spinner';
import Swal from 'sweetalert2';
import { useNavigate } from 'react-router-dom';
import Logo from '../assets/image/idealILogo.jpeg';
import Select from '../components/Select';
const ICICILombardTwoWheeler = () => {
    const navigate = useNavigate();
    const [startDate, setStartDate] = useState(new Date());
    const { base_url, user } = AuthDetail();
    const [isLoading, setIsLoading] = useState(false);
    const [userData, setUserData] = useState({});
    const [swal, setSwal] = useState(false);
    const [RTO_ZONE, setRTO_ZONE] = useState([]);
    const [RTO_STATE, setRTO_STATE] = useState([]);
    const [RTO_LOCATION, setRTO_LOCATION] = useState([]);
    const [towWheelerType, settowWheelerType] = useState([]);
    const [towWheelerTypeValue, settowWheelerTypeValue] = useState([]);
    const [twoWheeler_bikes_TYPE, setTwoWheeler_bikes_TYPE] = useState([])
    const [twoWheelerBikesValue, setTwoWheelerBikesTypeValue] = useState([])
    const [TwoWheeler_TYPE, setTwoWheeler_TYPE] = useState([]);
    const [newScooters, setNewScoters] = useState([])
    const [newScootersKey, setNewScotersKey] = useState([])

    const [dataRTO_ZONE, setDataRTO_ZONE] = useState("");
    const [dataRTO_STATE, setDataRTO_STATE] = useState("");
    const [dataRTO_LOCATION, setDataRTO_LOCATION] = useState("");
    const [dataPRIVATE_CAR_TYPE, setDataPRIVATE_CAR_TYPE] = useState("");
    const [dataTwoWheeler_TYPE, setDataTwoWheeler_TYPE] = useState("");
    const [newScooterData, setNewScotersData] = useState("");
    const [scooterType, setScooterType] = useState(false);

    const [errorRtoZone, setErrorRtoZome] = useState("");
    const [errorRTO_STATE, setErrorRTO_STATE] = useState("");
    const [errorRTO_LOCATION, setErrorRTO_LOCATION] = useState("");
    const [errorPRIVATE_CAR_TYPE, seterrorDataPRIVATE_CAR_TYPE] = useState("");
    const [errorTwoWheeler_TYPE, setErrorTwoWheeler_TYPE] = useState("");
    const [newScooterError, setNewScotersError] = useState(false)
    const [commission, setCommission] = useState("");

    const [dataTwoWheeler_bikes_TYPE, setDataTwoWheeler_bikes_TYPE] = useState()
    const [dataWidthCpa, setDataWithCpa] = useState({
        withcpa: ""
    });
    const [errorWithcpa, setErrorWithcpa] = useState("");
    useEffect(() => {
        setIsLoading(true);
        if (user != null) {
            setUserData(JSON.parse(user));
        } else {
            navigate('/login')
        }

        SelectRTOState(startDate.getMonth() + 1, startDate.getFullYear());

        setIsLoading(true);
    }, [])
    const onChangedate = (e) => {
        setStartDate(e)
        setRTO_ZONE([]);
        setRTO_STATE([]);
        setRTO_LOCATION([]);
        settowWheelerType([]);
        settowWheelerTypeValue([]);
        setTwoWheeler_bikes_TYPE([]);
        setTwoWheelerBikesTypeValue([]);
        setDataRTO_ZONE("");
        setDataRTO_STATE("");
        setDataRTO_LOCATION("");
        setDataPRIVATE_CAR_TYPE("");
        setDataTwoWheeler_TYPE("");
        setNewScotersData("");
        SelectRTOState(e.getMonth() + 1, e.getFullYear());

    }
    const SelectRTOState = async (startDate, year) => {
        try {
            const res = await base_url.post(`/twoWheeler/lombard/selectRtoZone`, {
                "date": startDate,
                "year": year,
            });;
            if (res.data.status === 200) {
                setRTO_ZONE(res.data.data);
                setIsLoading(false);
            } else {

                setIsLoading(false);
            }
        } catch (error) {
            setIsLoading(false);
            console.log(error);
        }
    }
    const handleChangeRTOZONE = async (e) => {
        const value = e.target.value;
        setDataRTO_ZONE(value);
        setIsLoading(true);
        setErrorRtoZome(false);
        setRTO_STATE([]);
        setRTO_LOCATION([]);
        settowWheelerType([]);
        settowWheelerTypeValue([]);
        setTwoWheeler_bikes_TYPE([]);
        setTwoWheelerBikesTypeValue([]);
        setDataRTO_STATE("");
        setDataRTO_LOCATION("");
        setDataPRIVATE_CAR_TYPE("");
        setDataTwoWheeler_TYPE("");
        setNewScotersData("");
        try {
            const res = await base_url.post(`/twoWheeler/lombard/selectRtoState`, {
                "date": startDate.getMonth() + 1,
                "year": startDate.getFullYear(),
                "rtoZone": value.split('_').join(' '),
            });
            if (res.data.status === 200) {
                setRTO_STATE(res.data.data);
                setIsLoading(false);
            } else {
                setIsLoading(false);
            }
        } catch (error) {
            setIsLoading(false);
            console.log(error);
        }
    }
    const handleChangeRtoState = async (e) => {
        const value = e.target.value;
        setDataRTO_STATE(value);
        setErrorRTO_STATE(false);
        setRTO_LOCATION([]);
        settowWheelerType([]);
        settowWheelerTypeValue([]);
        setTwoWheeler_bikes_TYPE([]);
        setTwoWheelerBikesTypeValue([]);
        setDataRTO_LOCATION("");
        setDataPRIVATE_CAR_TYPE("");
        setDataTwoWheeler_TYPE("");
        setNewScotersData("");
        setIsLoading(true);
        try {
            const res = await base_url.post(`/twoWheeler/lombard/selectRtoLocation`, {
                "date": startDate.getMonth() + 1,
                "year": startDate.getFullYear(),
                "rtoZone": dataRTO_ZONE,
                "rtoState": value.split('_').join(' '),
            });
            if (res.data.status === 200) {
                setRTO_LOCATION(res.data.data);
                setIsLoading(false);
            } else {
                setIsLoading(false);
            }
        } catch (error) {
            setIsLoading(false);
            console.log(error);
        }
    }
    const handleChangertoLocation = async (e) => {
        const value = e.target.value;
        setDataRTO_LOCATION(value);
        setErrorRTO_LOCATION(false);
        settowWheelerType([]);
        settowWheelerTypeValue([]);
        setTwoWheeler_bikes_TYPE([]);
        setTwoWheelerBikesTypeValue([]);
        setDataPRIVATE_CAR_TYPE("");
        setDataTwoWheeler_TYPE("");
        setNewScotersData("");
        setIsLoading(true);
        try {
            const res = await base_url.post(`/twoWheeler/lombard/selectTwoWheelerType`, {
                "date": startDate.getMonth() + 1,
                "year": startDate.getFullYear(),
                "rtoZone": dataRTO_ZONE,
                "rtoState": dataRTO_STATE,
                "rtoLocation": value.split('_').join(' '),
            });
            if (res.data.status === 200) {
                settowWheelerType(res.data.data)
                settowWheelerTypeValue(res.data.dataValue)
                setIsLoading(false);
            } else {
                setIsLoading(false);
            }
        } catch (error) {
            setIsLoading(false);
            console.log(error);
        }
    }
    const handleChangertoPrivateCare = async (e) => {
        const value = e.target.value;
        setDataPRIVATE_CAR_TYPE(value);
        seterrorDataPRIVATE_CAR_TYPE(false);
        // setTwoWheeler_bikes_TYPE([]);
        // setTwoWheelerBikesTypeValue([]);
        setDataTwoWheeler_TYPE("");
        setNewScotersData("");
        setIsLoading(true);
        setNewScoters([]);
        setNewScotersKey([]);
        try {
            const res = await base_url.post(`/twoWheeler/lombard/selectTwoWheeler_bikes_TYPE`, {
                "date": startDate.getMonth() + 1,
                "year": startDate.getFullYear(),
                "rtoZone": dataRTO_ZONE,
                "rtoState": dataRTO_STATE,
                "rtoLocation": dataRTO_LOCATION,
                "rtoPrivateCareType": value.toUpperCase(),
            });
            if (res.data.status === 200) {
                setTwoWheeler_bikes_TYPE(res.data.data)
                setTwoWheelerBikesTypeValue(res.data.dataValue);
                setIsLoading(false);
            } else {
                setIsLoading(false);
            }
        } catch (error) {
            setIsLoading(false);
            console.log(error);
        }
    }
    const handleChangertoFuelType = async (e) => {
        const value = e.target.value;
        setDataTwoWheeler_TYPE(value);
        setErrorTwoWheeler_TYPE(false);
        setNewScotersData("");
        setIsLoading(true);

        try {
            const res = await base_url.post(`/twoWheeler/lombard/selectCommissionPercentage`, {
                "rtoZone": dataRTO_ZONE,
                "rtoState": dataRTO_STATE,
                "rtoLocation": dataRTO_LOCATION,
                "privateCareFuel": value,
                "date": startDate.getMonth() + 1,
                "year": startDate.getFullYear(),
            });
            if (res.data.status === 200) {
                if (value == "NEW_SCOOTERS" || value == "NEW_BIKES" || value == "OLD_ROYAL_ENFIELD" || value == "ELECTRIC_VEHICLE-TW_OLD") {
                    setScooterType(true)
                    setNewScoters(Object.values(JSON.parse(res.data.data)));
                    setNewScotersKey(Object.keys(JSON.parse(res.data.data)))
                } else {
                    setScooterType(false)
                    setCommission(res.data.data)
                }
                setIsLoading(false);
            } else {
                setIsLoading(false);
            }
        } catch (error) {
            setIsLoading(false);
            console.log(error);
        }
    }
    const handleChangeNewScooter = async (e) => {
        const value = e.target.value;
        setNewScotersData(value);
        setNewScotersError(false);
        try {
            const res = await base_url.post(`/twoWheeler/lombard/selectNewScootersCommission`, {
                "rtoZone": dataRTO_ZONE,
                "rtoState": dataRTO_STATE,
                "rtoLocation": dataRTO_LOCATION,
                "privateCareFuel": dataTwoWheeler_TYPE,
                "newScooterType": value.toUpperCase(),
                "date": startDate.getMonth() + 1,
                "year": startDate.getFullYear(),
            });
            if (res.data.status === 200) {
                setCommission(res.data.data)
                setIsLoading(false);
            } else {
                setIsLoading(false);
            }
        } catch (error) {
            setIsLoading(false);
            console.log(error);
        }
    }
    const widthCpa = (e) => {
        const target = e.target;
        const name = target.name;
        const value = target.value;
        setErrorWithcpa(false);
        var stringData = commission.replace(/[^a-z]/gi, '');
        var numbertData = Number(commission.replace(/[^0-9\.]+/g, ""));
        if (commission != "Decline") {
            if (value === "Without-CPA") {
                var num = numbertData - 2.5;
                if (stringData === "") {
                    setDataWithCpa({ ...dataWidthCpa, withcpa: stringData + " " + num + "%" });
                } else {
                    setDataWithCpa({ ...dataWidthCpa, withcpa: stringData + " - " + num + "%" });
                }
            } else {
                setDataWithCpa({ ...dataWidthCpa, withcpa: `${commission}%` });
            }
        } else {
            setDataWithCpa({ ...dataWidthCpa, withcpa: commission });
        }
        setSwal(false);
    }
    const handleClick = () => {
        if (dataRTO_ZONE == "") {
            setErrorRtoZome(true);
        }
        if (dataRTO_STATE == "") {
            setErrorRTO_STATE(true);
        }
        if (dataRTO_LOCATION == "") {
            setErrorRTO_LOCATION(true);
        }
        if (dataPRIVATE_CAR_TYPE == "") {
            seterrorDataPRIVATE_CAR_TYPE(true);
        }
        if (dataTwoWheeler_TYPE == "") {
            setErrorTwoWheeler_TYPE(true);
        }
        if (dataWidthCpa.withcpa == "") {
            setErrorWithcpa(true);
        }
        if (newScooterData == "") {
            setNewScotersError(true);
        }
        if ([
            'NEW_SCOOTERS',
            'NEW_BIKES'
        ].includes(dataTwoWheeler_TYPE)) {
            if (dataRTO_ZONE != ""
                && dataRTO_STATE != ""
                && dataRTO_LOCATION != ""
                && dataPRIVATE_CAR_TYPE != ""
                && dataTwoWheeler_TYPE != ""
                && newScooterData != ""
            ) {
                if (dataWidthCpa.withcpa != "") {
                    Swalll();
                } else if (dataTwoWheeler_TYPE == "NEW_BIKES") {
                    Swalll();
                }
            }
        } else {
            if (dataRTO_ZONE != ""
                && dataRTO_STATE != ""
                && dataRTO_LOCATION != ""
                && dataPRIVATE_CAR_TYPE != ""
                && dataTwoWheeler_TYPE != "") {
                Swalll();
            }
        }
    }
    const Swalll = () => {
        return (
            Swal.fire({
                title: `ICICI LOMBARD TWO WHEELER`,
                text: 'Modal with a custom image.',
                html: `<table class="table text-start">
                <tbody>
                  <tr>
                    <td>RTO ZONE</td>
                    <td>${dataRTO_ZONE}</td>
                  </tr> 
                  <tr> 
                   <td>RTO STATE</td> 
                   <td style="text-transform: capitalize ; white-space: pre-wrap !important;">${dataRTO_STATE}</td>
                  </tr> 
                  <tr> 
                  <td>RTO Location </td>
                  <td style="text-transform: capitalize ; white-space: pre-wrap !important;">${dataRTO_LOCATION}</td> 
                </tr> 
                <td>Insurance  Type </td>
                    <td style="text-transform: capitalize ; white-space: pre-wrap !important;">${dataPRIVATE_CAR_TYPE.split('_').join(' ')}</td> 
                  </tr> 
                  <tr> 
                   <td>Insurance Sub Type </td>
                    <td style="text-transform: capitalize ; white-space: pre-wrap !important;">${dataTwoWheeler_TYPE.split('_').join(' ')}</td> 
                  </tr> 
                  <tr> 
            <td>Commission</td>
             <td style="text-transform: capitalize ; white-space: pre-wrap !important;">${[
                        'NEW_SCOOTERS',
                    ].includes(dataTwoWheeler_TYPE) ? dataWidthCpa.withcpa : commission.match(/[0-9]/g) ? `${commission}%` : commission}</td> 
             
           </tr> 
                </tbody> 
              </table>`,
                imageUrl: `${Logo}`,
                imageWidth: 400,
                imageHeight: 200,
                imageAlt: 'Custom image',
                confirmButtonText: 'OK',
                confirmButtonColor: 'green',
            })
        )
    }
    return (
        <div className='container-fluid mt-3  rounded position-top-right-center mt-5'>
            <div className='container '>
                <div className='row'>
                    {isLoading && <Spinner />}
                    <div className="mb-3 mx-auto text-center col-12 col-lg-8 col-md-8 col-sm-12 ">
                        <div className="mb-4 ">
                            <h1 className='form-heading text-center text-uppercase'>ICICI lombard Two Wheeler Insurance</h1>
                        </div>
                        {
                            userData.user_type !== "user" ? <Datepicker onChangedate={onChangedate} data={startDate} /> : ""
                        }
                        <Select
                            title='RTO Zone'
                            optionTitle='Select RTO Zone'
                            data={RTO_ZONE}
                            dataKey={RTO_ZONE}
                            onChange={handleChangeRTOZONE}
                            errors={errorRtoZone}
                            value={dataRTO_ZONE} />
                        <Select
                            title='RTO State'
                            optionTitle='Select RTO State'
                            data={RTO_STATE}
                            dataKey={RTO_STATE}
                            onChange={handleChangeRtoState}
                            errors={errorRTO_STATE}
                            value={dataRTO_STATE} />
                        <Select
                            title='RTO Location'
                            optionTitle='Select RTO Location'
                            data={RTO_LOCATION}
                            dataKey={RTO_LOCATION}
                            onChange={handleChangertoLocation}
                            errors={errorRTO_LOCATION}
                            value={dataRTO_LOCATION}
                        />
                        <Select
                            title='Insurance Type'
                            optionTitle='Select Insurance Type'
                            data={towWheelerType}
                            dataKey={towWheelerTypeValue}
                            onChange={handleChangertoPrivateCare}
                            errors={errorPRIVATE_CAR_TYPE}
                            value={dataPRIVATE_CAR_TYPE}
                        />
                        <Select
                            title='Insurance Sub Type'
                            optionTitle='Insurance Sub Type'
                            data={twoWheeler_bikes_TYPE}
                            dataKey={twoWheelerBikesValue}
                            onChange={handleChangertoFuelType}
                            errors={errorTwoWheeler_TYPE}
                            value={dataTwoWheeler_TYPE}
                        />
                        {scooterType && <Select
                            title={dataTwoWheeler_TYPE == "NEW_SCOOTERS" ? "Scooter Type" : "Bikes Type"}
                            optionTitle={dataTwoWheeler_TYPE == "NEW_SCOOTERS" ? "Insurance Scooter Type" : "Insurance Bikes Type"}
                            data={newScooters}
                            dataKey={newScootersKey}
                            onChange={handleChangeNewScooter}
                            errors={newScooterError}
                            value={newScooterData}
                        />}
                        {
                            [
                                'NEW_SCOOTERS',
                            ].includes(dataTwoWheeler_TYPE) ?
                                <div className="mb-4 ">
                                    <label className="form-label float-start ">With CPA/Without CPA <span className='text-danger'>*</span></label>
                                    <select className={`form-select form-select-md border border-2 ${errorWithcpa && "border border-2 border-danger "}`} aria-label=".form-select-md example" name='withcpa' onChange={widthCpa}>
                                        <option value=''>Select With CPA/Without CPA</option>
                                        <option value='With-CPA'>With CPA</option>
                                        <option value='Without-CPA'>Without CPA</option>
                                    </select>
                                </div>
                                : ""
                        }
                        <CustomButton
                            title={'search'}
                            handleClick={handleClick}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ICICILombardTwoWheeler;
