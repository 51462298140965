import React, { useState, useEffect } from 'react';
import Datepicker from '../../components/common/datepicker';
import { useNavigate } from 'react-router-dom';
import AuthDetail from '../../components/common/auth';
import CustomButton from '../../components/common/customButton';
import SelectOption from '../../components/common/selectOption';
import Spinner from '../../components/spinner';
import Swal from 'sweetalert2';
import Logo from '../../assets/image/idealILogo.jpeg';
const InsuranceType = [
    "Stand Alone Third Party",
    "Comprehensive/Stand Alone Own Damage"
];
const IIFCO_TOKIOFourWheeler = () => {
    const navigate = useNavigate();
    const { base_url, user } = AuthDetail();
    const [userData, setUserData] = useState({});
    const [isLoading, setIsLoading] = useState(false);
    const [startDate, setStartDate] = useState(new Date());
    const [zone, setZone] = useState([]);
    const [state, setState] = useState([]);
    const [insuranceType, setInsuranceTypes] = useState([]);
    const [claimBonusType, setClaimBonusTypes] = useState([]);
    const [commission, setCommission] = useState("")

    const [zoneData, setZoneData] = useState("");
    const [stateData, setStateData] = useState("");
    const [insuranceTypeData, setInsuranceTypesData] = useState("");
    const [claimBonusTypeData, setClaimBonusTypesData] = useState("");


    const [zoneDataError, setZoneDataError] = useState(false);
    const [stateDataError, setStateDataError] = useState(false);
    const [insuranceTypeError, setInsuranceTypesDataError] = useState(false);
    const [claimBonusTypeError, setClaimBonusTypesDataError] = useState(false);
    useEffect(() => {
        if (user != null) {
            setUserData(JSON.parse(user));
        } else {
            navigate('/login')
        }
        SelectZone(startDate.getMonth() + 1, startDate.getFullYear());
    }, []);
    const onChangedate = (e) => {
        setStartDate(e);
        SelectZone(e.getMonth() + 1, e.getFullYear());
    }
    const SelectZone = async (startDate, year) => {
        setIsLoading(true);
        try {
            const res = await base_url.post(`/motor/iifco_tokio/SelectZone`, {
                "date": startDate,
                "year": year,
            });
            if (res.data.status === 200) {
                setZone(res.data.data)
                setIsLoading(false);
            } else {
                setIsLoading(false);
            }
        } catch (error) {
            setIsLoading(false);
            console.log(error);
        }
    }
    const handleChangeZone = async (e) => {
        const value = e.target.value;
        setZoneData(value);
        setZoneDataError(false);
        setIsLoading(true);
        setStateData("");
        setInsuranceTypesData("");
        setClaimBonusTypesData("");
        setState([]);
        setInsuranceTypes([]);
        setClaimBonusTypes([]);
        try {
            const res = await base_url.post(`/motor/iifco_tokio/SelectState`, {
                "date": startDate.getMonth() + 1,
                "year": startDate.getFullYear(),
                "zone": value,
            });
            if (res.data.status === 200) {
                setState(res.data.data)
                setIsLoading(false);
            } else {
                setIsLoading(false);
            }
        } catch (error) {
            setIsLoading(false);
            console.log(error);
        }
    }
    const handleChangeState = async (e) => {
        const value = e.target.value;
        setStateData(value);
        setIsLoading(true);
        setStateDataError(false);
        setInsuranceTypesData("");
        setClaimBonusTypesData("");
        setInsuranceTypes([]);
        setClaimBonusTypes([]);
        try {
            const res = await base_url.post(`/motor/iifco_tokio/SelectInsuranceType`, {
                "date": startDate.getMonth() + 1,
                "year": startDate.getFullYear(),
                "zone": zoneData,
                "state": value.split('_').join(' ')
            });
            if (res.data.status === 200) {
                setInsuranceTypes(res.data.data)
                setIsLoading(false);
            } else {
                setIsLoading(false);
            }
        } catch (error) {
            setIsLoading(false);
            console.log(error);
        }

    }
    const handleChangeInsuranceType = async (e) => {
        const value = e.target.value;
        setInsuranceTypesData(value);
        setIsLoading(true);
        setInsuranceTypesDataError(false);
        setClaimBonusTypesData("");
        setClaimBonusTypes([]);
        try {
            const res = await base_url.post(`/motor/iifco_tokio/SelectClaimBonus`, {
                "date": startDate.getMonth() + 1,
                "year": startDate.getFullYear(),
                "zone": zoneData,
                "state": stateData.split('_').join(' '),
                "insuranceType": value.toUpperCase()
            });
            if (res.data.status === 200) {
                if (value === "Standalone_Third_Party") {
                    setCommission(res.data.data);
                } else {
                    setClaimBonusTypes(res.data.data)
                }
                setIsLoading(false);
            } else {
                setIsLoading(false);
            }
        } catch (error) {
            setIsLoading(false);
            console.log(error);
        }
    }
    const handleChangeClaimBonusType = async (e) => {
        const value = e.target.value;
        setClaimBonusTypesData(value);
        setClaimBonusTypesDataError(false);
        try {
            const res = await base_url.post(`/motor/iifco_tokio/SelectCommission`, {
                "date": startDate.getMonth() + 1,
                "year": startDate.getFullYear(),
                "zone": zoneData,
                "state": stateData.split('_').join(' '),
                "insuranceType": insuranceTypeData == "Standalone_Third_Party" ? insuranceTypeData.toUpperCase() : value == "NCB" ? `${insuranceTypeData.toUpperCase()}_WITH_NCB` : `${insuranceTypeData.toUpperCase()}_WITH_OUT_NCB`,
            });
            if (res.data.status === 200) {
                setCommission(res.data.data);
                setIsLoading(false);
            } else {
                setIsLoading(false);
            }
        } catch (error) {
            setIsLoading(false);
            console.log(error);
        }
    }
    const handleClick = () => {
        if (zoneData == "") {
            setZoneDataError(true);
        }
        if (stateData == "") {
            setStateDataError(true);
        }
        if (insuranceTypeData == "") {
            setInsuranceTypesDataError(true)
        }
        if (insuranceTypeData == "Standalone_Third_Party") {
            if (zoneData != "" && stateData != "" && insuranceTypeData != "") {
                Swalll();
            }
        } else {
            if (claimBonusTypeData == "") {
                setClaimBonusTypesDataError(true);
            }
            if (zoneData != "" && stateData != "" && insuranceTypeData != "" && claimBonusTypeData != "") {
                Swalll();
            }
        }
    }

    const Swalll = () => {
        return (
            Swal.fire({
                title: `IFFCO TOKIO`,
                text: 'Modal with a custom image.',
                html: `<table class="table text-start">
                <tbody> 
                  <tr> 
                   <td>Zone </td> 
                   <td style="text-transform: capitalize;white-space: pre-wrap !important;">${zoneData.split('_').join(' ')}</td>
                  </tr> 
                  <tr> 
                   <td>State </td> 
                   <td style="text-transform: capitalize;white-space: pre-wrap !important;">${stateData.split('_').join(' ')}</td>
                  </tr> 
                  <tr> 
                  <td>Insurance Type </td>
                  <td style="text-transform: capitalize;white-space: pre-wrap !important;">${insuranceTypeData.split('_').join(' ')}</td> 
                </tr>  
                ${insuranceTypeData != "Standalone_Third_Party" ?
                        `<tr>
                            <td>Claim Bonus Type </td>
                            <td style="text-transform: capitalize;white-space: pre-wrap !important;">${claimBonusTypeData.split('_').join(' ')}</td>
                        </tr>` : ""}           
                  <td>Commission</td>
                   <td style="text-transform: capitalize;white-space: pre-wrap !important;">${commission}${/[a-zA-Z]/g.test(commission) ? "" : "%"}</td> 
                 </tr> 
                 ${insuranceTypeData == "Comprehensive" ?
                        `<tr>
                      <td>Remarks</td>
                    <td style="text-transform: capitalize;white-space: pre-wrap !important;">Commission to be calculated on the OD Part</td> 
                  </tr> `: ""}
                </tbody> 
              </table>`,
                imageUrl: `${Logo}`,
                imageWidth: 400,
                imageHeight: 200,
                imageAlt: 'Custom image',
                confirmButtonText: 'OK',
                confirmButtonColor: 'green',
            })
        )
    }
    return (
        <div className='container-fluid mt-3  rounded position-top-right-center mt-5'>
            <div className='container '>
                <div className='row'>
                    {isLoading && <Spinner />}
                    <div className="mb-3 mx-auto text-center col-12 col-lg-8 col-md-8 col-sm-12 ">
                        <div className="mb-4 ">
                            <h1 className='form-heading text-center text-uppercase'> IFFCO TOKIO  FOUR WHEELER Insurance</h1>
                        </div>
                        {
                            userData.user_type !== "user" ? <Datepicker onChangedate={onChangedate} data={startDate} /> : ""
                        }
                        <SelectOption
                            title='Zone'
                            optionTitle='Select Zone'
                            data={zone}
                            onChange={handleChangeZone}
                            errors={zoneDataError}
                            value={zoneData}
                        />
                        <SelectOption
                            title='State'
                            optionTitle='Select State'
                            data={state}
                            onChange={handleChangeState}
                            errors={stateDataError}
                            value={stateData}
                        />
                        <SelectOption
                            title='Insurance Type'
                            optionTitle='Select Insurance Type'
                            data={insuranceType}
                            onChange={handleChangeInsuranceType}
                            errors={insuranceTypeError}
                            value={insuranceTypeData}
                        />
                        {insuranceTypeData != "Standalone_Third_Party" ?
                            <SelectOption
                                title='Claim Bonus Type'
                                optionTitle='Select Claim Bonus Type'
                                data={claimBonusType}
                                onChange={handleChangeClaimBonusType}
                                errors={claimBonusTypeError}
                                value={claimBonusTypeData}
                            /> : null}
                        <CustomButton
                            title={'search'}
                            handleClick={handleClick}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default IIFCO_TOKIOFourWheeler;
