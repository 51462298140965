import React,{memo} from 'react';

const select = ({ title, optionTitle, data,dataKey, onChange, errors, value,company }) => {
  return (
    <div className="mb-4 ">
      <label className="form-label float-start ">{title} <span className='text-danger'>*</span></label>
      <select className={`form-select form-select-md border border-2 ${errors && "border border-2 border-danger "}`} aria-label=".form-select-md example" name="companies" onChange={onChange} value={value} >
        <option>{optionTitle}</option>
        {
          data && data.map((data, i) => {
            return (
              <option key={i} value={dataKey[i]} id={data} name={dataKey[i]} className='text-capitalize'>
                {company=='godigit'?dataKey[i]+'-':null}  {data}
              </option>
            )
          })
        }
      </select>
    </div>
  );
}

export default memo(select);
