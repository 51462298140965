import React, { useState, useEffect } from 'react';
import Datepicker from '../../components/common/datepicker';
import AuthDetail from '../../components/common/auth';
import Spinner from '../../components/spinner';
import { useNavigate } from 'react-router-dom';
import SelectOption from '../../components/common/selectOption';
import CustomButton from '../../components/common/customButton';
import Swal from 'sweetalert2';
import Logo from '../../assets/image/idealILogo.jpeg'
const Reliance = () => {


    const navigate = useNavigate();
    const { base_url, user } = AuthDetail();
    const [userData, setUserData] = useState({});
    const [isLoading, setIsLoading] = useState(false);
    const [startDate, setStartDate] = useState(new Date());
    const [Zone, setZone] = useState([]);
    const [RTO, SetRTO] = useState([]);
    const [bikeType, setBikeType] = useState([]);
    const [plicyType, setPolicyType] = useState([]);
    const [ZoneData, SetZoneData] = useState("");
    const [RTOData, SetRTOData] = useState("");
    const [bikeTypeData, setBikeTypeData] = useState("");
    const [policyType, setpolicyType] = useState("");
    const [commission, setCommission] = useState("");
    const [ZoneDataError, SetZoneDataError] = useState(false);
    const [RTODataError, SetRTODataError] = useState(false);
    const [bikeTypeDataError, setBikeTypeDataError] = useState(false);
    const [commissionError, setCommissionError] = useState(false);
    useEffect(() => {
        if (user != null) {
            setUserData(JSON.parse(user));
        } else {
            navigate('/login')
        }
        SelectZone(startDate.getMonth() + 1, startDate.getFullYear())
    }, [])
    const onChangedate = (e) => {
        setStartDate(e);
        SelectZone(e.getMonth() + 1, e.getFullYear());
    }
    const SelectZone = async (startDate, year) => {
        setIsLoading(true);
        try {
            const res = await base_url.post(`/twoWheeler/reliance/SelectZone`, {
                "date": startDate,
                "year": year,
            });
            if (res.data.status === 200) {
                setZone(res.data.data);
                setIsLoading(false);
            } else {
                setIsLoading(false);
            }
        } catch (error) {
            setIsLoading(false);
            console.log(error);
        }
    }
    const handleChangeZone = async (e) => {
        const value = e.target.value;
        SetZoneData(value);
        SetZoneDataError(false);
        setIsLoading(true);
        SetRTO([]);
        setBikeType([]);
        setPolicyType([]);
        SetRTOData("");
        setBikeTypeData("");
        setCommission("");
        setpolicyType("");
        try {
            const res = await base_url.post(`/twoWheeler/reliance/SelectRto`, {
                "date": startDate.getMonth() + 1,
                "year": startDate.getFullYear(),
                "ZONE": value,
            });
            if (res.data.status === 200) {
                SetRTO(res.data.data);
                setIsLoading(false);
            } else {
                setIsLoading(false);
            }
        } catch (error) {
            setIsLoading(false);
            console.log(error);
        }
    }
    const handleChangeRTO = async (e) => {
        const value = e.target.value;
        SetRTOData(value);
        SetRTODataError(false);
        setIsLoading(true);
        setBikeType([]);
        setPolicyType([]);
        setCommission("");
        setpolicyType("");
        try {
            const res = await base_url.post(`/twoWheeler/reliance/SelectBikeTypes`, {
                "date": startDate.getMonth() + 1,
                "year": startDate.getFullYear(),
                "ZONE": ZoneData,
            });
            if (res.data.status === 200) {
                setBikeType(res.data.data);
                setIsLoading(false);
            } else {
                setIsLoading(false);
            }
        } catch (error) {
            setIsLoading(false);
            console.log(error);
        }

    }
    const handleChangeBikeType = async (e) => {
        const value = e.target.value;
        setBikeTypeData(value);
        setBikeTypeDataError(false);
        setIsLoading(true);
        setPolicyType([]);
        setpolicyType("");
        try {
            const res = await base_url.post(`/twoWheeler/reliance/SelectpolicyTypes`, {
                "bikeType": value,
                "ZONE": ZoneData,
                "date": startDate.getMonth() + 1,
                "year": startDate.getFullYear(),
            });
            if (res.data.status === 200) {
                setPolicyType(res.data.data);
                setIsLoading(false);
            } else {
                setIsLoading(false);
            }
        } catch (error) {
            setIsLoading(false);
            console.log(error);
        }
    }
    const handleChangeCommission = async (e) => {
        const value = e.target.value;
        setpolicyType(value);
        setCommissionError(false);
        setIsLoading(true);
        try {
            const res = await base_url.post(`/twoWheeler/reliance/SelectPolicyCommission`, {
                "policyType": bikeTypeData === "Scooters" ? `SCOOTERS-${value.toUpperCase()}` : value.toUpperCase(),
                "RTO_REGION": RTOData,
                "ZONE": ZoneData,
                "date": startDate.getMonth() + 1,
                "year": startDate.getFullYear(),
            });
            if (res.data.status === 200) {
                setCommission(res.data.data);
                setIsLoading(false);
            } else {
                setIsLoading(false);
            }
        } catch (error) {
            setIsLoading(false);
            console.log(error);
        }
    }
    const handleClick = () => {
        if (ZoneData === "") {
            SetZoneDataError(true);
        }
        if (RTOData === "") {
            SetRTODataError(true);
        }
        if (bikeTypeData === "") {
            setBikeTypeDataError(true);
        }
        if (policyType === "") {
            setCommissionError(true);
        }
        if (ZoneData !== "" &&
            RTOData !== "" &&
            bikeTypeData !== "" &&
            policyType !== "") {
            Swalll();
        }
    }
    const Swalll = () => {
        return (
            Swal.fire({
                title: `RELIANCE TWO WHEELER`,
                text: 'Modal with a custom image.',
                html: `<table class="table text-start">
          <tbody>
          <tr>
          <td>Zone</td>
          <td style="text-transform: capitalize;white-space: pre-wrap !important;">${ZoneData.split('_').join(' ')}</td>
        </tr>
        <tr>
              <td>RTO REGION</td>
              <td style="text-transform: capitalize;white-space: pre-wrap !important;">${RTOData.split('_').join(' ')}</td>
            </tr>
            <tr>
              <td>Two Wheeler Type</td>
              <td style="text-transform: capitalize;white-space: pre-wrap !important;">${bikeTypeData.split('_').join(' ')}</td>
            </tr>
            <tr>
            <td>Policy Type</td>
            <td style="text-transform: capitalize;white-space: pre-wrap !important;">${policyType.split('_').join(' ')}</td>
          </tr>
            
            <tr> <td>Commission </td> <td style="text-transform: capitalize;white-space: pre-wrap !important;">${commission}${/[a-zA-Z]/g.test(commission) ? "" : "%"}</td> </tr>
          </tbody> </table>`,
                imageUrl: `${Logo}`,
                imageWidth: 400,
                imageHeight: 200,
                imageAlt: 'Custom image',
                confirmButtonText: 'OK',
                confirmButtonColor: 'green',
            })
        )
    }
    return (
        <div className='container-fluid mt-3  rounded position-top-right-center mt-5'>
            <div className='container '>
                <div className='row'>
                    {isLoading && <Spinner />}
                    <div className="mb-3 mx-auto text-center col-12 col-lg-8 col-md-8 col-sm-12 ">
                        <div className="mb-4 ">
                            <h1 className='form-heading text-center text-uppercase'> RELIANCE two wheeler insurance</h1>
                        </div>
                        {
                            userData.user_type !== "user" ? <Datepicker onChangedate={onChangedate} data={startDate} /> : ""
                        }
                        <SelectOption
                            title='Zone'
                            optionTitle='Select Zone'
                            data={Zone}
                            onChange={handleChangeZone}
                            errors={ZoneDataError}
                            value={ZoneData}

                        />
                        <SelectOption
                            title='RTO REGION'
                            optionTitle='Select RTO REGION'
                            data={RTO}
                            onChange={handleChangeRTO}
                            errors={RTODataError}
                            value={RTOData}
                        />
                        <SelectOption
                            title='Two Wheeler Type'
                            optionTitle='Select Two Wheeler Type'
                            data={bikeType}
                            onChange={handleChangeBikeType}
                            errors={bikeTypeDataError}
                            value={bikeTypeData}
                        />
                        <SelectOption
                            title='Policy Type'
                            optionTitle='Select Policy Type'
                            data={plicyType}
                            onChange={handleChangeCommission}
                            errors={commissionError}
                            value={policyType}
                        />

                        <CustomButton
                            title={'search'}
                            handleClick={handleClick}
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Reliance