import React, { useState, useEffect } from 'react';
import AuthDetail from '../../components/common/auth';
import Datepicker from '../../components/common/datepicker';
import Spinner from '../../components/spinner';
import CustomButton from '../../components/common/customButton';
import SelectOption from '../../components/common/selectOption';
import Logo from '../../assets/image/idealILogo.jpeg';
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
const date_arr = { 1: 'Jan', 2: 'Feb', 3: 'Mar', 4: 'Apr', 5: 'May', 6: 'Jun', 7: 'Jul', 8: 'Aug', 9: 'Sep', 10: 'Oct', 11: 'Nov', 12: 'Dec' };
const BAJAJHealth = () => {
    const { base_url, user } = AuthDetail();
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false);
    const [userData, setUserData] = useState({});
    const [startDate, setStartDate] = useState(new Date());
    const [productArr, setProductArr] = useState([]);
    const [productDate, setProductData] = useState("");
    const [productDateError, setProductDataError] = useState("");
    const [Rate, setRate] = useState("");
    useEffect(() => {
        setIsLoading(true);
        if (user != null) {
            setUserData(JSON.parse(user));
        } else {
            navigate('/login')
        }
        selectProduct(`${date_arr[startDate.getMonth() + 1]}-${startDate.getFullYear()}`);

    }, [])
    const onChangedate = (e) => {
        let date = new Date(e)
        setStartDate(date);
        selectProduct(`${date_arr[date.getMonth() + 1]}-${date.getFullYear()}`);
        // setProductArr([]);
    }
    const selectProduct = async (currentDate) => {
        setIsLoading(true);
        try {
            const res = await base_url.post(`/health`, {
                where: { DATE: currentDate, INSURANCE_COMPANY: "BAJAJ" },
                fields: ["TYPE"]
            });
            if (res.data.status === 200) {
                const data = res.data.data.map((data, index) => {
                    return { label: data.TYPE, value: data.TYPE }
                })
                setProductArr(data);
                setIsLoading(false);
            } else {

                setIsLoading(false);
            }
        } catch (error) {
            console.log(error);
        }
    }
    const currentDate = `${date_arr[startDate.getMonth() + 1]}-${startDate.getFullYear()}`;
    const onchangeProduct = async (e) => {
        const value = e.value;
        setProductData(value);
        setProductDataError(false);
        try {
            const res = await base_url.post(`/health`, {
                where: { DATE: currentDate, INSURANCE_COMPANY: "BAJAJ", TYPE: value },
                fields: ["RATE"]
            });
            if (res.data.status === 200) {
                setRate(res.data.data[0].RATE);
                setIsLoading(false);
            } else {

                setIsLoading(false);
            }
        } catch (error) {
            console.log(error);
        }
    }
    const handleClick = () => {
        if (productDate == "") {
            setProductDataError(true);
        }
        if (productDate != "") {
            Swal.fire({
                title: `BAJAJ HEALTH INSURANCE`,
                text: 'Modal with a custom image.',
                html: `<table class="table text-start">
                <tbody>
                  <tr>
                    <td>PolicyType</td>
                    <td>${productDate}</td>
                  </tr> 

                  <tr> 
                  <td>Commission</td>
                   <td style="text-transform: capitalize;white-space: pre-wrap !important;">${Rate}%</td> 
                 </tr> 
                </tbody> 
              </table>`,
                imageUrl: `${Logo}`,
                imageWidth: 50,
                imageHeight: 50,
                imageAlt: 'Custom image',
                confirmButtonText: 'OK',
                confirmButtonColor: 'green',
            })
        }

    }
    return (
        <div className='container-fluid mt-3  rounded position-top-right-center mt-5'>
            <div className='container '>
                <div className='row'>
                    <div className="mb-3 mx-auto text-center col-12 col-lg-8 col-md-8 col-sm-12 ">
                        <div className="mb-4 ">
                            <h1 className='form-heading text-center text-uppercase'>BAJAJ HEALTH  Insurance</h1>
                        </div>
                        {
                            userData.user_type !== "user" ? <Datepicker onChangedate={onChangedate} data={startDate} /> : ""
                        }
                        <SelectOption title={'Product'} data={productArr} onChange={onchangeProduct} value={productDate} errors={productDateError} />
                        <CustomButton title={'search'} handleClick={handleClick} />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default BAJAJHealth
