import React, { useEffect, useState } from 'react';
import { Route, Routes, useLocation, BrowserRouter } from "react-router-dom";
import Home from './screen/Home';
import Health from './screen/Health';
import "react-datepicker/dist/react-datepicker.css";
import './App.css';
import Login from './components/Login';
import Motor from './screen/Motor';
import ICICILombard from './screen/ICICILombard';
import TwoWheeler from './screen/TwoWheeler';
import ICICILombardTwoWheeler from './screen/ICICILombardTwoWheeler';
import Dashboard from './dashboard/Dashboard';
import DashboardHome from './dashboard/screens/Home';
import DashboarTataAig from './dashboard/screens/TataAig';
import Navbar from './components/Navbar/Navbar';
import UserLIst from './dashboard/screens/UserLIst';
import {
  CARE,
  ICICILombardHealth,
  ManiPalCigna,
  Godigit,
  GodigitFourWheeler,
  BajajTw,
  IIFCO_TOKIO,
  IIFCO_TOKIOFourWheeler,
  BajajMotor,
  Reliance,
  MagmaHealth,
  Liberty_four_wheeler,
  LibertyBike,
  ADITYABIRLA,
  TATAAIGGScreen,
  NivaBupa,
  HDFC,
  BAJAJHealth,
  SBIHealth,
  REALINCEHealth,
  ORIENTALHealth,
  NEW_INDIA_Health,
  MAGMAHDIHealth,
  LIBERTY_Health,
  KOTAK_Health,
  IFFCO_TOKIO_Health,
  FUTURE_Health,
  EDELWEISS_Health,

  Commercial,
  National,
  New_India,
  Oriental_India,
  United_India
} from './screen';
import { UserTypeContext } from './UserTypeContext';
import AuthDetail from './components/common/auth';
const App = () => {
  const { user } = AuthDetail();
  const [userType, setUserType] = useState({});
  useEffect(() => {
    if (user != null) {
      setUserType(JSON.parse(user));
    }
  }, [])
  return (
    <>
      <UserTypeContext.Provider value={{ userType, setUserType }}>
        <Navbar />
        <Routes>
          <Route path="/Login" exact element={<Login />} />
          <Route path="/" exact element={<Home />} />
          <Route path="/health" exact element={<Health />} />
          <Route path="/health/tata_aig" exact element={<TATAAIGGScreen />} />
          <Route path="/health/niva_bupa" exact element={<NivaBupa />} />
          <Route path="/health/magma" exact element={<MagmaHealth />} />
          <Route path="/health/hdfc_ergo" exact element={<HDFC />} />
          <Route path="/health/care" exact element={<CARE />} />
          <Route path="/health/icici_lombard" exact element={<ICICILombardHealth />} />
          <Route path="/health/manipal_cigna" exact element={<ManiPalCigna />} />
          <Route path='/health/ADITYA_BIRLA' exact element={<ADITYABIRLA />} />
          <Route path='/health/BAJAJ' exact element={<BAJAJHealth />} />
          <Route path='/health/SBI' exact element={<SBIHealth />} />
          <Route path='/health/RELIANCE' exact element={<REALINCEHealth />} />
          <Route path='/health/ORIENTAL' exact element={<ORIENTALHealth />} />
          <Route path='/health/NEW_INDIA' exact element={<NEW_INDIA_Health />} />
          <Route path='/health/MAGMA_HDI' exact element={<MAGMAHDIHealth />} />
          <Route path='/health/LIBERTY' exact element={<LIBERTY_Health />} />
          <Route path='/health/KOTAK' exact element={<KOTAK_Health />} />
          <Route path='/health/IFFCO_TOKIO' exact element={<IFFCO_TOKIO_Health />} />
          <Route path='/health/FUTURE' exact element={<FUTURE_Health />} />
          <Route path='/health/EDELWEISS' exact element={<EDELWEISS_Health />} />

          {/* Commercial */}
          <Route path='/Commercial' exact element={<Commercial />} />
          <Route path='/Commercial/National' exact element={<National />} />
          <Route path='/Commercial/New_India' exact element={<New_India />} />
          <Route path='/Commercial/Oriental_India' exact element={<Oriental_India />} />
          <Route path='/Commercial/United_India' exact element={<United_India />} />


          {/* Commercial */}

          <Route path='/motor' exact element={<Motor />} />
          <Route path='/motor/icici_lombard' exact element={<ICICILombard />} />
          <Route path='/motor/godigit' exact element={<GodigitFourWheeler />} />
          <Route path='/motor/IFFCO_TOKIO' exact element={<IIFCO_TOKIOFourWheeler />} />
          <Route path='/motor/bajaj' exact element={<BajajMotor />} />
          <Route path='/motor/LIBERTY' exact element={<Liberty_four_wheeler />} />

          <Route path='/two-wheeler' exact element={<TwoWheeler />} />
          <Route path='/two-wheeler/icici_lombard' exact element={<ICICILombardTwoWheeler />} />
          <Route path='/two-wheeler/godigit' exact element={<Godigit />} />
          <Route path='/two-wheeler/bajaj' exact element={<BajajTw />} />
          <Route path='/two-wheeler/IFFCO_TOKIO' exact element={<IIFCO_TOKIO />} />
          <Route path='/two-wheeler/reliance' exact element={<Reliance />} />
          <Route path='/two-wheeler/LIBERTY' exact element={<LibertyBike />} />
          {/* <Route path="121/dashboard" element={<Dashboard />}  > */}
          <Route path="matrix/dashboard" exact element={<UserLIst />}  >
            {/* <Route index element={<DashboardHome />} /> */}
            <Route path="TataAig" exact element={<DashboarTataAig />} />
            <Route path="UserList" exact element={<UserLIst />} />
          </Route>

          <Route path="*" exact element={<h1>!-------</h1>} />
        </Routes>
      </UserTypeContext.Provider>
    </>
  )
}

export default App


