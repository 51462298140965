import React, { useState, useEffect } from 'react';
import Datepicker from '../../components/common/datepicker';
import AuthDetail from '../../components/common/auth';
import Spinner from '../../components/spinner';
import { useNavigate } from 'react-router-dom';
import SelectOption from '../../components/common/selectOption';
import CustomButton from '../../components/common/customButton';
import Swal from 'sweetalert2';
import Logo from '../../assets/image/idealILogo.jpeg';
const BajajTw = () => {
    const navigate = useNavigate();
    const { base_url, user } = AuthDetail();
    const [userData, setUserData] = useState({});
    const [isLoading, setIsLoading] = useState(false);
    const [startDate, setStartDate] = useState(new Date());
    const [RTOStatename, setRTOStatename] = useState([]);
    const [InsuranceTypes, setInsuranceTypes] = useState([]);
    const [commission, setCommission] = useState("");


    const [RTOData, setRTODatat] = useState("");
    const [InsuranceData, setInsuranceData] = useState("");
    const [comprehensiveData, setComprehensiveData] = useState("");


    const [RTOError, setRTOerror] = useState(false);
    const [InsuranceError, setInsuranceError] = useState(false);
    useEffect(() => {
        if (user != null) {
            setUserData(JSON.parse(user));
        } else {
            navigate('/login')
        }
        SelectSegment(startDate.getMonth() + 1, startDate.getFullYear());
    }, []);
    const onChangedate = (e) => {
        setIsLoading(true);
        setRTOStatename([]);
        setInsuranceTypes([]);
        setRTODatat("");
        setInsuranceData("");
        setComprehensiveData("");
        setStartDate(e);
        SelectSegment(e.getMonth() + 1, e.getFullYear());
    }
    const SelectSegment = async () => {
        setIsLoading(true)
        setRTODatat("");
        try {
            const res = await base_url.post(`/twoWheeler/bajaj/SelectSegment`, {
                "date": startDate.getMonth() + 1,
                "year": startDate.getFullYear(),
            });
            if (res.data.status === 200) {
                setRTOStatename(res.data.data)
                setIsLoading(false);
            } else {

                setIsLoading(false);
            }
        } catch (error) {
            setIsLoading(false);
            console.log(error);
        }
    }
    const handleChangeRTO = async (e) => {
        const value = e.target.value;
        setRTODatat(value);
        setIsLoading(true);
        setInsuranceData("");
        setComprehensiveData("");
        setRTOerror(false);
        try {
            const res = await base_url.post(`/twoWheeler/bajaj/SelectInsuranceTypes`, {
                "date": startDate.getMonth() + 1,
                "year": startDate.getFullYear(),
            });
            if (res.data.status === 200) {
                setInsuranceTypes(res.data.data)
                setIsLoading(false);
            } else {

                setIsLoading(false);
            }
        } catch (error) {
            setIsLoading(false);
            console.log(error);
        }
    }

    const handleChangeInsuranceType = async (e) => {
        const value = e.target.value;
        setInsuranceData(value);
        setInsuranceError(false);
        setIsLoading(true);
        setComprehensiveData("");
        try {
            const res = await base_url.post(`/twoWheeler/bajaj/selectInsuranceValue`, {
                "date": startDate.getMonth() + 1,
                "year": startDate.getFullYear(),
                "insuranceType": value.toUpperCase()
            });
            if (res.data.status === 200) {
                setCommission(res.data.data);
                setIsLoading(false);
            } else {

                setIsLoading(false);
            }
        } catch (error) {
            setIsLoading(false);
            console.log(error);
        }
    }
    const handleClick = () => {

        if (RTOData == "") {
            setRTOerror(true);
        }
        if (InsuranceData == "") {
            setInsuranceError(true);
        }
        if (RTOData != "" && InsuranceData != "") {
            Swalll()
        }

    }

    const Swalll = () => {
        return (
            Swal.fire({
                title: `BAJAJ TWO WHEELER`,
                text: 'Modal with a custom image.',
                html: `<table class="table text-start">
                <tbody> 
                  <tr> 
                   <td>RTO </td> 
                   <td style="text-transform: capitalize;white-space: pre-wrap !important;">${RTOData.split('_').join(' ')}</td>
                  </tr>                 
                  <td>Commission</td>
                   <td style="text-transform: capitalize;white-space: pre-wrap !important;">${commission}${/[a-zA-Z]/g.test(commission) ? "" : "%"}</td> 
                 </tr> 
                </tbody> 
              </table>`,
                imageUrl: `${Logo}`,
                imageWidth: 400,
                imageHeight: 200,
                imageAlt: 'Custom image',
                confirmButtonText: 'OK',
                confirmButtonColor: 'green',
            })
        )
    }
    return (
        <div className='container-fluid mt-3  rounded position-top-right-center mt-5'>
            <div className='container '>
                <div className='row'>
                    {isLoading && <Spinner />}
                    <div className="mb-3 mx-auto text-center col-12 col-lg-8 col-md-8 col-sm-12 ">
                        <div className="mb-4 ">
                            <h1 className='form-heading text-center text-uppercase'> BAJAJ two wheeler insurance</h1>
                        </div>
                        {
                            userData.user_type !== "user" ? <Datepicker onChangedate={onChangedate} data={startDate} /> : ""
                        }
                        <SelectOption
                            title='RTO'
                            optionTitle='Select RTO'
                            data={RTOStatename}
                            onChange={handleChangeRTO}
                            errors={RTOError}
                            value={RTOData}
                        />
                        <SelectOption
                            title='Insurance Type'
                            optionTitle='Select Insurance Type'
                            data={InsuranceTypes}
                            onChange={handleChangeInsuranceType}
                            errors={InsuranceError}
                            value={InsuranceData}
                        />
                        <CustomButton
                            title={'search'}
                            handleClick={handleClick}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default BajajTw;
