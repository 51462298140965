import React, { useEffect, useState } from 'react'
import { NavLink } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import AuthDetail from '../components/common/auth';
const Home = () => {
    const navigate = useNavigate();
    const [userType, setUserType] = useState({});
    const { user } = AuthDetail();
    useEffect(() => {
        if (user == null) {
            navigate('/login')
        } else {
            // setUserType(JSON.parse(user));
        }
    }, [])
    return (
        // <div className=' container-fluid mt-3  rounded position-top-right-center mt-5 '>
        <div className=' container-fluid mt-3  rounded position-top-right-center mt-5 '>
            <div className='container mt-5'
                style={{ maxWidth: '900px', height: "60vh" }}
            >
                <div className='row justify-content-md-evenly justify-content-evenly px-3 text-center mx-a home_item'>
                    <NavLink to='/motor' className='col-lg-2 col-md-2 col-sm-5 col-10  p-4 text-center mx-1 my-3 item-card bg-body border-bottom6px'>
                        <NavLink to='/motor'>
                            <div className="on-bx text-center  d-flex flex-column justify-content-between align-items-center pt-2">
                                <img src="./icon/car.png" alt="" className="img-fluid w-50" />
                                <h6 style={{ color: '#236348' }}>Motor</h6>
                            </div>
                        </NavLink>
                    </NavLink>
                    <NavLink to='/two-wheeler' className='col-lg-2 col-md-2 col-sm-5 col-10  p-4 text-center mx-1 my-3 item-card bg-body border-bottom6px'>
                        <NavLink to='/two-wheeler'>
                            <div className="on-bx text-center  d-flex flex-column justify-content-between align-items-center  ">
                                <img src="./icon/two-wheeler.png" alt="" className="img-fluid " />
                                <h6 style={{ color: '#236348' }}>Two Wheeler</h6>
                            </div>
                        </NavLink>
                    </NavLink>
                    <NavLink to='/health' className='col-lg-2 col-md-2 col-sm-5 col-10  p-4 text-center mx-1 my-3 item-card bg-body border-bottom6px'>
                        <NavLink to='/health'>
                            <div className="on-bx text-center  d-flex flex-column justify-content-between align-items-center  ">
                                <img src="./icon/health.png" alt="" className="img-fluid" />
                                <h6 style={{ color: '#236348' }}>Health</h6>
                            </div>
                        </NavLink>
                    </NavLink>
                    {/* <NavLink to='/life' className='col-lg-2 col-md-2 col-sm-5 col-10  p-4 text-center mx-1 my-3 item-card bg-body border-bottom6px'>
                        <NavLink to='/life'>
                            <div className="on-bx text-center  d-flex flex-column justify-content-between align-items-center  ">
                                <img src="./icon/terms.png" alt="" className="img-fluid" />
                                <h6 style={{ color: '#236348' }}>Life</h6>
                            </div>
                        </NavLink>
                    </NavLink> */}
                    <NavLink to='/other' className='col-lg-2 col-md-2 col-sm-5 col-10  p-4 text-center mx-1 my-3 item-card bg-body border-bottom6px'>
                        <NavLink to='/Commercial'>
                            <div className="on-bx text-center  d-flex flex-column justify-content-between align-items-center  ">
                                <img src="./icon/more.jpeg" alt="" className="img-fluid" />
                                <h6 style={{ color: '#236348' }}>Commercial</h6>
                            </div>
                        </NavLink>
                    </NavLink>
                </div>

            </div>
        </div>
    )
}

export default Home
