import React, { useState } from "react";
// import DatePicker from "react-datepicker";
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import dayjs, { Dayjs } from 'dayjs';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import moment from "moment";
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
import { format } from 'date-fns';
const Datepicker = ({ onChangedate, data }) => {
    return (
        <>
            {/* <label className="form-label float-start ">Select Date <span className='text-danger'>*</span></label> */}
            {/* <DatePicker
                showIcon
                selected={data}
                onChange={onChangedate}
                dateFormat="MMMM yyyy"
                showMonthYearPicker
                maxDate={new Date()}
            // className="w-100 mb-4 py-2 border-2 label text-gray datePickerClass "
            /> */}
            <LocalizationProvider dateAdapter={AdapterDayjs} item xs={12} md={6}>
                <DemoContainer components={['DatePicker']}>
                    <MobileDatePicker label="Select Date"
                        value={dayjs(data)}
                        inputFormat="MM/dd/yyyy"
                        openTo="month"
                        // disablePast
                        disableFuture
                        style={{ backgroundColor: 'red' }}
                        // maxDate={new Date("2020-08-18")}
                        // onAccept={(date) => onChangedate(format(date["$d"], 'yyyy-MM-dd'))}
                        onAccept={(date) => onChangedate(date)}
                        views={['month', 'year']}
                        className="w-100 mb-4  border-2  text-gray "
                        inputProps={{
                            style: {
                                color: 'red',
                                borderColor: 'yellow',
                                // fontFamily: fonts.root,
                                borderStyle: 'solid',
                                borderWidth: 2,
                                borderRadius: 10,
                                outline: 'none',
                                // fontSize: fontSizes[3],
                                // padding: `${space[2]}px`,
                            },
                        }}
                        sx={{
                            // border: "1px solid blue",
                            "& .MuiOutlinedInput-root": {
                                // border: "1px solid yellow",
                                // borderRadius: "0",
                                // padding: "8"
                            },
                            "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
                                border: "1px solid green"
                            }
                        }}
                        // format="DD-MM-YYYY"

                        autoOk={true}
                    // onChange={date => console.log(format(date["$d"], 'yyyy-MM-dd'))}
                    // onChange={(date) => {
                    //     onChangedate(data)
                    // }}
                    // onChange={(newValue) => onChangedate(newValue)}
                    />
                </DemoContainer>
            </LocalizationProvider>
        </>
    );
}

export default Datepicker;
