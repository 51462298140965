import React, { useEffect, useState } from 'react'
import {
    Box,
    Grid,
    Typography,
    IconButton,
    TextField,
    Button,
    Paper,
    InputLabel,
    MenuItem,
    OutlinedInput,
    InputAdornment

} from "@mui/material";
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import CloseIcon from '@mui/icons-material/Close'
import AuthDetail from './AuthDetail';
import { ToastContainer, toast } from 'react-toastify';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { useFormik } from 'formik';
import { singinSchema } from '../../schemas';
import { useNavigate } from "react-router-dom";
const initialValues = {
    email: "",
    password: "",
    name: "",
    password: "",
    phone: "",
}
const AddForm = ({ closeEvent, editData, url, saveEvent, setPageReload }) => {
    const navigate = useNavigate();
    const [showPassword, setShowPassword] = useState(false);
    const [useData, setUserData] = useState({});
    const { base_url, setToken } = AuthDetail();
    const handleClickShowPassword = () => setShowPassword((show) => !show);
    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };
    const { values, errors, touched, handleBlur, handleChange, handleSubmit } = useFormik({
        initialValues: initialValues,
        validationSchema: singinSchema,
        onSubmit: async (values, action) => {
            try {
                const res = await base_url.post(`/register`, values);
                const data = res.data;
                if (data.success === true) {
                    toast.success('user added', { autoClose: 2000, });
                    // window.location.href = window.location.href;
                    setPageReload(true);
                    navigate("/matrix/dashboard");
                } else {
                    toast.error('Something Went to wrong !');

                }
            } catch (error) {
                console.log(error);
                toast.error('The email has already been taken !');
            }
            // action.resetForm();
        },
    });
    return (
        <div>
            <form onSubmit={handleSubmit}>
                <ToastContainer />
                <Typography variant='h5' align='center'>
                    Add User
                </Typography>
                <IconButton style={{ position: 'absolute', top: "0", right: "0" }}
                    onClick={closeEvent}
                >
                    <CloseIcon className='m-3' />
                </IconButton>
                <Box sx={{ m: 4 }} />
                <Grid container spacing={2} >

                    <Grid item xs={12} >
                        <TextField
                            id="outlined-basic"
                            variant="outlined"
                            label="Name"
                            name='name'
                            onChange={handleChange}
                            onBlur={handleBlur}
                            sx={{
                                width: '100%', height: '20%',
                                "& .MuiOutlinedInput-root": {
                                    "& > fieldset": { borderColor: errors.name && touched.name ? 'red' : '#236348' },
                                },
                                "& .MuiOutlinedInput-root.Mui-focused": {
                                    "& > fieldset": {
                                        borderColor: '#236348',
                                        border: errors.name && touched.name ? '1px solid red' : '1px solid #236348'
                                    }
                                }
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} >
                        <TextField
                            id="outlined-basic"
                            variant="outlined"
                            label="Email"
                            name='email'
                            type='email'
                            sx={{
                                width: '100%', height: '20%',
                                "& .MuiOutlinedInput-root": {
                                    "& > fieldset": { borderColor: errors.email && touched.email ? 'red' : '#236348' },
                                },
                                "& .MuiOutlinedInput-root.Mui-focused": {
                                    "& > fieldset": {
                                        borderColor: '#236348',
                                        border: errors.email && touched.email ? '1px solid red' : '1px solid #236348'
                                    }
                                }
                            }}
                            onChange={handleChange}
                            onBlur={handleBlur}
                        />
                    </Grid>
                    <Grid item xs={12} >
                        <TextField
                            id="outlined-basic"
                            variant="outlined"
                            label="Phone"
                            name='phone'
                            onChange={handleChange}
                            onBlur={handleBlur}
                            sx={{
                                width: '100%', height: '20%',
                                "& .MuiOutlinedInput-root": {
                                    "& > fieldset": { borderColor: errors.phone && touched.phone ? 'red' : '#236348' },
                                },
                                "& .MuiOutlinedInput-root.Mui-focused": {
                                    "& > fieldset": {
                                        borderColor: '#236348',
                                        border: errors.phone && touched.phone ? '1px solid red' : '1px solid #236348'
                                    }
                                }
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} >
                        {/* <Box >
                        <FormControl fullWidth>
                            <InputLabel id="demo-simple-select-label">User-Type</InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={'user'}
                                name='user_type'
                                label="User-Type"
                                sx={{ minWidth: '100%' }}
                                onChange={handleChange}
                                 onBlur={handleBlur}
                            >
                                <MenuItem value={'user'}>user</MenuItem>
                                <MenuItem value={'admin'}>admin</MenuItem>
                                <MenuItem value={'super_admin'}>supper-admin</MenuItem>
                            </Select>
                        </FormControl>
                    </Box> */}
                        <FormControl sx={{
                            width: '100%', height: '50px',
                            "& .MuiOutlinedInput-root": {
                                "& > fieldset": { borderColor: errors.password && touched.password ? 'red' : '#236348' },
                            },
                            "& .MuiOutlinedInput-root.Mui-focused": {
                                "& > fieldset": {
                                    borderColor: '#236348',
                                    border: errors.password && touched.password ? '1px solid red' : '1px solid #236348'
                                }
                            }
                        }} variant="outlined">
                            <InputLabel htmlFor="outlined-adornment-password">Password</InputLabel>
                            <OutlinedInput
                                id="outlined-adornment-password"
                                type={showPassword ? 'text' : 'password'}
                                endAdornment={
                                    <InputAdornment position="end">
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={handleClickShowPassword}
                                            onMouseDown={handleMouseDownPassword}
                                            edge="end"
                                        >
                                            {showPassword ? <VisibilityOff color="success" /> : <Visibility color="success" />}
                                        </IconButton>
                                    </InputAdornment>
                                }
                                label="Password"
                                name="password"
                                value={values.password}
                                onChange={handleChange}
                                onBlur={handleBlur}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant='h5' align='center'>
                            <Button variant="contained" sx={{ width: '100%', p: 2 }} color="success" type='submit'
                            // onClick={(e) => saveEvent(propsData)}
                            >Save</Button>
                        </Typography>
                    </Grid>
                </Grid>
                <Box sx={{ m: 4 }} />
            </form>
        </div>
    )
}

export default AddForm