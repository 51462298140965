import React, { useEffect, useState } from 'react';
import SelectOption from '../components/common/selectOption';
import CustomButton from '../components/common/customButton';
import Datepicker from '../components/common/datepicker';
import AuthDetail from '../components/common/auth';
import Spinner from '../components/spinner';
import Swal from 'sweetalert2';
import { useNavigate } from 'react-router-dom';
import Logo from '../assets/image/idealILogo.jpeg';
import Select from '../components/Select';
const ICICILombard = () => {
    const [startDate, setStartDate] = useState(new Date());
    const navigate = useNavigate();
    const { base_url, user } = AuthDetail();
    const [userData, setUserData] = useState({});
    const [isLoading, setIsLoading] = useState(false);
    const [swal, setSwal] = useState(false);
    const [RTO_ZONE, setRTO_ZONE] = useState([]);
    const [RTO_STATE, setRTO_STATE] = useState([]);
    const [RTO_LOCATION, setRTO_LOCATION] = useState([]);
    const [PRIVATE_CAR_TYPE, setPRIVATE_CAR_TYPE] = useState([]);
    const [PRIVATE_CAR_TYPE_VALUE, setPRIVATE_CAR_TYPE_VALUE] = useState([]);
    const [CAR_FUEL_TYPE, setCAR_FUEL_TYPE] = useState([]);
    const [CAR_FUEL_TYPE_KEY, setCAR_FUEL_TYPE_KEY] = useState([]);
    const [CAR_NCB, setCAR_NCB] = useState([]);
    const [CAR_NCB_KEY, setCAR_NCB_KEY] = useState([]);

    const [dataRTO_ZONE, setDataRTO_ZONE] = useState("");
    const [dataRTO_STATE, setDataRTO_STATE] = useState("");
    const [dataRTO_LOCATION, setDataRTO_LOCATION] = useState("");
    const [dataPRIVATE_CAR_TYPE, setDataPRIVATE_CAR_TYPE] = useState("");
    const [dataCAR_FUEL_TYPE, setDataCAR_FUEL_TYPE] = useState("");
    const [dataNcb, setDataNCB] = useState("");
    const [dataNcbValue, setDataNCBValue] = useState("");

    const [errorRtoZone, setErrorRtoZome] = useState("");
    const [errorRTO_STATE, setErrorRTO_STATE] = useState("");
    const [errorRTO_LOCATION, setErrorRTO_LOCATION] = useState("");
    const [errorPRIVATE_CAR_TYPE, seterrorDataPRIVATE_CAR_TYPE] = useState("");
    const [errorCAR_FUEL_TYPE, setErrorCAR_FUEL_TYPE] = useState("");
    const [error_NCB, setErro_NCB] = useState("");
    const [commission, setCommission] = useState("");
    const [useEffectCallData, setUseEffectCallDAta] = useState("");
    useEffect(() => {
        setIsLoading(true);
        if (user != null) {
            setUserData(JSON.parse(user));
        } else {
            navigate('/login')
        }

        SelectRTOState(startDate.getMonth() + 1, startDate.getFullYear());

    }, [])
    const onChangedate = (e) => {
        setStartDate(e);
        setRTO_ZONE([]);
        setRTO_STATE([]);
        setRTO_LOCATION([]);
        setPRIVATE_CAR_TYPE([]);
        setPRIVATE_CAR_TYPE_VALUE([]);
        setCAR_FUEL_TYPE([]);
        setCAR_FUEL_TYPE_KEY([]);
        setCAR_NCB([]);
        setIsLoading(true);
        SelectRTOState(e.getMonth() + 1, e.getFullYear());
    }
    const SelectRTOState = async (startDate, year) => {
        try {
            const res = await base_url.post(`/motor/lombard/selectRtoZone`, {
                "date": startDate,
                "year": year
            });
            if (res.data.status === 200) {
                setRTO_ZONE(res.data.data);
                setIsLoading(false);
            } else {

                setIsLoading(false);
            }
        } catch (error) {
            setIsLoading(false);
            console.log(error);
        }
    }
    const handleChangeRTOZONE = async (e) => {
        const value = e.target.value;
        setDataRTO_ZONE(value);
        setIsLoading(true);
        setErrorRtoZome(false);
        setDataRTO_STATE("");
        setDataRTO_LOCATION("");
        setDataPRIVATE_CAR_TYPE("");
        setDataCAR_FUEL_TYPE("");
        setDataNCB("");
        setDataNCBValue("");
        setIsLoading(true);
        try {
            const res = await base_url.post(`/motor/lombard/selectRtoState`, {
                "date": startDate.getMonth() + 1,
                "year": startDate.getFullYear(),
                "rtoZone": value.split('_').join(' '),
            });
            if (res.data.status === 200) {
                setRTO_STATE(res.data.data);
                setIsLoading(false);
            } else {
                setIsLoading(false);
            }
        } catch (error) {
            setIsLoading(false);
            console.log(error);
        }
    }
    const handleChangeRtoState = async (e) => {
        const value = e.target.value;
        setDataRTO_STATE(value);
        setErrorRTO_STATE(false);
        setDataRTO_LOCATION("");
        setDataPRIVATE_CAR_TYPE("");
        setDataCAR_FUEL_TYPE("");
        setDataNCB("");
        setDataNCBValue("");
        setIsLoading(true);
        try {
            const res = await base_url.post(`/motor/lombard/selectRtoLocation`, {
                "date": startDate.getMonth() + 1,
                "year": startDate.getFullYear(),
                "rtoZone": dataRTO_ZONE.split('_').join(' '),
                "rtoState": value.split('_').join(' '),
            });
            if (res.data.status === 200) {
                setRTO_LOCATION(res.data.data);
                setIsLoading(false);
            } else {
                setIsLoading(false);
            }
        } catch (error) {
            setIsLoading(false);
            console.log(error);
        }
    }
    const handleChangertoLocation = async (e) => {
        const value = e.target.value;
        console.log(PRIVATE_CAR_TYPE_VALUE)
        setDataRTO_LOCATION(value);
        setErrorRTO_LOCATION(false);
        setDataPRIVATE_CAR_TYPE("");
        setDataCAR_FUEL_TYPE("");
        setDataNCB("");
        setDataNCBValue("");
        setIsLoading(true);
        try {
            const res = await base_url.post(`/motor/lombard/selectPrivateCarType`, {
                "date": startDate.getMonth() + 1,
                "year": startDate.getFullYear(),
                "rtoZone": dataRTO_ZONE.split('_').join(' '),
                "rtoState": dataRTO_STATE.split('_').join(' '),
                "rtoLocation": value.split('_').join(' '),
            });
            if (res.data.status === 200) {
                setPRIVATE_CAR_TYPE(res.data.data)
                setPRIVATE_CAR_TYPE_VALUE(res.data.dataValue)
                setIsLoading(false);
            } else {
                setIsLoading(false);
            }
        } catch (error) {
            setIsLoading(false);
            console.log(error);
        }
    }
    const handleChangertoPrivateCare = async (e) => {
        const value = e.target.value;
        setDataPRIVATE_CAR_TYPE(value);
        seterrorDataPRIVATE_CAR_TYPE(false);
        setDataCAR_FUEL_TYPE("");
        setDataNCB("");
        setDataNCBValue("");
        setIsLoading(true);
        setCAR_NCB([]);
        try {
            const res = await base_url.post(`/motor/lombard/PRIVATE_CAR_COMP_SAOD_POINTS_ON_OD_TYPE`, {
                "date": startDate.getMonth() + 1,
                "year": startDate.getFullYear(),
                "rtoZone": dataRTO_ZONE.split('_').join(' '),
                "rtoState": dataRTO_STATE.split('_').join(' '),
                "rtoLocation": dataRTO_LOCATION.split('_').join(' '),
                "rtoPrivateCareType": value.toUpperCase(),
            });
            if (res.data.status === 200) {
                if (value == "USED_CARS") {
                    setCommission(res.data.commission);
                } else {
                    setCAR_FUEL_TYPE(res.data.data)
                    setCAR_FUEL_TYPE_KEY(res.data.dataKey);
                }
                setIsLoading(false);
            } else {
                setIsLoading(false);
            }
        } catch (error) {
            setIsLoading(false);
            console.log(error);
        }
    }
    const handleChangertoFuelType = async (e) => {
        const value = e.target.value;
        setDataCAR_FUEL_TYPE(value);
        setDataNCB("");
        setDataNCBValue("");
        setErrorCAR_FUEL_TYPE(false);
        setIsLoading(true);
        try {
            let uri = "";
            if (value == "NEW_PETROL" || value == "NEW_DIESEL") {
                uri = "/motor/lombard/selectCommissionPercentage"
            } else {
                uri = "/motor/lombard/SelectFuelType"
            }
            const res = await base_url.post(uri, {
                "rtoZone": dataRTO_ZONE.split('_').join(' '),
                "rtoState": dataRTO_STATE.split('_').join(' '),
                "rtoLocation": dataRTO_LOCATION.split('_').join(' '),
                "privateCareFuel": value,
                "date": startDate.getMonth() + 1,
                "year": startDate.getFullYear(),
            });

            if (res.data.status === 200) {
                if (value == "NEW_PETROL" || value == "NEW_DIESEL") {
                    setCommission(res.data.data)
                    setIsLoading(false);
                } else {
                    setCAR_NCB(res.data.data);
                    setCAR_NCB_KEY(res.data.dataKey)
                    setIsLoading(false);
                }
            } else {
                setIsLoading(false);
            }
        } catch (error) {
            setIsLoading(false);
            console.log(error);
        }
    }
    const handleChangertoNCB = async (e) => {
        const value = e.target.value;
        const index = e.target.selectedIndex;
        const el = e.target.childNodes[index];
        const option = el.getAttribute('id');
        setDataNCB(value);
        setDataNCBValue(option);
        setErro_NCB(false);
        setIsLoading(true);
        try {
            const res = await base_url.post(`/motor/lombard/selectNCB`, {
                "rtoZone": dataRTO_ZONE.split('_').join(' '),
                "rtoState": dataRTO_STATE.split('_').join(' '),
                "rtoLocation": dataRTO_LOCATION.split('_').join(' '),
                "privateCareFuel": dataPRIVATE_CAR_TYPE,
                "ncb": value,
                "date": startDate.getMonth() + 1,
                "year": startDate.getFullYear(),
            });
            if (res.data.status === 200) {
                setCommission(res.data.data)
                setIsLoading(false);
            } else {
                setIsLoading(false);
            }
        } catch (error) {
            setIsLoading(false);
            console.log(error);
        }
    }
    const handleClick = () => {
        if (dataRTO_ZONE == "") {
            setErrorRtoZome(true);
        }
        if (dataRTO_STATE == "") {
            setErrorRTO_STATE(true);
        }
        if (dataRTO_LOCATION == "") {
            setErrorRTO_LOCATION(true);
        }
        if (dataPRIVATE_CAR_TYPE == "") {
            seterrorDataPRIVATE_CAR_TYPE(true);
        }
        if (dataCAR_FUEL_TYPE == "" && dataPRIVATE_CAR_TYPE != "USED_CARS") {
            setErrorCAR_FUEL_TYPE(true);
        }
        if (dataNcb == "") {
            setErro_NCB(true);
        }
        if (dataRTO_ZONE !== ""
            && dataRTO_STATE !== ""
            && dataRTO_LOCATION !== ""
            && dataPRIVATE_CAR_TYPE !== ""
            && dataPRIVATE_CAR_TYPE == "USED_CARS") {
            Swalll();
        } else if (dataRTO_ZONE !== ""
            && dataRTO_STATE !== ""
            && dataRTO_LOCATION !== ""
            && dataPRIVATE_CAR_TYPE !== ""
            && dataCAR_FUEL_TYPE == "NEW_PETROL" || dataCAR_FUEL_TYPE == "NEW_DIESEL") {
            Swalll();
        } else if (dataRTO_ZONE !== ""
            && dataRTO_STATE !== ""
            && dataRTO_LOCATION !== ""
            && dataPRIVATE_CAR_TYPE !== ""
            && dataNcb != "") {
            Swalll();
        }
    }
    const Swalll = () => {
        return (
            Swal.fire({
                title: `ICICI LOMBARD FOUR WHEELER `,
                text: 'Modal with a custom image.',
                html: `
                <table class="table text-start">
                <tbody>
                  <tr>
                    <td>RTO ZONE</td>
                    <td>${dataRTO_ZONE}</td>
                  </tr> 
                  <tr> 
                   <td>RTO STATE</td> 
                   <td style="text-transform: capitalize;white-space: pre-wrap !important;">${dataRTO_STATE.split('_').join(' ')}</td>
                  </tr> 
                  <tr> 
                  <td>RTO Location </td>
                  <td style="text-transform: capitalize;white-space: pre-wrap !important;">${dataRTO_LOCATION.split('_').join(' ')}</td> 
                </tr> 
                <tr>
                <td>Insurance  Type </td>
                    <td style="text-transform: capitalize;white-space: pre-wrap !important;">${dataPRIVATE_CAR_TYPE.split('_').join(' ')}</td> 
                  </tr>
                  ${dataPRIVATE_CAR_TYPE != "USED_CARS" ? `<tr> 
                   <td>Insurance Sub Type </td>
                    <td style="text-transform: capitalize;white-space: pre-wrap !important;">${dataCAR_FUEL_TYPE.split('_').join(' ')}</td> 
                  </tr>`: ""}
                  ${dataPRIVATE_CAR_TYPE != "USED_CARS" && dataCAR_FUEL_TYPE != "NEW_PETROL" && dataCAR_FUEL_TYPE != "NEW_DIESEL" ? `<tr> 
                  <td>Is NCB </td>
                   <td style="text-transform: capitalize;white-space: pre-wrap !important;">${dataNcbValue.split('_').join(' ')}</td> 
                 </tr>`: ""}
                  <tr> 
                  <td>Commission</td>
                   <td style="text-transform: capitalize;white-space: pre-wrap !important;">${commission}%${/[a-zA-Z]/g.test(commission) ? "" : ""}</td> 
                 </tr> 
                 ${dataPRIVATE_CAR_TYPE.toLowerCase().match("comprehensive") != null ?
                        `<tr>
                      <td>Remarks</td>
                    <td style="text-transform: capitalize;white-space: pre-wrap !important;">Commission to be calculated on the OD Part</td> 
                  </tr> `: ""}
                </tbody> 
              </table>`,
                imageUrl: `${Logo}`,
                imageWidth: 400,
                imageHeight: 200,
                imageAlt: 'Custom image',
                confirmButtonText: 'OK',
                confirmButtonColor: 'green',
            })
        )
    }
    return (
        <div className='container-fluid mt-3  rounded position-top-right-center mt-5'>
            <div className='container '>
                <div className='row'>
                    {isLoading && <Spinner />}
                    <div className="mb-3 mx-auto text-center col-12 col-lg-8 col-md-8 col-sm-12 ">
                        <div className="mb-4 ">
                            <h1 className='form-heading text-center text-uppercase'>ICICI lombard four Wheeler Insurance</h1>
                        </div>
                        {
                            userData.user_type !== "user" ? <Datepicker onChangedate={onChangedate} data={startDate} /> : ""
                        }
                        <SelectOption
                            title='RTO Zone'
                            optionTitle='Select RTO Zone'
                            data={RTO_ZONE}
                            onChange={handleChangeRTOZONE}
                            errors={errorRtoZone}
                            value={dataRTO_ZONE}
                        />
                        <SelectOption
                            title='RTO State'
                            optionTitle='Select RTO State'
                            data={RTO_STATE}
                            onChange={handleChangeRtoState}
                            errors={errorRTO_STATE}
                            value={dataRTO_STATE}
                        />
                        <SelectOption
                            title='RTO Location'
                            optionTitle='Select RTO Location'
                            data={RTO_LOCATION}
                            onChange={handleChangertoLocation}
                            errors={errorRTO_LOCATION}
                            value={dataRTO_LOCATION}
                        />
                        <Select
                            title='Insurance Type'
                            optionTitle='Select Insurance Type'
                            dataKey={PRIVATE_CAR_TYPE_VALUE}
                            data={PRIVATE_CAR_TYPE}
                            onChange={handleChangertoPrivateCare}
                            errors={errorPRIVATE_CAR_TYPE}
                            value={dataPRIVATE_CAR_TYPE}
                        />
                        {dataPRIVATE_CAR_TYPE != "USED_CARS" && <Select
                            title='Insurance Sub Type'
                            optionTitle='Insurance Sub Type'
                            data={CAR_FUEL_TYPE}
                            dataKey={CAR_FUEL_TYPE_KEY}
                            onChange={handleChangertoFuelType}
                            errors={errorCAR_FUEL_TYPE}
                            value={dataCAR_FUEL_TYPE}
                        />}
                        {dataPRIVATE_CAR_TYPE != "USED_CARS" && dataCAR_FUEL_TYPE != "NEW_PETROL" && dataCAR_FUEL_TYPE != "NEW_DIESEL"
                            &&
                            <Select
                                title='Is NCB'
                                optionTitle='Is NCB'
                                data={CAR_NCB}
                                dataKey={CAR_NCB_KEY}
                                onChange={handleChangertoNCB}
                                errors={error_NCB}
                                value={dataNcb}
                            />}
                        <CustomButton
                            title={'search'}
                            handleClick={handleClick}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ICICILombard;
