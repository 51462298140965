import React, { useState, useEffect } from 'react';
import CustomButton from '../../components/common/customButton';
import SelectOption from '../../components/common/selectOption';
import AuthDetail from '../../components/common/auth';
import Spinner from '../../components/spinner';
import { useNavigate } from 'react-router-dom';
import Datepicker from '../../components/common/datepicker';
import Swal from 'sweetalert2';
import Logo from '../../assets/image/idealILogo.jpeg';
import Select from '../../components/Select';
const twoWheelerTypes = [
    "Digit_TW",
    "Digit_TW_EV"
];
const Godigit = () => {
    const navigate = useNavigate();
    const { base_url, user } = AuthDetail();
    const [userData, setUserData] = useState({});
    const [isLoading, setIsLoading] = useState(false);
    const [startDate, setStartDate] = useState(new Date());

    const [ZONE, setZONE] = useState([]);
    const [ZONEKey, setZONEKey] = useState([]);
    const [RTOData, setRTOData] = useState([]);
    const [RTODataKey, setRTODataKey] = useState([]);
    const [SEGMENTS, setSEGMENTS] = useState([])
    const [pacTypeData, setPacTypeData] = useState([]);
    const [pacTypeDataKey, setPacTypeDataKey] = useState([]);

    const [ZONEDataKey, setZONEDataKey] = useState("");
    const [ZONEDataValue, setZONEDataValue] = useState("");
    const [ClusterData, setClusterData] = useState("");
    const [ClusterDataKey, setClusterDataKey] = useState("");
    const [SEGMENTSData, setSEGMENTSDAta] = useState("")
    const [PackageTypeData, setPackageTypeData] = useState("");
    const [commission, setCommission] = useState("");
    const [tWType, setTWType] = useState("")

    const [tWTypeError, setTWTypeError] = useState("")
    const [ZONEError, setZONEError] = useState("");
    const [RTOErroe, setRTOErroe] = useState("");
    const [SEGMENTSDError, setSEGMENTSDError] = useState("")
    const [PackageTypeError, setPackageTypeError] = useState("");
    const [useEffectCallData, setUseEffectCallDAta] = useState("");
    useEffect(() => {
        if (user != null) {
            setUserData(JSON.parse(user));
        } else {
            navigate('/login')
        }
    }, [])
    const onChangedate = (e) => {
        setStartDate(e)
    }
    const handleChangeTwoWheelerTypes = async (e) => {
        const value = e.target.value;
        setTWType(value);
        setZONE([]);
        setZONEKey([])
        setRTOData([]);
        setRTODataKey([]);
        setSEGMENTS([]);
        setPacTypeData([]);
        setPacTypeDataKey([]);
        setIsLoading(true);
        setTWTypeError(false);
        setZONEDataKey("");
        setZONEDataValue("")
        setClusterData("");
        setClusterDataKey("");
        setSEGMENTSDAta("");
        setPackageTypeData("");
        setCommission("");
        try {
            const res = await base_url.post(`/twoWheeler/godigit/SelectTwType`, {
                "date": startDate.getMonth() + 1,
                "year": startDate.getFullYear(),
                "tWType": value
            });
            if (res.data.status == 200) {
                setUseEffectCallDAta();
                setZONE(res.data.data);
                setZONEKey(res.data.data);
                setIsLoading(false);
            } else {

                setIsLoading(false);
            }
        } catch (error) {
            setIsLoading(false);
            console.log(error);
        }
    }
    const handleChangeZONE = async (e) => {
        const value = e.target.value;
        setZONEDataKey(value);
        setZONEDataValue(value)
        setRTOData([]);
        setRTODataKey([]);
        setSEGMENTS([]);
        setPacTypeData([]);
        setPacTypeDataKey([]);
        setZONEError(false);
        setClusterData("");
        setClusterDataKey("");
        setSEGMENTSDAta("");
        setPackageTypeData("");
        setCommission("");
        setIsLoading(true);
        try {
            const res = await base_url.post(`/twoWheeler/godigit/SelectZONE`, {
                "date": useEffectCallData == "" ? startDate.getMonth() + 1 : startDate.getMonth(),
                "year": startDate.getFullYear(),
                "tWType": tWType,
                "ZONE": value.split('_').join(' ')
            });
            if (res.data.status == 200) {
                let arrayRTOVal = [];
                let arrayRTOClusterName = [];
                Object.values(res.data.data).map((data, index) => {
                    arrayRTOVal.push(data.RTO);
                })
                Object.values(res.data.data).map((data, index) => {
                    arrayRTOClusterName.push(data.NEW_CLUSTER_NAME);
                })
                setRTOData(arrayRTOVal);
                setRTODataKey(arrayRTOClusterName);
                setIsLoading(false);
            } else {
                setIsLoading(false);
            }
        } catch (error) {
            setIsLoading(false);
            console.log(error);
        }
    }
    const handleChangeCluster = async (e) => {
        const value = e.target.value;
        const index = e.target.selectedIndex;
        const el = e.target.childNodes[index]
        const option = el.getAttribute('id');
        setClusterData(option);
        setClusterDataKey(value)
        setSEGMENTS([]);
        setPacTypeData([]);
        setPacTypeDataKey([]);
        setRTOErroe(false);
        setSEGMENTSDAta("");
        setPackageTypeData("");
        setCommission("");
        setIsLoading(true);
        try {
            const res = await base_url.post(`/twoWheeler/godigit/SelectRTO`, {
                "date": useEffectCallData == "" ? startDate.getMonth() + 1 : startDate.getMonth(),
                "year": startDate.getFullYear(),
                "tWType": tWType,
                "ZONE": ZONEDataKey.split('_').join(' '),
                "RTO": value,
            });
            if (res.data.status == 200) {
                setSEGMENTS(res.data.data);
                setIsLoading(false);
            } else {
                setIsLoading(false);
            }
        } catch (error) {
            setIsLoading(false);
            console.log(error);
        }
    }
    const handleChangeSEGMENTS = async (e) => {
        const value = e.target.value;
        setSEGMENTSDAta(value);
        setPacTypeData([]);
        setPacTypeDataKey([]);
        setSEGMENTSDError(false);
        setPackageTypeData("");
        setIsLoading(true);
        try {
            const res = await base_url.post(`/twoWheeler/godigit/SelectSegments`, {
                "date": useEffectCallData == "" ? startDate.getMonth() + 1 : startDate.getMonth(),
                "year": startDate.getFullYear(),
                "tWType": tWType,
                "ZONE": ZONEDataKey.split('_').join(' '),
                "RTO": ClusterDataKey,
                'SEGMENTS': value.split('_').join(' '),
            });
            if (res.data.status == 200) {
                setPacTypeData(Object.values(JSON.parse(res.data.data)));
                setPacTypeDataKey(Object.keys(JSON.parse(res.data.data)));
                setIsLoading(false);
            } else {
                setIsLoading(false);
            }
        } catch (error) {
            setIsLoading(false);
            console.log(error);
        }
    }
    const handleChangePackageType = async (e) => {
        const value = e.target.value;
        setPackageTypeData(value);
        setPackageTypeError(false);
        setIsLoading(true);
        try {
            const res = await base_url.post(`/twoWheeler/godigit/SelectPackageType`, {
                "date": useEffectCallData == "" ? startDate.getMonth() + 1 : startDate.getMonth(),
                "year": startDate.getFullYear(),
                "tWType": tWType,
                "ZONE": ZONEDataKey.split('_').join(' '),
                "RTO": ClusterDataKey,
                'SEGMENTS': SEGMENTSData.split('_').join(' '),
                'PackageType': value.split(' ').join('_')
            });
            if (res.data.status == 200) {
                setCommission(res.data.data);
                setIsLoading(false);
            } else {
                setIsLoading(false);
            }
        } catch (error) {
            setIsLoading(false);
            console.log(error);
        }
    }
    const handleClick = () => {
        if (tWType == "") {
            setTWTypeError(true);
        }
        if (ZONEDataKey == "") {
            setZONEError(true);
        }
        if (ClusterData == "") {
            setRTOErroe(true);
        }
        if (SEGMENTSData == "") {
            setSEGMENTSDError(true);
        }
        if (PackageTypeData == "") {
            setPackageTypeError(true);
        }
        if (tWType !== ""
            && ZONEDataKey !== ""
            && ClusterData !== ""
            && SEGMENTSData !== ""
            && PackageTypeData !== "") {
            Swalll();
        }
    }
    const Swalll = () => {
        return (
            Swal.fire({
                title: `GODIGIT TWO WHEELER`,
                text: 'Modal with a custom image.',
                html: `<table class="table text-start">
          <tbody>
            <tr>
              <td>Two Wheeler Type</td>
              <td style="text-transform: capitalize;white-space: pre-wrap !important;">${tWType.split('_').join(' ')}</td>
            </tr>
            <tr>
              <td>Zone</td>
              <td style="text-transform: capitalize;white-space: pre-wrap !important;">${ZONEDataKey.split('_').join(' ')}</td>
            </tr>
            <tr>
              <td>New cluster name</td>
              <td style="text-transform: capitalize;white-space: pre-wrap !important;">${ClusterDataKey.split('_').join(' ')}</td>
            </tr>
            <tr>
              <td>Segments</td>
              <td style="text-transform: capitalize;white-space: pre-wrap !important;">${SEGMENTSData.split('_').join(' ')}</td>
            </tr>
            <tr>
              <td>Packge</td>
              <td style="text-transform: capitalize;white-space: pre-wrap !important;">${PackageTypeData.split('_').join(' ')}</td>
            </tr>
            <tr> <td>Commission </td> <td style="text-transform: capitalize;white-space: pre-wrap !important;">${commission}${/[a-zA-Z]/g.test(commission) ? "" : "%"}</td> </tr>
          </tbody> </table>`,
                imageUrl: `${Logo}`,
                imageWidth: 400,
                imageHeight: 200,
                imageAlt: 'Custom image',
                confirmButtonText: 'OK',
                confirmButtonColor: 'green',
            })
        )
    }
    return (
        <div className='container-fluid mt-3  rounded position-top-right-center mt-5'>
            <div className='container '>
                <div className='row'>
                    {isLoading && <Spinner />}
                    <div className="mb-3 mx-auto text-center col-12 col-lg-8 col-md-8 col-sm-12 ">
                        <div className="mb-4 ">
                            <h1 className='form-heading text-center text-uppercase'> godigit two wheeler insurance</h1>
                        </div>
                        {
                            userData.user_type !== "user" ? <Datepicker onChangedate={onChangedate} data={startDate} /> : ""
                        }

                        <SelectOption
                            title='Two Wheeler Types'
                            optionTitle='Select Two Wheeler Types'
                            data={twoWheelerTypes}
                            onChange={handleChangeTwoWheelerTypes}
                            errors={tWTypeError}
                            value={tWType}
                        />
                        <Select
                            title='RTO Zone'
                            optionTitle='Select RTO Zone'
                            data={ZONE}
                            dataKey={ZONEKey}
                            onChange={handleChangeZONE}
                            errors={ZONEError}
                            value={ZONEDataValue}
                        />
                        <Select
                            title='RTO List'
                            optionTitle='Select RTO List'
                            data={RTOData}
                            dataKey={RTODataKey}
                            onChange={handleChangeCluster}
                            errors={RTOErroe}
                            value={ClusterDataKey}
                            company={'godigit'}
                        />
                        <SelectOption
                            title='Segments'
                            optionTitle='Select Segments'
                            data={SEGMENTS}
                            onChange={handleChangeSEGMENTS}
                            errors={SEGMENTSDError}
                            value={SEGMENTSData}
                        />
                        <Select
                            title='Packge'
                            optionTitle='Select Packge'
                            data={pacTypeData}
                            dataKey={pacTypeDataKey}
                            onChange={handleChangePackageType}
                            errors={PackageTypeError}
                            value={PackageTypeData}
                        />
                        <CustomButton
                            title={'search'}
                            handleClick={handleClick}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Godigit;
