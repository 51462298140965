import React, { useState, useEffect } from 'react';
import SelectOption from '../../components/common/selectOption';
import CustomButton from '../../components/common/customButton';
import AuthDetail from '../../components/common/auth';
import Datepicker from '../../components/common/datepicker';
import Spinner from '../../components/spinner';
import Swal from 'sweetalert2';
import { useNavigate } from 'react-router-dom';
import Logo from '../../assets/image/idealILogo.jpeg';
const date_arr = { 1: 'Jan', 2: 'Feb', 3: 'Mar', 4: 'Apr', 5: 'May', 6: 'Jun', 7: 'Jul', 8: 'Aug', 9: 'Sep', 10: 'Oct', 11: 'Nov', 12: 'Dec' };
const MagmaHealth = () => {
    const navigate = useNavigate();
    const { base_url, user } = AuthDetail();
    const [userData, setUserData] = useState({});
    const [isLoading, setIsLoading] = useState(false);
    const [startDate, setStartDate] = useState(new Date());
    const [froms, setFroms] = useState([]);
    const [policyTypes, setPolicyTypes] = useState([]);
    const [dataPolicyTypes, setDataPolicyTypes] = useState("");
    const [errorfroms, setErrorFroms] = useState("");
    const [errorPolicyTypes, setErrorPolicyTypes] = useState("");
    const [commission, setCommission] = useState("");

    useEffect(() => {
        setIsLoading(true);
        if (user != null) {
            setUserData(JSON.parse(user));
        } else {
            navigate('/login')
        }

        // selectForm(startDate.getMonth() + 1, startDate.getFullYear());
        PolicyType(`${date_arr[startDate.getMonth() + 1]}-${startDate.getFullYear()}`)


    }, []);

    // const selectForm = async (startDate, year) => {
    //     try {
    //         const res = await base_url.post(`/health/magma_nm_july/selectForm`, {
    //             "date": startDate,
    //             "year": year

    //         });
    //         if (res.data.status === 200) {
    //             setFroms(res.data.data);
    //             setIsLoading(false);
    //         } else {

    //             setIsLoading(false);
    //         }
    //     } catch (error) {
    //         setIsLoading(false);
    //         console.log(error);
    //     }
    // }
    const onChangedate = (e) => {
        let date = new Date(e)
        setStartDate(date);
        PolicyType(`${date_arr[date.getMonth() + 1]}-${date.getFullYear()}`);
        setFroms([]);
        setPolicyTypes([]);

    }
    const PolicyType = async (currentDate) => {
        setErrorFroms(false);
        setDataPolicyTypes("");
        setPolicyTypes([]);
        try {
            const res = await base_url.post(`/health`, {
                where: { DATE: currentDate, INSURANCE_COMPANY: "MAGMA HDI" },
                fields: ["TYPE"]
            });
            if (res.data.status === 200) {
                const data = res.data.data.map((data, index) => { return { label: data.TYPE, value: data.TYPE } })
                setPolicyTypes(data);
                setIsLoading(false);
            } else {
                setIsLoading(false);
            }
        } catch (error) {
            setIsLoading(false);
            console.log(error);
        }
    }
    const currentDate = `${date_arr[startDate.getMonth() + 1]}-${startDate.getFullYear()}`;
    const handleChangeSumInsured = async (e) => {
        const value = e.value;
        setDataPolicyTypes(value)
        setIsLoading(true);
        setErrorPolicyTypes(false);
        try {
            const res = await base_url.post(`/health`, {
                where: { DATE: currentDate, INSURANCE_COMPANY: "MAGMA HDI", TYPE: value },
                fields: ["RATE"]
            });

            if (res.data.status === 200) {
                setCommission(res.data.data[0].RATE);
                setIsLoading(false);
            } else {
                setIsLoading(false);
            }
        } catch (error) {
            setIsLoading(false);
            console.log(error);
        }
    }
    const handleClick = () => {

        if (dataPolicyTypes === "") {
            setErrorPolicyTypes(true);
        }
        if (dataPolicyTypes != "") {
            Swalll();
        }
    }
    const Swalll = () => {
        return (
            Swal.fire({
                title: `MAGMA HEALTH INSURANCE`,
                text: 'Modal with a custom image.',
                html: `<table class="table text-start">
          <tbody>
            <tr>
              <td>Policy Type</td>
              <td style="text-transform: capitalize;white-space: pre-wrap !important;">${dataPolicyTypes.split('_').join(' ')}</td>
            </tr>
            <tr> <td>Commission </td> <td style="text-transform: capitalize;white-space: pre-wrap !important;">${commission}${/[a-zA-Z]/g.test(commission) ? "" : "%"}</td> </tr>
          </tbody> </table>`,
                imageUrl: `${Logo}`,
                imageWidth: 400,
                imageHeight: 200,
                imageAlt: 'Custom image',
                confirmButtonText: 'OK',
                confirmButtonColor: 'green',
            })
        )
    }
    return (
        <div className='container-fluid mt-3  rounded position-top-right-center mt-5'>
            <div className='container '>
                <div className='row'>
                    {isLoading && <Spinner />}
                    <div className="mb-3 mx-auto text-center col-12 col-lg-8 col-md-8 col-sm-12 ">
                        <div className="mb-4 ">
                            <h1 className='form-heading text-center text-uppercase'> Magma health insurance</h1>
                        </div>
                        {
                            userData.user_type !== "user" ? <Datepicker onChangedate={onChangedate} data={startDate} /> : ""
                        }
                        <SelectOption
                            title='Policy Type'
                            optionTitle='Select Policy Type'
                            data={policyTypes}
                            onChange={handleChangeSumInsured}
                            errors={errorPolicyTypes}
                            value={dataPolicyTypes}
                        />
                        <CustomButton
                            title={'search'}
                            handleClick={handleClick}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default MagmaHealth;
