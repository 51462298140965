import * as Yup from "yup"

export const singUpSchema = Yup.object({
    email: Yup.string().email().required("Please enter your email"),
    password: Yup.string().min(6).required("Plase enter your password")
});
export const singinSchema = Yup.object({
    email: Yup.string().email().required("Please enter a email"),
    password: Yup.string().min(6).required("Plase enter your password"),
    name:Yup.string().min(6).required("Plase enter a Name"),
    name:Yup.string().min(5).required("Plase enter a Phone Number"),
    phone:Yup.string().min(10).max(10).required("Plase enter a Phone Number"),
});

export const healthCompanyUpSchema = Yup.object({
    selectHealthCompany: Yup.string().required("Please select any company")
});

export const healthTATASchema = Yup.object({
    productName: Yup.string().required("Please select any company"),
    sumInsured: Yup.string().required("Please select any company"),
    policyType: Yup.string().required("Please select any company")
});