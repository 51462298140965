import React, { useEffect, useState } from 'react';
import AuthDetail from '../components/common/auth';
import { useNavigate } from 'react-router-dom';
import Spinner from '../components/spinner';
import SelectOption from '../components/common/selectOption';
import CustomButton from '../components/common/customButton';
const Motor = () => {
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false);
    const { base_url, user } = AuthDetail();
    const [motorCompany, setMotorCompany] = useState([]);
    const [companyNem, setCompanyName] = useState("");
    const [ErrorCompanyNem, setErrorCompanyName] = useState("");
    const [userData, setUserData] = useState({});
    useEffect(() => {
        setIsLoading(true);
        if (user != null) {
            setUserData(JSON.parse(user));
        } else {
            navigate('/login')
        }
        SelectHealthCompany();
    }, []);
    const SelectHealthCompany = async () => {
        setIsLoading(true);
        try {
            const res = await base_url.get(`/insuranceType?insuranceType=motor`);
            if (res.data.status === 200) {
                setMotorCompany(res.data.data);
                setIsLoading(false)
            }
        } catch (error) {
            setIsLoading(false);
            console.log(error);
        }
    }
    const handleChangeCompanies = (e) => {
        const value = e.target.value;
        if (value == "") {
            setErrorCompanyName(true)
        } else {
            setCompanyName(e.target.value)
            setErrorCompanyName(false)
        }

    }
    const handleClick = () => {
        if (companyNem !== "") {
            setErrorCompanyName(false)
            navigate(companyNem);
        } else {
            setErrorCompanyName(true)
        }
    }
    return (
        <div className='container-fluid mt-3  rounded position-top-right-center mt-5'>
            <div className='container '>
                <div className='row'>
                    <div className="mb-3 mx-auto text-center col-12 col-lg-8 col-md-8 col-sm-12 ">
                        <div className="mb-4 ">
                            <h1 className='form-heading text-center text-uppercase'> FOUR WHEELER insurance companies</h1>
                        </div>
                        <SelectOption title='Company'
                            optionTitle='Select Company'
                            data={motorCompany}
                            onChange={handleChangeCompanies}
                            errors={ErrorCompanyNem} />
                        <CustomButton
                            title={'search'}
                            handleClick={handleClick}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Motor;
