import React, { useState, useEffect } from 'react';
import SelectOption from '../../components/common/selectOption';
import CustomButton from '../../components/common/customButton';
import AuthDetail from '../../components/common/auth';
import Datepicker from '../../components/common/datepicker';
import Spinner from '../../components/spinner';
import Swal from 'sweetalert2';
import { useNavigate } from 'react-router-dom';
import Logo from '../../assets/image/idealILogo.jpeg';
const date_arr = { 1: 'Jan', 2: 'Feb', 3: 'Mar', 4: 'Apr', 5: 'May', 6: 'Jun', 7: 'Jul', 8: 'Aug', 9: 'Sep', 10: 'Oct', 11: 'Nov', 12: 'Dec' };
const HDFC = () => {
    const navigate = useNavigate();
    const { base_url, user } = AuthDetail();
    const [userData, setUserData] = useState({});
    const [isLoading, setIsLoading] = useState(false);
    const [detil1, setDetil1] = useState([]);
    const [detil2, setDetil2] = useState([]);
    const [tierType, setTierType] = useState([]);
    const [tierSI, setTierSI] = useState([]);
    const [startDate, setStartDate] = useState(new Date());
    const [errorDetil1, setErrorDetil1] = useState("");
    const [dataDetil1, setDataDetil1] = useState("");
    const [errorDetil2, setErrorDetil2] = useState("");
    const [dataDetil2, setDataDetil2] = useState("");
    const [dateOn, setdateOn] = useState(false);
    const [errordataproductTypes, setErrordataproductTypes] = useState("");
    const [dataTierType, setDataTierType] = useState("");
    const [errorTierType, setErrorTierType] = useState("");
    const [dataTierSIValue, setDataTierSIValue] = useState("")
    const [commission, setCommission] = useState("");
    useEffect(() => {
        setIsLoading(true);
        if (user != null) {
            setUserData(JSON.parse(user));
        } else {
            navigate('/login')
        }
        selectDetil(`${date_arr[startDate.getMonth() + 1]}-${startDate.getFullYear()}`);

    }, []);

    useEffect(() => {
        if (dataDetil1 !== "" && dataDetil1 !== 'NEW' && dataDetil1 !== 'PORT') {
            setdateOn(true);
        } else {
            setdateOn(false);
        }
    }, [dataDetil1])
    const onChangedate = (e) => {
        let date = new Date(e)
        setStartDate(date);
        selectDetil(`${date_arr[date.getMonth() + 1]}-${date.getFullYear()}`);
        setDetil1([]);
        setDetil2([]);
        setTierType([]);
        setTierSI([]);
    }
    const selectDetil = async (currentDate) => {
        setIsLoading(true);
        try {
            const res = await base_url.post(`/health`, {
                where: { DATE: currentDate, INSURANCE_COMPANY: "HDFC ERGO" },
                fields: ["TYPE"]
            });
            setIsLoading(true);
            if (res.data.status === 200) {
                const data = res.data.data.map((data, index) => {
                    return { label: data.TYPE, value: data.TYPE }
                })
                setDetil1(data);
                setIsLoading(false);
            } else {
                setIsLoading(false);
            }
        } catch (error) {
            setIsLoading(false);
            console.log(error);
        }
    }
    const currentDate = `${date_arr[startDate.getMonth() + 1]}-${startDate.getFullYear()}`;
    const selectDetil2 = async (e) => {
        const value = e.value;
        setDataDetil1(value);
        setIsLoading(true);
        setErrorDetil1(false);
        setTierType([]);
        setTierSI([]);
        setDataDetil2("");
        setDataTierType("");
        setDataTierSIValue("");
        if (value === 'RENEW') {
            try {
                const res = await base_url.post(`/health`, {
                    where: { DATE: currentDate, INSURANCE_COMPANY: "HDFC ERGO", TYPE: value },
                    fields: ["YEAR"]
                });
                if (res.data.status === 200) {
                    const data = res.data.data.map((data, index) => {
                        return { label: data.YEAR, value: data.YEAR }
                    })
                    setDetil2(data);
                    setIsLoading(false);
                }
            } catch (error) {
                setIsLoading(false);
                console.log(error);
            }
        } else {
            selectTierType(e);
        }
    }
    useEffect(() => {

    }, [dataDetil1])
    const selectTierType = async (e, dataDetil1 = "") => {
        const value = e.value;
        setDataDetil2(value);
        setIsLoading(true);
        setErrorDetil2(false);
        setTierSI([]);
        setDataTierType("");
        // dataDetil1
        let bodyData = [];
        if (dataDetil1 == "RENEW") {
            bodyData = {
                where: { DATE: currentDate, INSURANCE_COMPANY: "HDFC ERGO", TYPE: dataDetil1, YEAR: value },
                fields: ["LOCATION"]
            }
        } else {
            bodyData = {
                where: { DATE: currentDate, INSURANCE_COMPANY: "HDFC ERGO", TYPE: value },
                fields: ["LOCATION"]
            }
        }
        try {
            const res = await base_url.post(`/health`, bodyData);
            if (res.data.status === 200) {
                const data = res.data.data.map((data, index) => {
                    return { label: data.LOCATION, value: data.LOCATION }
                })
                setTierType(data);
                setIsLoading(false);
            } else {
                setIsLoading(false);
            }
        } catch (error) {
            setIsLoading(false);
            console.log(error);
        }
    }
    const handleChangePolicyType = async (e) => {
        const value = e.value;
        setDataTierType(value);
        setIsLoading(true);
        setErrordataproductTypes(false);
        setDataTierSIValue("");
        try {
            const res = await base_url.post(`/health`, {
                // "detail1": dataDetil1.split('_').join(' '),
                // "detail2": dataDetil2.split('_').join(' '),
                // "tierType": value,
                // "date": startDate.getMonth() + 1,
                // "year": startDate.getFullYear()
                where: { DATE: currentDate, INSURANCE_COMPANY: "HDFC ERGO", TYPE: dataDetil1, LOCATION: value },
                fields: ["SUM_INSURED"]
            });
            if (res.data.status === 200) {
                const data = res.data.data.map((data, index) => {
                    return { label: data.SUM_INSURED, value: data.SUM_INSURED }
                })
                setTierSI(data);
                setIsLoading(false);
            } else {
                setIsLoading(false);
            }
        } catch (error) {
            setIsLoading(false);
            console.log(error);
        }
    }
    const handleChangeselectSI = async (e) => {
        const value = e.value;
        setDataTierSIValue(value);
        setIsLoading(true);
        setErrorTierType(false)
        try {
            const res = await base_url.post(`/health`, {
                // "detail1": dataDetil1.split('_').join(' '),
                // "detail2": dataDetil2.split('_').join(' '),
                // "date": startDate.getMonth() + 1,
                // "year": startDate.getFullYear(),
                // "tierType": dataTierType,
                // "tierSI": value
                where: { DATE: currentDate, INSURANCE_COMPANY: "HDFC ERGO", TYPE: dataDetil1, LOCATION: dataTierType, SUM_INSURED: value },
                fields: ["RATE"]
            });
            if (res.data.status === 200) {
                setCommission(res.data.data[0].RATE);
                setIsLoading(false);
            } else {
                setIsLoading(false);
            }
        } catch (error) {
            setIsLoading(false);
            console.log(error);
        }
    }
    const handleClick = () => {
        if (dataDetil1 == "") {
            setErrorDetil1(true);
        }
        if (dataDetil2 == "") {
            setErrorDetil2(true)
        }
        if (dataTierType == "") {
            setErrordataproductTypes(true);
        }
        if (dataTierSIValue == "") {
            setErrorTierType(true)
        }
        if (dataDetil1 != "" && dataDetil2 != "" && dataTierSIValue != "") {
            Swalll();
        }
    }
    const Swalll = () => {
        return (
            Swal.fire({
                title: `HDFC ERGO HEALTH INSURANCE`,
                text: 'Modal with a custom image.',
                html: `<table class="table text-start">
                <tbody>
                  <tr>
                    <td>Policy Type</td>
                    <td style="text-transform: capitalize;white-space: pre-wrap !important;">${dataDetil1}</td>
                  </tr> 
                  <tr> 
                  ${dataDetil1 == "RENEW" ? ` <td>Year of Renewal</td> 
                  <td style="text-transform: capitalize;white-space: pre-wrap !important;">${dataDetil2}</td>
                 </tr> `: ""
                    }
                  <tr> 
                   <td>Location </td>
                    <td style="text-transform: capitalize">${dataTierType}</td> 
                  </tr> 
                  <tr> 
                  <td>Sum Insured </td>
                  <td style="text-transform: capitalize"> ${dataTierSIValue}</td> 
                </tr> 
                  </tr> 
                  <tr> 
                  <td>Commission	</td>
                   <td style="text-transform: capitalize">${commission}${/[a-zA-Z]/g.test(commission) ? "" : "%"}</td> 
                 </tr> 
                </tbody> 
              </table>`,
                imageUrl: `${Logo}`,
                imageWidth: 400,
                imageHeight: 200,
                imageAlt: 'Custom image',
                confirmButtonText: 'OK',
                confirmButtonColor: 'green',
            })
        )
    }
    return (
        <div className='container-fluid mt-3  rounded position-top-right-center mt-5'>
            <div className='container'>
                <div className='row'>
                    {isLoading && <Spinner />}
                    <div className="mb-3 mx-auto text-center col-12 col-lg-8 col-md-8 col-sm-12 ">
                        <div className="mb-4 ">
                            <h1 className='form-heading text-center text-uppercase'> HDFC ERGO health insurance</h1>
                        </div>
                        {
                            userData.user_type !== "user" ? <Datepicker onChangedate={onChangedate} data={startDate} /> : ""
                        }
                        <SelectOption title='Policy Type'
                            optionTitle='Select Policy Type'
                            data={detil1} onChange={selectDetil2}
                            errors={errorDetil1} value={dataDetil1}
                        />
                        {dateOn ?
                            <SelectOption
                                title='Year of Renewal'
                                optionTitle='Select Year of Renewal'
                                data={detil2} onChange={(e) => selectTierType(e, dataDetil1)}
                                errors={errorDetil2} value={dataDetil2}
                            /> : ""
                        }
                        <SelectOption
                            title='Location '
                            optionTitle='Select Location'
                            data={tierType}
                            onChange={handleChangePolicyType}
                            errors={errordataproductTypes}
                            value={dataTierType} />
                        <SelectOption
                            title='Sum Insured'
                            optionTitle='Select Sum Insured'
                            data={tierSI}
                            onChange={handleChangeselectSI}
                            errors={errorTierType}
                            value={dataTierSIValue} />
                        <CustomButton
                            title={'search'}
                            handleClick={handleClick}
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default HDFC