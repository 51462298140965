import React, { useState, useEffect } from 'react'
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
  Divider,
  AddCircleIcon,
  Button,
  Box,
  Stack,
  SideNav,
  Autocomplete,
  TextField,
  Grid,
  Modal,
  Card,
  Input
} from '@mui/material';
import * as XLSX from "xlsx";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import AuthDetail from '../components/AuthDetail';
import EditForm from '../components/EditForm';
import { ToastContainer, toast } from 'react-toastify';
import { useNavigate } from "react-router-dom";
import AddForm from '../components/AddForm';
const UserLIst = () => {
  const navigate = useNavigate();
  const { base_url, setToken, user } = AuthDetail();
  const [rowsData, setRows] = useState([]);
  const [page, setPage] = useState(0);
  const [pageReload, setPageReload] = useState("")
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [editOpen, setEditOpen] = useState(false);
  const [AddOpen, setAddOpen] = useState(false);
  const [userData, setuserData] = useState({
    name: '',
    email: '',
    phone: '',
    user_type: '',
  })
  const [editData, setEditData] = useState({
  })
  const handleEditClose = () => setEditOpen(false);
  const editDataTable = (data) => {
    setEditOpen(true)
    setEditData(data);
  }
  const handleAddClose = () => setAddOpen(false);
  const clickAddForm = () => {
    setAddOpen(true);
  }
  const deleteUser = () => {
  }
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  useEffect(() => {
    setAddOpen(false);
    getDataTata();
    checkUserToken();
  }, [pageReload])
  const checkUserToken = async () => {
    if (user != null) {
      const userData = JSON.parse(user);
      if (userData.user_type === "super_admin") {

      } else {
        navigate('/');
      }
    } else {
      navigate('/login');
    }
  }
  const getDataTata = async () => {
    try {
      const res = await base_url.get(`/userList`);
      const data = res.data;
      if (data.status === 200) {
        setRows(data.data);
      }
    } catch (error) {
      console.table(error);
    }
  }
  if (pageReload == true) {
    setPageReload(false);
    setAddOpen(false);
    getDataTata();
    checkUserToken();
  }
  const handleSaved = async (dataValue) => {
    try {
      const res = await base_url.post('/health/tata/selectTataDataEdit', dataValue);
      const data = res.data;
      if (data.status === 200) {
        getDataTata();
        setTimeout(() => {
          navigate("/121/dashboard/TataAig");
        }, 2000);
        toast.success('Success Notification !', { autoClose: 2000, });
      } else {
        toast.error('Something Went to wrong !', { autoClose: 2000, });
      }
    } catch (error) {
      console.table(error);
    }
  }
  const handleAddSaved = async (data) => {

  }
  const handleChange = (e) => {
    const value = e.target.value;
    const name = e.target.name;
    setuserData({ ...userData, [name]: value });
    console.log(userData)
  }
  const [data, setData] = useState([]);
  return (
    <Paper sx={{ width: "98%", overflow: "hidden", padding: "12px" }} className='mx-3 my-3'>
      <Modal
        open={editOpen}
        // onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          bgcolor: 'background.paper',
          p: 5,
          border: '1px solid #666',
          boxShadow: 24,
          width: { xs: "95%", md: "70%", lg: "40%" },
        }} >
          <EditForm closeEvent={handleEditClose} saveEvent={handleSaved} editData={editData} url='/health/tata/selectTataDataEdit' />
        </Box>
      </Modal>
      <Modal
        open={AddOpen}
        // onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          bgcolor: 'background.paper',
          p: 5,
          border: '1px solid #666',
          boxShadow: 24,
          width: { xs: "95%", md: "70%", lg: "40%" },
        }} >
          <AddForm closeEvent={handleAddClose} saveEvent={handleAddSaved} handleChange={handleChange} setPageReload={setPageReload} />
        </Box>
      </Modal>
      <Typography
        gutterBottom
        variant="h5"
        component="div"
        sx={{ padding: "20px" }}
      >
        {/* <h4> Users List  */}
        <Button variant="contained" color="success" onClick={clickAddForm}>Add User</Button>
        {/* </h4> */}

      </Typography>

      <Divider />
      <Box height={10} />
      <Grid item xs={8} >
        <TableContainer
        // component={Paper}
        >
          <Table sx={{ minWidth: 100 }} aria-label="customized table">
            <TableHead>
              <TableRow>
                <TableCell>Id</TableCell>
                <TableCell>Name</TableCell>
                <TableCell>email</TableCell>
                {/* <TableCell>User Type</TableCell> */}
                {/* <TableCell>Action</TableCell> */}
              </TableRow>
            </TableHead>
            <TableBody>
              {
                rowsData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((data, index) => {
                  return (
                    <TableRow
                      hover
                      role="checkbox"
                      tabIndex={-1}
                      key={index}
                    >
                      <TableCell>{data.id}</TableCell>
                      <TableCell>{data.name}</TableCell>
                      <TableCell>{data.email}</TableCell>
                      {/* <TableCell>{data.user_type}</TableCell> */}
                      {/* <TableCell align="left">
                      <Stack spacing={2} direction="row">
                        <EditIcon
                          style={{
                            fontSize: "20px",
                            color: "blue",
                            cursor: "pointer",
                          }}
                          className="cursor-pointer"
                          onClick={() => editDataTable(data)}
                        /> */}
                      {/* <DeleteIcon
                        style={{
                          fontSize: "20px",
                          color: "darkred",
                          cursor: "pointer",
                        }}
                        onClick={() => {
                          deleteUser(data.id);
                        }}
                      /> */}
                      {/* </Stack>
                    </TableCell> */}
                    </TableRow>
                  )
                })
              }
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
      <TablePagination
        rowsPerPageOptions={[1, 5, 10, 25]}
        component="div"
        count={rowsData.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Paper>
  )
}

export default UserLIst