import React, { useState, useEffect } from 'react'
import SelectOption from '../components/common/selectOption'
import AuthDetail from '../components/common/auth';
import { useNavigate } from 'react-router-dom';
import CustomButton from '../components/common/customButton';
import Spinner from '../components/spinner';
const Health = () => {
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false);
    const { base_url, user } = AuthDetail();
    const [userData, setUserData] = useState({});
    const [healthCompany, setHealthCompany] = useState([]);
    const [dataCompany, setDataCompany] = useState("");
    const [dataCompanyError, setDataCompanyError] = useState(false);
    const [userdata, setUserdata] = useState("");
    useEffect(() => {
        setIsLoading(true);
        if (user != null) {
            setUserData(JSON.parse(user));
        } else {
            navigate('/login')
        }
        SelectHealthCompany();
    }, []);
    const SelectHealthCompany = async () => {
        try {
            const res = await base_url.get(`/insuranceType?insuranceType=health`);
            if (res.data.status === 200) {
                const data = res?.data?.data.map((data, index) => { return { label: data.split('_').join(' '), value: data.split('_').join(' ') } })
                setHealthCompany(data)
                setIsLoading(false)
            } else {
                setIsLoading(false)
            }
        } catch (error) {
            setIsLoading(false);
            console.log(error);
        }
    }
    const handleChangeConpany = (e) => {
        setDataCompanyError(false)
        setDataCompany(e.value);
    }
    const handleClick = () => {
        if (dataCompany !== "") {
            navigate(dataCompany.split(' ').join('_'));
        } else {
            setDataCompanyError(true)
        }
    }
    return (
        <div className='container-fluid mt-3  rounded position-top-right-center mt-5'>
            <div className='container '>
                <div className='row'>
                    {isLoading && <Spinner />}
                    <div className="mb-3 mx-auto text-center col-12 col-lg-8 col-md-8 col-sm-12 ">
                        <div className="mb-4 ">
                            <h1 className='form-heading text-center text-uppercase'>HEALTH insurance companies</h1>
                        </div>
                        <SelectOption
                            title='Select company '
                            optionTitle='Select Company'
                            data={healthCompany}
                            onChange={handleChangeConpany}
                            errors={dataCompanyError}
                        />
                        <CustomButton
                            title={'search'}
                            handleClick={handleClick}
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Health