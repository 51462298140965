import React, { useState, useEffect } from 'react';
import SelectOption from '../../components/common/selectOption';
import CustomButton from '../../components/common/customButton';
import AuthDetail from '../../components/common/auth';
import Datepicker from '../../components/common/datepicker';
import Spinner from '../../components/spinner';
import Swal from 'sweetalert2';
import { useNavigate } from 'react-router-dom';
import Logo from '../../assets/image/idealILogo.jpeg';
const date_arr = { 1: 'Jan', 2: 'Feb', 3: 'Mar', 4: 'Apr', 5: 'May', 6: 'Jun', 7: 'Jul', 8: 'Aug', 9: 'Sep', 10: 'Oct', 11: 'Nov', 12: 'Dec' };
const TATAAIGGScreen = () => {
  const navigate = useNavigate();
  const { base_url, user } = AuthDetail();
  const [userData, setUserData] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [swal, setSwal] = useState(false);
  const [startDate, setStartDate] = useState(new Date());
  const [productNames, setProductNames] = useState([]);
  const [sumInsured, setSumInsured] = useState([]);
  const [productTypes, setProductTypes] = useState([]);
  const [dataProduct, setDataProduct] = useState("");
  const [dataSumInsured, setDataSumInsured] = useState("");
  const [dataproductTypes, setDataProductTypes] = useState("");
  const [commission, setCommission] = useState();
  const [productNamesError, setProductNamesError] = useState("");
  const [errorDataSumInsured, setErrorDataSumInsured] = useState("");
  const [errordataproductTypes, setErrordataproductTypes] = useState("");
  useEffect(() => {
    // setIsLoading(true);
    if (user != null) {
      setUserData(JSON.parse(user));
    } else {
      navigate('/login')
    }

    selectProductName(`${date_arr[startDate.getMonth() + 1]}-${startDate.getFullYear()}`);

  }, [])
  const selectProductName = async (currentDate) => {
    setIsLoading(true);
    setSumInsured([]);
    setProductTypes([]);
    try {
      const res = await base_url.post(`/health`, {
        where: { DATE: currentDate, INSURANCE_COMPANY: "TATA AIG" },
        fields: ["PRODUCT_NAME"]
      });
      if (res.data.status === 200) {
        const data = res?.data?.data.map((data, index) => { return { label: data?.PRODUCT_NAME.split('_').join(' '), value: data?.PRODUCT_NAME.split('_').join(' ') } })
        setProductNames(data);
        setIsLoading(false);
      } else {
        setIsLoading(false);
      }
    } catch (error) {
      console.log(error);
    }
  }
  const onChangedate = (e) => {
    let date = new Date(e)
    setStartDate(date);
    selectProductName(`${date_arr[date.getMonth() + 1]}-${date.getFullYear()}`);
    setProductNames([]);
    setSumInsured([]);
    setProductTypes([]);
    setDataProduct("");
    setDataSumInsured("");
    setDataProductTypes("");
  }

  const handleChangeProduct = async (e) => {
    const currentDate = `${date_arr[startDate.getMonth() + 1]}-${startDate.getFullYear()}`;
    const value = e.value;
    setDataProduct(value);
    setProductNamesError(false);
    setIsLoading(true);
    setSwal(false);
    setDataSumInsured("");
    setDataProductTypes("");
    setSumInsured([]);
    setProductTypes([]);
    try {
      const res = await base_url.post(`/health`,
        {
          where: { DATE: currentDate, INSURANCE_COMPANY: "TATA AIG", PRODUCT_NAME: value },
          fields: ["SUM_INSURED"]
        });
      if (res.data.status === 200) {
        const data = res?.data?.data.map((data, index) => { return { label: data?.SUM_INSURED.split('_').join(' '), value: data?.SUM_INSURED.split('_').join(' ') } })
        setSumInsured(data);
        setIsLoading(false);
      } else {
        setIsLoading(false);
      }
    } catch (error) {
      console.log(error);
    }

  }
  const handleChangeSumInsured = async (e) => {
    const currentDate = `${date_arr[startDate.getMonth() + 1]}-${startDate.getFullYear()}`;
    const value = e.value;
    setDataSumInsured(value);
    setIsLoading(true);
    setProductTypes([]);
    setDataProductTypes("");
    setErrorDataSumInsured(false);
    setSwal(false);
    if (value !== "") {
      try {
        const res = await base_url.post(`/health`, {
          where: { DATE: currentDate, INSURANCE_COMPANY: "TATA AIG", PRODUCT_NAME: dataProduct, SUM_INSURED: value },
          fields: ["TYPE"]
        });

        if (res.data.status === 200) {
          const data = res?.data?.data.map((data, index) => { return { label: data?.TYPE.split('_').join(' '), value: data?.TYPE.split('_').join(' ') } })
          setProductTypes(data);
          setIsLoading(false);
        }
      } catch (error) {
        setIsLoading(false);
        // console.log(error);
      }
    } else {
      setIsLoading(false);
    }

  }
  const handleChangePolicyType = async (e) => {
    const value = e.value;
    const currentDate = `${date_arr[startDate.getMonth() + 1]}-${startDate.getFullYear()}`;
    setDataProductTypes(value);
    setIsLoading(true);
    setErrordataproductTypes(false);
    setSwal(false);
    if (value !== "") {
      try {
        const res = await base_url.post(`/health`, {
          where: { DATE: currentDate, INSURANCE_COMPANY: "TATA AIG", PRODUCT_NAME: dataProduct, SUM_INSURED: dataSumInsured, TYPE: value },
          fields: ["RATE"]
        });
        if (res.data.status === 200) {
          setIsLoading(false);
          setCommission(res.data.data[0].RATE);
        } else {
          setIsLoading(false);
        }
      } catch (error) {
        setIsLoading(false);
        console.log(error);
      }
    } else {
      setIsLoading(false);
    }
  }
  const handleClick = () => {
    if (dataProduct === "") {
      setProductNamesError(true);
    }
    if (dataSumInsured === "") {
      setErrorDataSumInsured(true)
    }
    if (dataproductTypes === "") {
      setErrordataproductTypes(true)
    }
    if (dataProduct !== "" && dataSumInsured !== "" && dataproductTypes !== "") {
      Swalll();
    }
  }
  const Swalll = () => {
    return (
      Swal.fire({
        title: `TATA AIG HEALTH INSURANCE`,
        text: 'Modal with a custom image.',
        html: `<table class="table text-start">
    <tbody>
      <tr>
        <td>Product Name</td>
        <td style="text-transform: capitalize;white-space: pre-wrap !important;">${dataProduct.split('_').join(' ')}</td>
      </tr>
      <tr>
        <td>Sum Insured</td>
        <td style="text-transform: capitalize;white-space: pre-wrap !important;">${dataSumInsured.split('_').join(' ')}</td>
      </tr>
      <tr>
        <td>Policy Type</td>
        <td style="text-transform: capitalize;white-space: pre-wrap !important;">${dataproductTypes.split('_').join(' ')}</td>
      </tr><tr> <td>Commission </td> <td style="text-transform: capitalize;white-space: pre-wrap !important;">${commission}${/[a-zA-Z]/g.test(commission) ? "" : "%"}</td> </tr>
    </tbody> </ > `,
        imageUrl: `${Logo} `,
        imageWidth: 400,
        imageHeight: 200,
        imageAlt: 'Custom image',
        confirmButtonText: 'OK',
        confirmButtonColor: 'green',
      })
    )
  }
  return (
    <div className='container-fluid mt-3  rounded position-top-right-center mt-5'>
      <div className='container '>
        <div className='row'>
          {isLoading && <Spinner />}
          <div className="mb-3 mx-auto text-center col-12 col-lg-8 col-md-8 col-sm-12 ">
            <div className="mb-4 ">
              <h1 className='form-heading text-center text-uppercase'> TATA AIG health insurance</h1>
            </div>
            {
              userData.user_type !== "user" ? <Datepicker onChangedate={onChangedate} data={startDate} /> : ""
            }
            <SelectOption
              title='Product'
              optionTitle='Select product'
              data={productNames}
              onChange={handleChangeProduct}
              errors={productNamesError}
              value={dataProduct}
            />
            <SelectOption title='Sum Insured'
              optionTitle='Select sumInsured'
              data={sumInsured}
              onChange={handleChangeSumInsured}
              errors={errorDataSumInsured}
              value={dataSumInsured}
            />
            <SelectOption
              title='Policy Type'
              optionTitle='Select policyType'
              data={productTypes}
              onChange={handleChangePolicyType}
              errors={errordataproductTypes}
              value={dataproductTypes}
            />
            <CustomButton
              title={'search'}
              handleClick={handleClick}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default TATAAIGGScreen