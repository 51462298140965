import React from 'react';
import '../../Health.css'
const Spinner = () => {
    return (
        <div className='spinner-wrapper'>
            <div className="spinner-border text-primary" role="status">
                <span className="visually-hidden">Loading...</span>
            </div>
        </div>
    );
}

export default Spinner;
